import './index.sass'

export const Button = ({name, cb, error , addStyleClasses = [], addStyleClass = ''}) => {

  let classes = `btn_gold ${addStyleClasses.join(' ')} ${addStyleClass} ${error ? 'error_red' : ''}`
  return (
    <div
      className={classes}
      onClick={cb}>
      {name}
    </div>
  )
}
