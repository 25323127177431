import {makeAutoObservable, toJS} from "mobx";
import Competencies from "./Competencies";
import {notification} from "antd";
import _ from 'lodash'
import {toJSON} from "lodash/seq";
import CoursesStore from "./CoursesStore";

class Users {
  user = {
    id: null,
    surname: undefined,
    subjects: []
  }

  organizationId = 1


  constructor() {
    makeAutoObservable(this)
  }

  getUser() {
    return this.user
  }

  getOrgId(){

  }

  getSelfUsername(){
    return this.user.username
  }

  addField(data){
    this.user = {...this.user, ...data}
  }

  fetchData(request){
    request(`/api/users/getMyData`)
      .then(data => {
        this.user = data
        this.user.subjects = _.sortBy(this.user.subjects, 'id')


        request(`/api/positions/getPositionById/${data.position.id}`)
          .then(r => {
            Competencies.setComp(r.competences)
            CoursesStore.setReqCourses(r.courses)
            this.organizationId = r.organization.id
          })

        if(data.id === 1) return;
        request(`/api/users/getLeaderByUserId/${data.id}`)
          .then(leader => {
            this.user.leader = leader
          })
      })
  }

  fetchDataPhoto(request) {
    request(`/api/users/getMyData`).then(data => {

      this.user.avatar_url = data.avatar_url
    })
  }


  //редактирование статуса
  editUserStatus(request, status){
    let {id, position} = this.user

    let {organization, department} = position

    let data = {
      "position_id": position.id,
      "department_id": department.id,
      "organization_id": organization.id,
      "id": id,
      "status": status
    }

    request(`/api/users/update/${this.user.id}`, "PUT", data)
      .then(data => {
        this.addField({status: status})
      })
  }
}

export default new Users()