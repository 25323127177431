import {useEffect, useRef, useState} from "react";
import "./index.sass"
import {observer} from "mobx-react-lite";
import OrganizationStore from "../../store/OrganizationStore";
import {useHttp} from "../../hooks/http.hook";
import {Space} from "../../components/Space";
import {AvatarProfile} from "../Profile/components/avatar/AvatarProfile";
import {Input} from "../../components/Input/Input";
import {toJS} from "mobx";
import _ from 'lodash'
import {BioBlock} from "./BioBlock";
import {BackBtn2} from "../../components/BackBtn2";
import DetailUser from "../../store/DetailUser";
import {InputSearch} from "../../components/InputSearch/Input";

let ArrowDownSVG = () => {
  return   <svg viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0h24v24H0z" fill="none">
    </path>
    <path d="m13 16.172 5.364-5.364 1.414 1.414L12 20l-7.778-7.778 1.414-1.414L11 16.172V4h2v12.172z"
          fill="#b0b0b0"
          className="arrowDownFill">
    </path>
  </svg>
}


export const Organization = observer(() => {
  let [showBio, setShowBio] = useState({start: false, item: {}, leader: {}})
  let [parentIds, setParentsIds] = useState([])
  let [treeCopy, setTreeCopy] = useState(null)
  let [clearText, setClearText] = useState(false)
  let [searchList, setSearchList] = useState([])
  let [searchShow, setSearchShow] = useState(false)
  const [vis, setVis] = useState(false)
  let {request} = useHttp()
  let tree = OrganizationStore.getTree()

  useEffect(()=>{
    document.addEventListener('scroll', ()=>{
      if(window.scrollY > 5){
        setVis(true)
      }else{
        setVis(false)
      }
    })
  },[])

  useEffect(() => {
    OrganizationStore.fetchData(request)
    // DetailUser.fetchDataAll(request)
  }, [])

  useEffect(()=>{
    document.addEventListener('click', (e) => {
      let el = document.querySelector('.blockResult')
      if(!e.target.className ||( typeof e.target.className !== 'string') ) return;
      let self =  e.target.className.includes(`inputText`)
      if(el && !el.contains(e.target) && !self){
        setSearchShow(false)
      }
    })
  }, [])


  const blockHideShow = (id, onlyOpen) => {
    let copy = [...parentIds]
    if (parentIds.includes(id) && !onlyOpen) {
      let f = parentIds.findIndex(x => x === id)
      copy.splice(f, 1)
      setParentsIds(copy)
    } else {
      copy.push(id)
      setParentsIds(copy)

      if(treeCopy && treeCopy.length >= 2) searchByTree(id)
    }
  }

  const showBioFn = (e, item, id, hide, leader) => {
    if (e) e.stopPropagation()

    if (hide) {
      setShowBio({start: false, item: null})
      return;
    }

    setShowBio({start: true, item: item, leader: leader})

    if(id !== 'empty') OrganizationStore.fetchDataByUser(request, id, item)
  }

  const treeItem = (x, first, leader = {}) => {
    let bioHuman = x.worker && x.worker.name && (x.worker.id !== 'empty')
    let bioFn = bioHuman ?
      (e) => showBioFn(e, x, x.worker.id, false, x.leader) :
      (e) => showBioFn(e, x, false , false, x.leader)

    let avatarUrl = (!x.worker || x.worker.id === 'empty' || !x.worker.avatar_url) ? null :
      x.worker.id +"_"+ x.worker.avatar_url

    return (
      <div
        className={`positionItemInner ${!first ? "lineBefore" : ""} `}
           onClick={(e) => bioFn(e)}>

        <div className="avatarBlock">
          <AvatarProfile
            avatar_url={avatarUrl}
            size={70}
            edit={false}
            companyAvatar={!avatarUrl}
          />

        </div>

        <div className="infoByPos">
          {bioHuman && <span className={'posValue'}>{x.worker.fullName}</span>}
          {!bioHuman &&
            <span className={'posValue'}>
              Вакантная должность
            </span>}

          <span title={x.position.name} className={'posName'}> {x.position.name } </span>
        </div>

      </div>
    )
  }

  const getTree = (parent, upBtn) => {
    let result = []

    let domGeneration = (self, level, spaceType, leader) => {
      if(upBtn && !level && self.worker.id !== 1) {
        let el = <Space h={15}/>
        result.push(el)
      }

      let lines = spaceType.map(x => {
        let className = x === false ? 'noneLine' : x === 0 ? 'lineVertical' : `lineAnger`
        return <div className={className}/>
      })

      let el = (
        <div key={self.worker.id} className={`itemLine`}>
          {lines && lines.map(l => l)}

          {upBtn && !level && self.worker.id !== 1 &&
            <div
              style={{left: spaceType.length * 73}}
              onClick={() => blockShowUp(self.worker.id)}
              className={`sesPosition seeMore upBtn ${parentIds.includes(self.worker.id) ? `seeHide` : ""}`}
            >
              <ArrowDownSVG/>
            </div> }

          {treeItem(self, !level, leader)}

          {self.final.length > 0 && (
            <div
              style={{left: spaceType.length * 73}}
              onClick={() => blockHideShow(self.worker.id)}
              className={`sesPosition seeMore ${parentIds.includes(self.worker.id) ? `seeHide` : ""}`}
            >
              <ArrowDownSVG/>
            </div>
          )}
        </div>
      )

      result.push(el)

      parentIds.includes(self.worker.id) &&
      self.final.forEach((child, ii, arr) => {
        let last = arr.length - 1 === ii

        let copySpaces = spaceType.map(l => {
          if (l === 1) return false
          if (l === 0) return 0
          if (l === false) return false
        }) || []

        if (last) copySpaces.push(1)
        if (!last) copySpaces.push(0)

        domGeneration(child, level + 1, copySpaces, self)
      })
    }

    domGeneration(parent, 0, [], parent)

    return result
  }

  const inputSearch = (e) => {
    let r = []
    let treeFetch = OrganizationStore.getTreeFetch()
    let searchText = e.target.value.toLowerCase()

    if (!searchText) {
      setSearchList([])
      setTreeCopy(null)
      return;
    }

    if(searchText.length < 3){
      return;
    }

    treeFetch.forEach(x => {
      if (x.name.toLowerCase().includes(searchText)) {
        r.push({id: `p${x.id}`, name: x.name})
      }

      x.workers.forEach(xx => {
        let fullName = xx.family + " " + xx.name + " " + xx.surname

        if (xx.name !== 'Вакант' && fullName.toLowerCase().includes(searchText)) {
          r.push({id: xx.id, name: fullName})
        }else if("вакантная должность".includes(searchText)) {
          if(xx.name === 'Вакант') r.push({id: xx.id, vakant : true, name: "Вакантная должность"})
        }
      })
    })

    setSearchList(r)
  }

  const onEnter = () => {
    setSearchShow(false)

    let r = []
    if (!searchList.length) {
      return;
    }

    let findFn = (arr) => {
      arr.forEach(x => {
        if (searchList.find(xx => xx.id === `p${x.position.id}`) ||
          searchList.find(xx => xx.id === x.worker.id) ||
          searchList.find(xx => xx.vakant && x.worker.name === 'Вакант')
        ) {
          r.push(x)
        }

        findFn(x.final)
      })
    }

    findFn(tree)

    setParentsIds([])
    setTreeCopy(r)
  }

  const SearchBlock = () => {
    return (
      <div className="search">
        <div className={'inputZone'}>
          <div className={`searchShos`}>
            <InputSearch placeholder={'Поиск сотрудника...'}
                         clear={clearText}
                         cb={inputSearch}
                         onClick={clickInput}
                         cleanAll={backToStart}
                         onEnter={onEnter}
            />
          </div>

          {searchShow && <div className="blockResult">
            {searchList.map((x,i) => {
              if(i) return
              if(searchList.findIndex(x => x.vakant) > -1){
               return <span onClick={searchByTreeVakants} key={'vakant'}>Вакантная должность</span>
              }
            })}

            {searchList.map((x,i) => {
              if(x.vakant) return
              return <span onClick={() => searchByTree(x.id)} key={x.id}>{x.name}</span>
            })}

          </div>
          }
        </div>
      </div>
    )
  }

  useEffect(() => {
    setSearchShow(searchList.length > 0)
  }, [searchList])

  const searchByTree = (id) => {
    setSearchShow(false)
    let el = null

    if(typeof id === 'string' && id.includes('p')){
      let idPosition = parseInt(id.replace('p',''))
      let result = []

      let findFn = (arr, id) => {
        arr.forEach(x => {
          if(x.position.id === id){
            result.push(x)
          }
          findFn(x.final, id)
        })
      }

      findFn(tree, idPosition)
      setTreeCopy(result)
      return;
    }


    let findFn = (arr, id) => {
      let f = arr.find(x => x.worker.id === id)

      if (f) {
        el = f;
        return;
      }
      arr.forEach(x => findFn(x.final, id))
    }

    findFn(tree, id)

    if(el) setTreeCopy([el])
  }

  const searchByTreeVakants = () => {
    setSearchShow(false)

    let r = []
    if (!searchList.length) {
      return;
    }

    let findFn = (arr) => {
      arr.forEach(x => {
        if (x.worker.name === 'Вакант') r.push(x)
        findFn(x.final)
      })
    }

    findFn(tree)

    setParentsIds([])
    setTreeCopy(r)
  }

  const clickInput = () => {
    if (searchList.length) setSearchShow(true)
  }

  const blockShowUp = (selfId) => {
    let u = DetailUser.getUserById(selfId)

    let leaderId = u.leader.id

    if(leaderId === 1 ){
      blockHideShow(leaderId, true)
      setTreeCopy(false)
      return;
    }

    blockHideShow(leaderId, true)
    searchByTree(leaderId)
  }

  const backToStart = () => {
    setTreeCopy(false)
    setSearchShow(false)
    setSearchList([])
    setClearText(true)
    // setSearchText(null)
    setTimeout(()=>setClearText(false), 100)
  }


  return (
    <div id={'shosPage'} className={`shosPage ${vis ? "topMilkShow" : ""}`}>
      <div className="title">Организационная стуктура</div>
      <Space h={30}/>

      <BackBtn2 />

      {SearchBlock()}

      <Space h={20}/>

      {!treeCopy && tree.map((x) => getTree(x))}

      {treeCopy && treeCopy.map(x => getTree(x, true))}

      {showBio.start && <BioBlock
        hideFn={() => showBioFn(null, null, null, true)}
        leaderClick={()=>blockShowUp(showBio.item.worker.id)}
        item={showBio.item}/>}

    </div>
  )
})

