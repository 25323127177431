import React, {useEffect, useState} from "react";
import './index.sass'

export const InputSearch = ({id, cb ,error2, clear, cleanAll, name, inputMode, defValue, type, error, placeholder, onEnter, onClick}) => {
  let [text, setText] = useState('')
  let [errorStyle, setErrorStyle] = useState(false)


  const change = (e) => {
    setText(e.target.value)
    cb(e)
    if(e.target.value) {
      setErrorStyle(false)
    }
  }

  useEffect(()=>{
    if(defValue) setText(defValue)
  }, [defValue])


  useEffect(()=>{
    if(clear) setText('')
  }, [clear])


  useEffect(()=>{
    if(error && !text) setErrorStyle(true)
  }, [error] )

  useEffect(()=>{
    if(error2){
      setText('')
      setErrorStyle(true)
    }
  }, [error2] )


  const clean = () => {
    setText("")
    cleanAll()
  }

  const keyDown = (event) => event.key === 'Enter' && onEnter && onEnter()

  return (
    <div className={'wrap searchWrapMain'}>
      {errorStyle && <div className={`empty_info`}/>}
      <input
        id={id || null}
        className={`inputText ${errorStyle ? 'input_error' : ''}`}
        onChange={change}
        onKeyDown={keyDown}
        name={name}
        onClick={onClick}
        value={text}
        placeholder={placeholder || ""}
        inputMode={inputMode || 'text'}
        type={type || "text"}/>

      {!text && <div className="lupe"/>}
      {text && <div onClick={clean} className="clear"/>}
    </div>

  )
}