export const columns = [
  {
    title: 'НАИМЕНОВАНИИЕ КОМПЕТЕНЦИИ',
    dataIndex: 'name',
    key: 'name',
    width: 250
  },
  {
    title: `ОЦЕНТКА`,
    dataIndex: 'grade',
    key: 'grade',
    className: 'centerColumn',
    width: 140

  },
  {
    title: 'ТРЕБУЕМЫЙ УРОВЕНЬ',
    dataIndex: 'req_level',
    key: 'req_level',
    className: 'centerColumn',
    width: 150

  },
  {
    title: 'СТАТУС',
    dataIndex: 'status',
    key: 'status',
    className: 'centerColumn',
    width: 150
  },
  {
    title: 'ИСТОРИЯ ОЦЕНКИ',
    dataIndex: 'history',
    key: 'history',
    className: 'centerColumn',
    width: 120,
  },
  {
    title: 'СОПРОВОДИТЕЛЬНЫЙ ТЕКСТ',
    dataIndex: 'text',
    key: 'text',
    className: 'centerColumn',
    width: 160
  },
  {
    title: 'КОММЕНТАРИЙ',
    dataIndex: 'note',
    key: 'note',
    className: 'centerColumn',
    width: 125
  }
];