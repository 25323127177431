import {Modal} from "../../../../components/Modal";
import "./index.sass"
import {ButtonsForModal} from "../../../../components/ButtonsForModal";
import {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import Competencies from "../../../../store/Competencies";
import {toJS} from "mobx";

export const ModalText = observer(({closeFn , data, btnsMode=false}) => {
  let [text, setText ] = useState(data.actual.acc_text)

  const saveText = (e) => {
    setText(e.target.value)
  }

  const save = () => {
    Competencies.saveAccText(data, text)
    closeFn()
  }

  return (
    <Modal
      closeFn={closeFn}
      title={'Сопроводительный текст'}
      titlePadding={!btnsMode ? 0 : 10}
      wrapClose={!btnsMode}
    >
      {!btnsMode && <div id={'info_modal_id'} className={'modalBodyBlock'}>
        {text}
      </div>}

      {btnsMode &&
        <>
          <div className={'text_area'}>
          <textarea
            value={text || ""}
            onChange={saveText}
            name="text" rows="8"
            placeholder={'Введите сопроводительный текст для текущей компетенции:'}/>
          </div>
          <ButtonsForModal save={save} cancel={closeFn}/>
        </>
      }

    </Modal>
  )
})
