import {useState} from "react";
import {AvatarProfile} from "../avatar/AvatarProfile";
import "./index.sass"
import {StatusPopUp} from "../StatusPopUp";
import {observer} from "mobx-react-lite";
import User from "../../../../store/User";
import {Space} from "../../../../components/Space";
import {useHttp} from "../../../../hooks/http.hook";
import email from '../../../../img/email.png'
import phone from '../../../../img/phone.png'
import {toJS} from "mobx";


const PreviewMode = observer(({previewMode, user , showAllMode}) => {
  let [statusShow, setStatusShow] = useState(false)
  let {request} = useHttp()


  const changeStatus = (status) => {
    User.editUserStatus(request, status)
  }

  const openStatusMenu = () => {
    setStatusShow(prev => !prev)
  }

  let {
    avatar_url, id, family, name, phone_work, status, surname, t_number,
    leader, position, username } = User.getUser()

  if(!previewMode) return null;

  let {organization, department} = position

  return (
    <div className="content">

      <div className={'rel'}>
        <div className="grid">

          <div className={'left'}>
            <AvatarProfile
              avatar_url={id + "_" + avatar_url}
              size={278}
              companyAvatar={!avatar_url}
            />

            <Space h={25}/>

            <div className={'phone'}> <img src={phone} alt={''}/> <span>{phone_work}</span> </div>
            <div className={'mail'}> <img src={email} alt={''}/> <span>{username}</span> </div>

          </div>

          <div className={'right'}>

            <div className='name_full_block grid_name'>
              <span className={'name_full'}> {family} {name} {surname} </span>
              <span></span>

              <span id={'statusLine'} onClick={openStatusMenu} className={'status'} >
                статус: <span className={'statusHover'} style={{textTransform: `uppercase`}}>{status.replace("_"," ")}</span>

                {statusShow && <StatusPopUp close={openStatusMenu} change={changeStatus}/>}
              </span>

            </div>

            <div className="info">

              <p><span className="title_key">Должность: </span>{position.name}</p>
              <p><span className="title_key">Отдел: </span>{department.name}</p>
              <p><span className="title_key">Компания: </span>{organization.name}</p>

              {id!==1 && <>
                <p><span className="title_key">Руководитель: </span>{leader?.family} {leader?.name} {leader?.surname}</p>
                <p><span className="title_key">Должность руководителя: </span>{leader?.position?.name}  </p>
              </>
              }
            </div>

          </div>

        </div>

      </div>
    </div>
  )
})

export default PreviewMode