import {makeAutoObservable, toJS} from "mobx";
import _ from "lodash"
import {notification} from "antd";
import moment from "moment";
import User from "../User";
import ShosStore from "./ShosStore";


class Admin {
  competencies = []
  competenciesWithChild = []
  baseCourseParents = []
  baseCompetenciesParents = []
  coursesWithChild = []
  courses = []

  categories = []
  positions = []
  departments = []
  company = {}


  constructor() {
    makeAutoObservable(this)
  }

  notificationSuccess(text) {
    notification.success({
      message: text,
      className: 'text_notification',
      duration: 3
    })
  }


  notificationError(text) {
    notification.error({
      message: text,
      className: 'text_notification',
      duration: 3
    })
  }


  fetchCat(request, type) {
    let r1 = new Promise(resolve => {
      request(`/api/categories/getCategoryByType/${type}`)
        .then(data => {
          if(type === 'Компетенция') this.baseCompetenciesParents = data || []
          if(type === 'Курс') this.baseCourseParents = data || []
          resolve(data)
        })
    })

    let r2;

    if (type === 'Курс') {
      r2 = new Promise(resolve => {
        request('/api/courses/getCourses/0/999')
          .then(data => {
            // this.courses = sortByNameCourse(data)
            resolve(data)
          })
          .catch(e => {
            resolve([])
          })
      })
    } else {
      r2 = new Promise(resolve => {
        request('/api/competencies/list/0/999')
          .then(data => {
            this.competencies = data
            resolve(data)
          })
          .catch(e => {
            resolve([])
          })
      })
    }


    Promise.all([r1, r2]).then(resolve => {
      let [categories, child] = resolve
      let nameType = (type === 'Курс') ? `courses` : `competencies`
      this.categories = categories

      if (nameType === 'competencies') {
        this.competenciesWithChild = sortCompAndChild(categories, child)
      } else {
        this.coursesWithChild = sortCourseAndChild(categories, child)
      }
    })
  }


  /** competencies START */
  getComp() {
    return this.competencies
  }

  getCompParentChild() {
    return this.competenciesWithChild
  }

  getCompParentChildNotFree() {
    return this.competenciesWithChild.filter(x => {
      if(x.comp && x.comp.length) return x
    })
  }

  async addComp(request, data) {
    return request(`/api/competencies/save`, 'post', data)
      .then(data => {
        this.competencies.push(data)
        this.notificationSuccess(`Компетенция добавлена`)
        return true
      })
  }

  addCatCourse(request, name) {
    let data = {
      "categoryName": name,
      "categoryType": "Курс"
    }
    request(`/api/categories/save`, 'post', data)
      .then(data => {
        this.categories.push(data)
        data.courses = []
        this.coursesWithChild.push(data)

        this.notificationSuccess(`Категория добавлена`)
      })
  }


  editCatCourse(request, data) {
    request(`/api/categories/save`, 'post', data)
      .then(response => {
        this.coursesWithChild.forEach(x => {
          if (x.id === data.id) x.categoryName = data.categoryName
        })

        this.notificationSuccess('Изменения сохранены')
      })
  }

  delCatCourse(request, id) {
    request(`/api/categories/delete/${id}`, 'delete')
      .then(data => {
        data.courses = []
        this.coursesWithChild = this.coursesWithChild.filter(x => x.id !== id)

        this.notificationSuccess(`Категория удалена`)
      })
  }

  editComp(request, compData) {
    request(`/api/competencies/save`, 'post', compData)
      .then(data => {
        this.competenciesWithChild.forEach(gr => {
          gr.comp.forEach(x => {
            if (x.id === data.id) {
              x.comp_name = compData.comp_name
              x.description = compData.description
            }
          })
        })
        this.competencies.push(data)
        this.notificationSuccess(`Изменения сохранены`)
      })
  }

  delComp(request, id) {
    request(`/api/competencies/delete/${id}`, 'delete')
      .then(data => {
        //this.competencies = this.competencies.filter(x=> x.id !== id)

        this.competenciesWithChild.forEach(gr => {
          gr.comp = gr.comp.filter(x => x.id !== id)
        })

        this.notificationSuccess('Изменения сохранены')
      })
      .catch(e => {

        this.notificationError('Нельзя удалить компетенцию, пока она связна с должностью')
        return false
      })

  }

  deleteCategoriesById(request, id) {
    request(`/api/categories/delete/${id}`, 'delete')
      .then(data => {
        this.competenciesWithChild = this.competenciesWithChild.filter(x => x.id !== id)
        //this.competencies = this.competencies.filter(x=> x.id !== id)

        this.notificationSuccess('Изменения сохранены')
      })
  }

  editCategoriesById(request, data) {
    request(`/api/categories/save`, 'post', data)
      .then(response => {
        this.competenciesWithChild.forEach(x => {
          if (x.id === data.id) x.categoryName = data.categoryName
        })

        this.notificationSuccess('Изменения сохранены')
      })
  }

  /** competencies END  */


  /** positions START  */

  getPositions() {
    return this.positions
  }

  getPositionsFilterNull() {
    let f = this.positions.filter(x => {
      if(x.id === 100 || x.id === 101) return false
      return x
    })
    return f
  }

  async addPosition(request, data , saveComp, coursesNew) {

    let response = await request(`/api/positions/save`, 'post', data)


    //сразу создаю вакантную должность только в случае если есть один возможный лидер для позиции
    if (data.parent_id){
      let parent = await request(`/api/positions/getPositionById/${data.parent_id}`)
      if(parent.workers.length===1){
        ShosStore.createVacant(request, response.id, parent.workers[0].id)
      }
    }

    await this.addCompetentToPosition(request, response.id , saveComp)
    await this.addCoursesToPosition(request, response.id , coursesNew)
    this.notificationSuccess('Должность добавлена')

    return true
  }

  async editPosition(request, data , saveComp, coursesNew) {

    await this.addCompetentToPosition(request, data.id , saveComp)
    await this.addCoursesToPosition(request, data.id , coursesNew)

    await request(`/api/positions/update/${data.id}`, 'put', data)
      .then(r => {
        this.notificationSuccess('Изменения сохранены')
      })

    return true

  }

  async addCompetentToPosition(request,positionId,  data) {
    let requests = []

    data.forEach(gr => {
      gr.comp.forEach(x => {
        if(x.add){
          let level = (x.req_level === 'На практике') ? 'На_практике' : x.req_level
          let d = {
            "positionId": positionId,
            "competenceId": x.id,
            "competenceLevel": level
          }
          requests.push(()=> request(`/api/positions/saveCompetenceToPosition`, 'post', d) )
        }

        if(x.delete){
          requests.push( ()=> request(`/api/positions/deleteCompetenceToPosition/${positionId}/${x.id}`, 'delete'))
        }
      })
    })

    for (const req of requests) await req()
    return true
  }

  async addCoursesToPosition(request, positionId, data) {
    let requests = []

    data.forEach(gr => {
      gr.courses.forEach(x => {
        if(x.add){
          let d = {
            "positionId": positionId,
            "courseId": x.id
          }
          requests.push(()=> request(`/api/positions/saveCourseToPosition`, 'post', d) )
        }

        if(x.delete){
          requests.push(()=> request(`/api/positions/deleteCourseToPosition/${positionId}/${x.id}`, 'delete'))
        }
      })
    })

    for (const req of requests) await req()
    return true
  }

  deletePosition(request, id) {
    request(`/api/positions/delete/${id}`, 'delete')
      .then(r => {
        this.positions = this.positions.filter(x => x.id !== id)
      })
  }

  fetchPositions(request) {
    request(`/api/positions/getPositions/0/999`)
      .then(data => {
        // let f = data.filter(x => (x.id !== 100 && x.id !== 101 && (x.id !== x.parent_id)))
        let f = data.filter(x => (x.id !== 100 && x.id !== 101 ))
        this.positions = filterByName(f)
      })
  }

  /** positions END  */


  /** courses START  */
  getCourses() {
    return this.courses
  }

  getCoursesAndChild() {
    return this.coursesWithChild
  }

  async addCourse(request, data, learnTimes = []) {
    return request(`/api/courses/save`, 'post', data)
      .then(r => {

        let req = []

        learnTimes.forEach(x => {
          if (!x.begin_date) return;
          x.course_id = r.id

          req.push(request(`/api/courses/createCourseDate`, 'post', x))
        })

        return Promise.all(req)
          .then(resp => {
            if(data.edit){
              this.notificationSuccess('Изменения сохранены')
            }else{
              this.notificationSuccess('Курс добавлен')
            }

            return true
          })
      })
  }

  delCourse(request, id) {
    request(`/api/courses/delete/${id}`, 'delete')
      .then(r => {
        this.coursesWithChild.forEach(gr => {
          gr.courses = gr.courses.filter(x => x.id !== id)
        })
        this.notificationSuccess('Изменения сохранены')
      })
      .catch(()=>{
        this.notificationError('Нельзя удалить курс, пока он связан с должностью')
      })
  }

  /** courses END  */


  savePaysLips(request, filesToSave, id) {
    let files = new FormData()

    let file = filesToSave[0]

    files.append(`file`, file, `${id}_${file.name}`)

    request(`/api/payslips/uploadPayslip`, files)
      .then(r => {
        this.notificationSuccess('Файл успехно загружен')
      })
  }

  /** КАТЕГОРИИ КАТЕГОРИИ КАТЕГОРИИ КАТЕГОРИИ */
  getCats() {
    return this.categories
  }

  addCat(request, type, name) {
    let data = {
      "categoryName": name,
      "categoryType": type
    }
    request(`/api/categories/save`, 'post', data)
      .then(data => {
        this.categories.push(data)
        data.comp = []
        this.competenciesWithChild.push(data)

        this.notificationSuccess(`Категория добавлена`)

      })
  }

  /** КАТЕГОРИИ КОНЕЦ  */


  /** ДЕПАРТАМЕНТЫ ДЕПАРТАМЕНТЫ ДЕПАРТАМЕНТЫ ДЕПАРТАМЕНТЫ */
  getDepartments() {
    return this.departments.filter(x => x.name !== `Уволенные`)
  }
  getDepartments2() {
    return this.departments
  }

  fetchDepartments(request) {
    request('/api/departments/getDepartments/0/999')
      .then(data => {
        this.departments = data
      })
  }

  addDepartment(request, name) {
    request(`/api/departments/save`, 'post', {name: name})
      .then(data => {
        this.departments.push(data)
        this.notificationSuccess(`Департамент добавлен`)
      })
  }

  editDepartment(request, data) {
    request(`/api/departments/update/${data.id}`, 'put', data)
      .then(r => {
        this.departments.forEach(x => {
          if (x.id === data.id) x.name = data.name
        })

        this.notificationSuccess(`Изменения сохранены`)

      })
  }

  deleteDepartment(request, id) {
    request(`/api/departments/delete/${id}`, 'delete')
      .then(r => {
        this.departments = this.departments.filter(x => x.id !== id)
      })
  }

  /** ДЕПАРТАМЕНТЫ КОНЕЦ  */


  fetchOrganizationInfo(request) {
    let organizationId = User.organizationId

    request(`/api/organizations/getOrganizationById/${organizationId}`)
      .then(data =>
        this.company = data
      )
  }

  getOrganizationInfo() {
    return this.company
  }

  fetchOrganizationUpdate(request, info) {
    let organizationId = User.organizationId

    request(`/api/organizations/update/${organizationId}`, 'put', info)
      .then(data => {
        this.notificationSuccess(`Данные обновлены`)
      })
  }

  sortCompAndChild2(data) {
    let newArr = []
    data.forEach(x => {
      let f = newArr.findIndex(xx => xx.categoryName === x.competence.category.categoryName)
      if (f === -1) {
        let ff = this.baseCompetenciesParents.find(item => item.categoryName ===  x.competence.category.categoryName)
        newArr.push({
          categoryName: x.competence.category.categoryName,
          categoryId: ff.id,
          cats: []
        })
      }
    })

    newArr.forEach(x => {
      data.forEach(xx => {
        if (xx.competence.category.categoryName === x.categoryName) {
          x.cats.push({id: xx.competence.id, req_level: xx.req_level, comp_name: xx.competence.comp_name})
        }
      })
    })

    return newArr
  }

}

export default new Admin()


const sortCompAndChild = (parent, child) => {
  const dataObj = [...parent]

  dataObj.forEach(x => {
    x.comp = []
    child.forEach(xx => {
      if (x.id === xx.category.id) {
        x.comp.push(xx)
      }
    })
  })

  return dataObj
}


const sortCourseAndChild = (parent, child) => {
  const dataObj = [...parent]


  dataObj.forEach(x => {
    x.courses = []
    child.forEach(xx => {
      if (x.categoryName === xx.category.categoryName) {
        x.courses.push(xx)
      }
    })
  })

  return dataObj
}


const filterByName = (data) => {
  data = _.sortBy(data, 'name')
  return data
}

const sortByNameCourse = (data) => {
  let gr = []

  data = _.sortBy(data, 'course_name')

  data.forEach(x => {
    const index = gr.findIndex(item => item.title === x.category.categoryName)

    x.course_dates.forEach(xx => {
      xx.begin_date = moment(xx.begin_date).format('DD.MM.YYYY')
    })

    if (index > -1) {
      gr[index].courses.push(x)
    } else {
      gr.push({
        title: x.category.categoryName,
        courses: [x]
      })
    }
  })

  gr = _.sortBy(gr, 'title')

  return gr
}
