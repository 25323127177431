import './index.sass'
import {Modal} from "../../../../../../components/Modal";
import {ButtonsForModal} from "../../../../../../components/ButtonsForModal";
import {useRef, useState} from "react";
import {Select} from "antd";

const Option = Select.Option

export const PopUpChoose = ({closeFn, save  }) => {
  let years = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]
  let months = [0,1,2,3,4,5,6,7,8,9,10,11]

  let setChoose = useRef({years:0, months:0})


  const saveChoose = () => {
    save(setChoose.current)
    closeFn()
  }

  const activeSel = (value, type) => {
    setChoose.current[type] = value
  }



  return (
    <Modal
      closeFn={closeFn}
      title={'ЧАСТОТА ПОВТОРЕНИЙ'}
    >
      <div id={'info_modal_id'}>

        <div className="grid2Simple">
          <div className={'text_main_name_close'}>
            <p>{`Выберите года ` }</p>
            <Select
              onChange={(val)=>{activeSel(val,`years` )}}
              style={{width: '200px'}}>
              {years.map((x,i) => {
                return (
                  <Option key={i} value={i}>{i}</Option>
                )
              })}
            </Select>
          </div>

          <div className={'text_main_name_close'}>
            <p>{`Выберите месяцы ` }</p>
            <Select
              onChange={(val)=>{activeSel(val,`months` )}}
              style={{width: '200px'}}>
              {months.map((i,x) => {
                return (
                  <Option key={x} value={x}>{x}</Option>
                )
              })}
            </Select>
          </div>
        </div>

      </div>

      <ButtonsForModal okText={'Сохранить'} save={saveChoose} cancel={closeFn}/>
    </Modal>
  )
}
