import {makeAutoObservable, toJS} from "mobx";
import User from "./User";
import _ from 'lodash'
import moment from "moment";

class CoursesStore {
  courses = []
  requirements = []

  constructor() {
    makeAutoObservable(this)
  }


  get(){
    return this.courses
  }

  getReq(){
    return this.requirements
  }

  clean(){
  }


  fetchData(request){
    let u = User.getUser()

    request(`/api/courses/getCourses/0/999`)
      .then(data => {
        this.courses = sortByNameCourse(data)
      })

    request(`/api/requirements/getRequirementsByOwnerId/${u.id}/0/999`)
      .then(data => {
        this.requirements = requireMerge(this.requirements , data)
      })
  }

  setReqCourses(requirements){
    this.requirements = sortByReqCourse(requirements)
  }
}

export default new CoursesStore()


const sortByNameCourse = (data) => {
  let gr = []

  data = _.sortBy(data, 'course_name')

  data.forEach(x => {
    const index = gr.findIndex(item => item.title === x.category.categoryName)

    x.course_dates.forEach(xx => {
      xx.begin_date = moment(xx.begin_date).format('DD.MM.YYYY')
    })

    if(index > -1){
      gr[index].courses.push(x)
    }else{
      gr.push({
        title: x.category.categoryName,
        courses: [x]
      })
    }
  })

  gr = _.sortBy(gr, 'title')

  return gr
}

export const sortByReqCourse = (data) => {
  let gr = []

  data.forEach(x => {
    const index = gr.findIndex(item => item.title === x.category.categoryName)

    x.status = 'Не выставлен'

    if(index > -1){
      gr[index].courses.push(x)
    }else{
      gr.push({
        title: x.category.categoryName,
        courses: [x]
      })
    }
  })

  gr.forEach( x => {
    x.courses = _.sortBy(x.courses, 'course_name')
  })
  return gr
}

export const requireMerge = (courses, history=[]) => {
  history.forEach(x => {
    courses.forEach(xx => {
      xx.courses.forEach(xxx => {
        if(xxx.id  === x.course_id){
          xxx.begin_date = x.begin_date
          xxx.end_date = x.end_date
          xxx.status = x.status
        }
      })
    })
  })

  return courses
}