import {makeAutoObservable, toJS} from "mobx";
import _ from 'lodash'
import {theme} from "antd";

class OrganizationStore {
  tree = []
  treeFetch = []

  constructor() {
    makeAutoObservable(this)
  }

  getTree(){
    return this.tree
  }

  getTreeFetch(){
    return this.treeFetch
  }

  fetchData(request) {
    let r1 = request(`/api/positions/getPositions/0/999`)

    Promise.all([r1]).then((result)=>{
      let [positions] = result
      let p = positions.filter(x => {
        if(x.id !== 100 && x.id !== 101) return x
      })

      this.treeFetch = [...p]
      let positionsTree = this.positionsTree(p)

      this.tree = this.positionUserTree(positionsTree)
    })
  }

  fetchDataByUser(request, id, parent) {
    let r1 = new Promise((res)=>{
      request(`/api/users/getUserById/${id}`)
        .then(data => {
          res(data)
        })
    })

    let r2 = new Promise((res)=>{
      request(`/api/users/getLeaderByUserId/${id}`)
        .then(data => {
          res(data)
        })
        .catch(err => {
          res({id:id})
        })
    })

    Promise.all([r1, r2])
      .then(promises => {
        let [user, leader] = promises
        user.leader = leader
        parent.bio = user
      })
  }


  positionsTree = (positions) => {
    let delArr = []

    let filterNull = positions.filter(item => {
      if(!item.workers.length || !item.parent_id) return false
      return item
    })

    positions = _.sortBy(filterNull, 'id')

    positions.forEach(parent => {
      parent.tree = []

      positions.forEach((xx, ii) => {
        if(parent === xx) return;

        if(parent.id === xx.parent_id) {
          parent.tree.push(xx)
          delArr.push(ii)
        }
      })
    })

    delArr = _.uniq(delArr)

    positions = positions.filter((f,i) => !delArr.includes(i))

    return positions
  }


  positionUserTree = (positions) => {

    let final = []

    const fn = (position, plase, leader) => {

      position.workers.forEach((worker, i) => {

        let fullName = worker.name === "Вакант" ? "Вакантная должность" :
          worker.family +" "+worker.name +" "+worker.surname

        let el = {
          position: {
            name: position.name,
            id: position.id
          },
          worker: {...worker, leaderId: leader?.id, fullName: fullName},
          tree: null,
          final: [],
          leader: {
            position: {
              name: leader?.name,
              id: leader?.id
            },
            worker: {
              name: leader?.workers[0].name,
              id: leader?.workers[0].id,
            }
          },
          sub: []
        }

        el.sub = worker.subjects.map(s => s.id)

        el.tree = _.cloneDeep(position.tree)

        let findCount = 0
        el.tree.forEach(tr => {
          tr.workers = tr.workers.filter( w => el.sub.includes(w.id))
          findCount = tr.workers.length
        })

        //if(!findCount) el.tree = []

        el.tree && el.tree.forEach(x => fn(x , el.final, position))

        plase.push(el)
      })
    }

    positions.forEach(position => fn(position , final))

    return final
  }
}


export default new OrganizationStore()