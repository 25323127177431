import {useEffect, useState} from "react";
import {Input} from "../../../../components/Input/Input";
import {Space} from "../../../../components/Space";
import {DeleteOutlined, PlusCircleTwoTone} from "@ant-design/icons";
import {Select} from "antd";

export const SaveModeEducation = ({defValue, save, clear}) => {
  let [rows, setRows] = useState([])
  let [edit, setEdit] = useState(false)
  let keyVal = [`УРОВЕНЬ` ,`УЧЕБНОЕ ЗАВЕДЕНИЕ`, 'ФАКУЛЬТЕТ', 'СПЕЦИАЛИЗАЦИЯ', 'ГОД ОКОНЧАНИЯ']
  let levels = [`Среднее`, `Среднее специальное`, `Неоконченное высшее`, `Высшее`,
    `Баклавр`, `Магистр`, `Кандидат наук`, `Доктор наук`]

  useEffect(()=>{
    setRows([getRow()])
  },[])


  const getRow = (data) => {
    let element = {id: Math.random(), keys: []}
    keyVal.forEach(x => {
      let val = (data && data.hasOwnProperty(x)) ? data[x] : ''
      element.keys.push({
        title : x,
        value : val,
      })
    })
    return element
  }

  const addRow = () => {
    let copy = [...rows]
    copy.push(getRow())
    setRows(copy)
  }

  useEffect(()=>{
    if(edit) return
    if(!defValue) return
    setEdit(true)

    let newRows = []
    let lines = defValue.split(';')

    lines.forEach(x=> {
      let items = x.split(',')
      let obj = {}

      items.forEach(y => {
        let keyVals = y.split(':')
        obj[keyVals[0]] = keyVals[1]
      })

      let row = getRow(obj)
      newRows.push(row)
    })

    setRows(newRows)
  },[defValue])

  useEffect(()=>{
    let r = getSaveData(rows)
    if(!r) return
    let e = {target:{value:r}}
    save(e)
  },[rows] )

  const getSaveData = (rows) => {
    let txt = ""
    rows.forEach((x,ii) => {
      let add = false
      if(ii !== 0){}

      x.keys.forEach( (xx, i)  => {
        let last = i === 4
        if(xx.title && xx.value){
          if(ii!==0 && !add){
            add = true
            txt = txt + ';'
          }
          txt += `${xx.title}:${xx.value}${last ? "" :","}`
        }
      })
    })
    return txt
  }

  const delRow = (id) => {
    if(rows.length === 1) return
    let copy = rows.filter(x=> x.id !== id)
    setRows(copy)
  }

  const saveRow = (e, rowIndex, itemIndex ) => {
    let copy = [...rows]
    copy[rowIndex].keys[itemIndex].value = e.target.value
    setRows(copy)
  }

  const saveSelect = (e, rowIndex, itemIndex ) => {
    let copy = [...rows]
    copy[rowIndex].keys[itemIndex].value = e
    setRows(copy)
  }

  return (
    <div>
      {rows.map((row, i) => {
        let canDel = i>0
        let last = rows.length === i+1

        return (
          <div key={row.id} >
            <div className={`titleDate`}>
              ОБРАЗОВАНИЕ № {i+1}
              {canDel && <span className={'m10'}> <DeleteOutlined onClick={ ()=>delRow(row.id) }/> </span>}
              <span className={'m10'}>{last && <PlusCircleTwoTone onClick={addRow}/> }</span>
            </div>

            <Space h={15}/>

            {row.keys.map((key, ii) => {
              return(
                <div className="itemOne">
                  <div className={'itemTit'}>{key.title}</div>
                  {key.title === 'УРОВЕНЬ' &&
                  <Select
                    className={`editUserSelect`}
                    value={key.value}
                    onChange={(value) => saveSelect(value, i, ii )}
                  >
                      {levels.map(y => {
                        return (<Select.Option value={y} key={y}>{y}</Select.Option>)
                      })}
                    </Select>
                  }
                  {key.title !== 'УРОВЕНЬ' &&
                    <Input
                      defValue={key.value}
                      clear={clear}
                      cb={(e)=>saveRow(e, i, ii )}
                    />
                  }
                </div>
              )
            })}
          </div>
        )})}
    </div>
  )
}
