import {makeAutoObservable, toJS} from "mobx";
import {notification} from "antd";

class UsersListStore {
  allUsers = []

  constructor() {
    makeAutoObservable(this)
  }

  getUsers(){
    return this.allUsers
  }

  getUsersNotNull(){
    return this.allUsers.filter(x => {
      if(x.name !== 'Вакант' && x.position.id !== 101 && x.position.id !== 100){
        return x
      }
    })
  }

  getUsersNotNull2(){
    return this.allUsers.filter(x => {
      if(x.name !== 'Вакант' && x.position.id !== 100){
        return x
      }
    })
  }

  async deleteUser(request, userId){
    return request(`/api/users/movePositionByUser/Cancel/${userId}/0` , 'put')
      .then(data => {
        return true
      })
      .catch(()=>{
        return false
      })
  }

  async createUser(request, data){
    return request(`/api/users/register`, `post`, data)
      .then(r => {
        this.notificationSuccess('Пользователь добавлен')
        return true
      })
  }


  async editUser(request, user){
    return request(`/api/users/update/${user.id}`, "PUT", user)
      .then(data => {
        this.notificationSuccess('Изменения сохранены')
        return true
      })
  }

  fetchList(request){
    request('/api/users/list/0/999')
      .then(r => {
        this.allUsers = r
      })
  }

  notificationSuccess(text) {
    notification.success({
      message: text,
      className: 'text_notification',
      duration: 3
    })
  }
}

export default new UsersListStore()
