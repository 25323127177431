import {columns} from "../../Courses/reqTableColumn";
import {Table} from "antd";
import DetailUser from "../../../store/DetailUser";
import {toJS} from "mobx";
import {useEffect, useState} from "react";
import {ModalInfo} from "../../Competencies/components/ModalInfo";
import moment from "moment";
import {Input} from "../../../components/Input/Input";
import {PaginationForTable} from "../../../components/Pagination";
import {useHttp} from "../../../hooks/http.hook";
import {InputSearch} from "../../../components/InputSearch/Input";
import RequarementsEmployees from "../../../store/RequarementsEmployees";
import {observer} from "mobx-react-lite";
import {CustomTable} from "../../../components/CustomTable";


const RequirementsTable = observer(({userId, tableHeight }) => {
  let [modalShow, setModalShow] = useState({type:null, data:null})
  let [searchText, setSearchText] = useState(null)
  let [childListOpen, setChildListOpen] = useState([])


  let data = RequarementsEmployees.getCoursesByUserId(userId)

  const modelShowFn = (item) => {
    setModalShow({type:`info`, data: item})
  }

  const addChildList = (id) => {
    let list = [...childListOpen]

    let f = list.findIndex(x => x === id)

    if(f>-1) list.splice(f,1)
    if(f === -1) list.push(id)

    setChildListOpen(list)
  }

  const coursesTable  = () => {
    let filterData = filterByName(data, searchText)
    filterData = filterByStatus(filterData)

    let mustHave = [{
      key: 'mustHave',
      name: <div className={'groupBlock'}>Обязательны к обучению</div>,
    }]

    let over = [{
      key: 'noMust',
      name: <div className={'groupBlock'}>Не обязательные</div>,
    }]


    const pushEl = (item, arr) => {
      let element = {
        key: item.id,
        name: <div className={'flexCenterVert firstChar'}>
          <span style={{marginLeft: '20px'}}>{item.course_name}</span>
          <i className={`infoBtn`} onClick={()=>modelShowFn(item)}/>
        </div> ,
        status:  <div className={'statusMain status_table'} title={item.statusTitle}> {item.status} </div>,
        start_date: item.begin_date ? moment(item.begin_date).format("DD.MM.YYYY") : 'Не указано',
        end_date: item.end_date ? <div className={'end_date_table'}> {moment(item.end_date).format("DD.MM.YYYY")} </div> : 'Не указано',

        item: item
      }
      arr.push(element)
    }

    filterData.forEach((gr, i) => {
      let requiredFlag = false
      let noRequiredFlag = false

      gr.courses.forEach(x => {
        let keyReq = `req_${gr.title}`
        let keyNReq = `nreq_${gr.title}`

        if(x.required && !requiredFlag){
          requiredFlag = true
          mustHave.push({
            key: keyReq,
            name:
              <div className={'flex'}> {gr.title}
                <p className={`arrowTable ${!childListOpen.includes(keyReq) ? "arrowTableUp" : ""}`}
                   onClick={()=>addChildList(keyReq)}/>
              </div>
          })
        }
        if(x.required && !childListOpen.includes(keyReq)) pushEl(x, mustHave)

        if(!x.required && !noRequiredFlag){
          noRequiredFlag = true
          over.push({
            key: keyNReq,
            name:
              <div className={'flex'}> {gr.title}
                <p className={`arrowTable ${!childListOpen.includes(keyNReq) ? "arrowTableUp" : ""}`}
                   onClick={()=>addChildList(keyNReq)}/>
              </div>
          })
        }
        if(!x.required && !childListOpen.includes(keyNReq)) pushEl(x, over)
      })
    })

    let r = []
    if(mustHave.length>1) r = [...mustHave]
    if(over.length>1) r = [...r , ...over]
    return r
  }

  const filterByStatus = (data) => {
    data.forEach(gr => {
      gr.courses.forEach(x => {

        if(x.status === "Не выставлен"){
          x.statusTitle = 'Не выставлен'
          return
        }
        if(x.status === "Просрочен"){
          x.statusTitle = 'Срок действия истек'
          return
        }

        if(x.status !== "Просрочен"){
          let end_date = moment(x.end_date, "YYYY-MM-DD").unix()
          let current_date = moment().unix()
          let oneMonth = (end_date - current_date ) / 86400 <= 30
          x.statusTitle = oneMonth ? 'Cок действия скоро заканчивается' : 'Всё хорошо'
        }
      })
    })

    return data
  }

  const filterByName = (data, text) => {
    if(!text) return data
    let final = []

    data.forEach(x => {
      let c = x.courses.filter(xx => {
        if(x.title.toLowerCase().includes(text.toLowerCase())) return xx
        if(xx.course.course_name.toLowerCase().includes(text.toLowerCase())) return xx
      })
      if(c.length) final.push({...x,courses:c })
    })

    return final
  }


  const getRowClassName = (el) => {
    if(el.key === 'mustHave' || el.key === 'noMust') return 'title_must_row'
    let status = el?.item?.status

    if(!status) return 'title_row'
    if (status === 'Просрочен') return 'title_row_overDay'
    if (status === 'Не выставлен') return 'title_row_no_status'

    if((status !== 'Просрочен')){
      return (el.item.statusTitle === 'Всё хорошо') ? 'title_row_normal' : 'title_row_oneMonth'
    }
  }

  return (
    <>
      <div className="flexRow searchTable">
        <InputSearch placeholder={'Поиск...'}
                     cb={(e)=>setSearchText(e.target.value)}
                     cleanAll={()=>setSearchText(null)} />
      </div>

      <CustomTable
        rowClassName={getRowClassName}
        columns={columns}
        dataSource={coursesTable()}
        tableHeight={tableHeight}
      />

      {modalShow?.type==='info' &&
        <ModalInfo
          closeFn={()=>setModalShow(false)}
          data={modalShow.data}
        />}
    </>
  )
})

export default RequirementsTable