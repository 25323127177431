import {useEffect, useState} from "react";
import './index.sass'
import {Input} from "../../../../components/Input/Input";
import {Space} from "../../../../components/Space";
import {Button} from "../../../../components/Button";
import {useHttp} from "../../../../hooks/http.hook";
import Admin from "../../../../store/Admin/Admin";
import {InputSearch} from "../../../../components/InputSearch/Input";
import {MilkLine} from "../../../../components/MilkLine";
import {toJS} from "mobx";
import UsersList from "../../../../store/Admin/UsersList";

export const Bookkeep = () => {
  const [result , setResult] = useState({})
  const [searchInput, setSearchInput] = useState('')
  const [searchList, setSearchList] = useState([])
  let [searchShow, setSearchShow] = useState(false)
  let [block , setBlock] = useState(true)

  const {request , requestAxios} = useHttp()

  const [files, setFiles] = useState(null)

  useEffect(()=>{
    UsersList.fetchList(request)
  }, [])

  let users = UsersList.getUsersNotNull2()

  useEffect(()=>{
    if(!searchInput) {
      setSearchShow(false)
      setSearchList([])
      return;
    }

    if(searchInput.length < 3) return;

    let filteredList = users.filter(x => {
      if (x.name !== 'Вакант'){
        let fullName = (x.family +" "+ x.name +" "+ x.surname).toLowerCase().includes(searchInput.toLowerCase())
        let t_num = x.t_number && x.t_number.includes(searchInput)
        if(t_num || fullName) return x
      }
    })

    if(filteredList.length>0) {
      setSearchShow(true)
    }else{
      setSearchShow(false)
    }

    setSearchList(filteredList)
  }, [searchInput])


  const sendFile = () => {
    if(block) return

    if(result && result.name){
      Admin.savePaysLips(requestAxios, files, result.id)

      let el = document.querySelector(".bookInput")
      if(el){
        el.value = null
      }
      setFiles(null)
      setBlock(true)
    }
  }

  const selectFile = (e) => {
    let files = e.target.files
    setFiles(files)
    setBlock(false)
  }

  const clickInput = () => {
    if(searchList.length>0) setSearchShow(true)
  }

  const searchClick = (child) => {
    setResult(child)
    setSearchShow(false)
  }

  const backToStart = () => {
    setSearchShow(false)
    setSearchList([])
    setResult(null)
  }

  const SearchBlock = () => {
    return (
      <div className="search">
        <div className={'inputZone'}>
          <div className={`searchShos`}>
            <InputSearch placeholder={'Поиск сотруника по ФИО и табельному номеру...'}
                         cb={(e)=>setSearchInput(e.target.value)}
                         onClick={clickInput}
                         cleanAll={backToStart}
            />
          </div>

          {searchShow && <div className="blockResult1">
            {searchList.map(x => {
              let fullName = x.family +" "+ x.name +" "+ x.surname
              return  <span className={`line`} onClick={()=>searchClick(x)} key={x.id}>
                <span>№ {x.t_number}</span>
                <p className={'w20'}/>
                <span>{fullName}</span>
              </span>
            })}
          </div>
          }
        </div>
      </div>
    )
  }


  return (
    <div id={'bookkeep'} className={'bookkeep'}>

      <div className="title">Расчетный лист</div>
      <MilkLine/>

      <Space h={15}/>

      {SearchBlock()}

      <Space h={15}/>

      <div className="gridAdmin">

        {result && result.name && (
          <>
            <span>Фамилия</span>
            <span>{result.family}</span>

            <span>Имя</span>
            <span>{result.name}</span>

            <span>Отчество</span>
            <span>{result.surname}</span>

            <span>Табельлный номер</span>
            <span>{result.t_number}</span>
          </>
        )}

      </div>

      {result && result.name && (
        <>
          <div className="gridAdmin"
               title={`Формат имени файла -  ГОД_МЕСЯЦ_НАЗВАНИЕ, например 2021_3_Иван.txt`}>
            Выбрать файл
            <input type='file' className={'bookInput'} onChange={selectFile}/>
          </div>

          <div className="gridAdmin gridAdminGray">
            <span>Формат имени файла</span>
            <span>
              ГОД_МЕСЯЦ_ТИПВЫПЛАТЫ
            </span>
            <span></span>
            <span>
              Например: 2023_1_Аванс.xlsx
            </span>
          </div>
        </>
      )}

      <div className={'row2'}>
        <Button
          addStyleClass={block ? 'block_color' : ""}
          name={"Загрузить"} cb={sendFile} />
      </div>

    </div>
  )
}
