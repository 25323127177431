import {Modal} from "../../../../components/Modal";
import "./index.sass"
import {toJS} from "mobx";
import {useEffect, useState} from "react";
import DetailUser from "../../../../store/DetailUser";
import {observer} from "mobx-react-lite";
import {useHttp} from "../../../../hooks/http.hook";


export const ModalInfo = observer(({closeFn, data, title=null}) => {
  let [userId, setUserId] = useState(data?.owner_detail?.id || data?.responsible?.id)
  let {request} = useHttp()

  useEffect(()=>{
    if(userId) DetailUser.fetchUserById(request , userId)
  }, [])

  let user = DetailUser.getUserById(userId)
  let {name, family, surname, e_mail, phone_work} = user || {}

  return (
    <Modal
      closeFn={closeFn}
      title={title || data.comp_name || data.course_name || data.course?.course_name}
      wrapClose={true}
    >
      <div id={'info_modal_id'}>

        <div className={'text_info'}>
          {data.description || data.course?.course_name}
        </div>

        {userId &&
          <div className={'responsibleDetail'}>
            <p><span className={'key'}>ответственный </span>   </p>
            <p><span className={'key'}>фио: </span> <span className={'value'}>{family} {name} {surname}</span> </p>
            {phone_work && <p><span className={'key'}>телефон: </span> <span className={'value'}>{phone_work} </span> </p>}
            {e_mail && <p><span className={'key'}>email: </span> <span className={'value'}>{e_mail} </span> </p>}
          </div>
        }
      </div>
    </Modal>
  )
})
