import React from "react";
import {Route, Switch , Redirect} from 'react-router-dom'
import {Login} from "./pages/Login/Login";
import {Base} from "./pages/Base";
import Profile from "./pages/Profile";
import {CompetenciesPage} from "./pages/Competencies";
import {Courses} from "./pages/Courses";
import {Statistics} from "./pages/Statistics";
import {Estimated} from "./pages/Estimated";
import {Organization} from "./pages/Organization";
import {Employees} from "./pages/Employees";
import {Settings} from "./pages/Settings";
import {Admin} from "./pages/Admin";
import {User} from "./pages/Admin/pages/User";
import {MainMode} from "./pages/Admin/pages/User/UserReed/MainMode";
import {Company} from "./pages/Admin/pages/Company";
import {Compencies, CompetenciesAdmin} from "./pages/Admin/pages/CompetenciesAdmin";
import {CoursesAdmin} from "./pages/Admin/pages/CoursesAdmin";
import {Positions} from "./pages/Admin/pages/Positions";
import {Shos} from "./pages/Admin/pages/Shos";
import {DetailPage} from "./pages/Employees/components/DetailPage";
import {Bookkeep} from "./pages/Admin/pages/Bookkeep";
import {NewPosition} from "./pages/Admin/pages/Positions/NewPosition";
import {NewComp} from "./pages/Admin/pages/CompetenciesAdmin/NewComp";
import {NewCourse} from "./pages/Admin/pages/CoursesAdmin/NewCourse";
import {Departments} from "./pages/Admin/pages/Departments";
import {UserCourse} from "./pages/Admin/pages/UserCourse";
import {UsersList} from "./pages/Admin/pages/UserListAll";
import {Files} from "./pages/Admin/pages/Files";


export const useRoutes = (isAuth) => {
  if(isAuth){
    return (
      <Switch>
        <Route path={'/profile'} exact>
          <Base>
            <Profile/>
          </Base>
        </Route>

        <Route path={'/competencies'} exact>
          <Base>
            <CompetenciesPage/>
          </Base>
        </Route>

        <Route path={'/courses'} exact>
          <Base>
            <Courses/>
          </Base>
        </Route>

        <Route path={'/estimated'} exact>
          <Base>
            <Estimated/>
          </Base>
        </Route>

        <Route path={'/organization'} exact>
          <Base>
            <Organization/>
          </Base>
        </Route>

        <Route path={'/statistics'} exact>
          <Base>
            <Statistics/>
          </Base>
        </Route>

        <Route path={'/employees'} exact>
          <Base>
            <Employees/>
          </Base>
        </Route>

        <Route path={'/employees/:id'} exact>
          <Base>
            <DetailPage/>
          </Base>
        </Route>

        <Route path={'/settings'} exact>
          <Base>
            <Settings/>
          </Base>
        </Route>

        <Route path={'/admin'} exact>
          <Base admin={true}>
            <User/>
          </Base>
        </Route>

        <Route path={'/admin/users'} exact>
          <Base admin={true}>
            <UsersList/>
          </Base>
        </Route>

        <Route path={'/admin/users/new'} exact>
          <Base admin={true}>
            <User/>
          </Base>
        </Route>

        <Route path={'/admin/users/reed'} exact>
          <Base admin={true}>
            <MainMode/>
          </Base>
        </Route>

        <Route path={'/admin/shos'} exact>
          <Base admin={true}>
            <Shos/>
          </Base>
        </Route>

        <Route path={'/admin/departments'} exact>
          <Base admin={true}>
            <Departments/>
          </Base>
        </Route>


        <Route path={'/admin/positions'} exact>
          <Base admin={true}>
            <Positions/>
          </Base>
        </Route>

        <Route path={'/admin/positions/:id'}>
          <Base admin={true}>
            <NewPosition/>
          </Base>
        </Route>
        <Route path={'/admin/positions/new'} exact>
          <Base admin={true}>
            <NewPosition/>
          </Base>
        </Route>

        <Route path={'/admin/courses'} exact>
          <Base admin={true}>
            <CoursesAdmin/>
          </Base>
        </Route>

        <Route path={'/admin/user_courses'} exact>
          <Base admin={true}>
            <UserCourse/>
          </Base>
        </Route>

        <Route path={'/admin/courses/new'} exact>
          <Base admin={true}>
            <NewCourse/>
          </Base>
        </Route>

        <Route path={'/admin/competencies'} exact>
          <Base admin={true}>
            <CompetenciesAdmin/>
          </Base>
        </Route>

        <Route path={'/admin/competencies/new'} exact>
          <Base admin={true}>
            <NewComp/>
          </Base>
        </Route>

        <Route path={'/admin/company'} exact>
          <Base admin={true}>
            <Company/>
          </Base>
        </Route>


        <Route path={'/admin/byx'} exact>
          <Base admin={true}>
            <Bookkeep/>
          </Base>
        </Route>


        <Route path={'/admin/files'} exact>
          <Base admin={true}>
            <Files/>
          </Base>
        </Route>



        <Redirect to={'/profile'}/>
      </Switch>
    )
  }else{
    return (
      <Switch>
        <Route path={'/'} exact>
          <Login/>
        </Route>

        <Route path={'/forget'} exact>
          <Login forget={true}/>
        </Route>

        <Redirect to={'/'}/>
      </Switch>
    )
  }
}