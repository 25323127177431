import {Space} from "../../../../components/Space";
import {AvatarProfile} from "../avatar/AvatarProfile";
import './index.sass'
import {CloseBtn} from "../../../../components/CloseBtn";
import {PopUpClose} from "../../../../components/PopUpClose";

export const MainMode = ({previewMode ,setPreviewMode,  user}) => {
  if(previewMode) return null;

  if(!user.name) return null;
  let {organization, department} = user.position

  const getRow = (item) => {
    let r = []
    if(!item) return null

    let arr = item.split(';')
    if(arr){
      arr.forEach(y => {
        let lines = y.split(',')
        lines.forEach(x=>{
          let keyVal = x.split(':')
          r.push(<span><span>{keyVal[0]}:</span>{keyVal[1]}</span>)
          r.push(<br/>)
        })
        r.push(<Space h={25}/>)
      })

    }else{
      let lines = item.split(',')

      lines.forEach(x=>{
        let keyVal = x.split(':')
        r.push(<span><span>{keyVal[0]}:</span>{keyVal[1]}</span>)
        r.push(<br/>)
      })
    }

    return r
  }

  return (
    <div className={'main_mode'}>
      <Space h={30}/>
      <div className="main_info">
        <AvatarProfile
          size={150}
          avatar_url={user.avatar_url ? user.id +"_"+user.avatar_url : null }
        />

        <div className="main_info_name">
          <p className={'name'}> {user.family} {user.name} {user.surname}</p>
          <p className={'t_number'}>Табельный номер:<span>{user.t_number}</span> </p>
        </div>


        <CloseBtn size={20} right={0} top={0} fn={setPreviewMode}/>

      </div>


      <div className="block">
        <div className="title">Личные данные:</div>

        <div className="grid_block">
          <div>
            <span className={'mgB2'}><span>ФИО: </span>{user.family} {user.name} {user.surname}</span>
            <span className={'mgB2'}><span>Дата рождения: </span>29.12.2022</span>
            <span className={'mgB2'}><span>Пол: </span>{user.gender}</span>
            <span className={'mgB2'}><span>Граджанство: </span>{user.citizenship}</span>
            <span className={'mgB2'}><span>Военная служба: </span>{user.military} </span>
            <span className={'mgB2'}><span> Личный телефон: </span>{user.phone_mobile} </span>
          </div>
          <div>
            {getRow(user.education)}
          </div>

        </div>
      </div>

      <Space h={40}/>

      <div className="block">
        <div className="title">Организационные данные:</div>

        <div className="grid_block fr1">
          <span><span>Должность: </span>{user.position.name}</span>
          <span><span>Отдел: </span>{department.name}</span>
          <span><span>Компания: </span>{organization.name} </span>
          <span><span>Дата трудоустройства: </span>{user.placement_date} </span>
          <span><span>Рабочий телефон: </span>{user.phone_work} </span>
        </div>
      </div>

      <Space h={40}/>

      <div className="block">
        <div className="title">Семья:</div>

        <div className="grid_block">
          <div>
            <span className={'mgB2'} ><span>Семейное положение: </span>{user.maritalStatus} </span>
            <span className={'mgB2'} ><span>Дата свадьбы: </span>{user.wedding_date} </span>
            <span className={'mgB2'} ><span>Контакты для связи в случаи ЧС: </span></span>
            {getRow(user.family_contact)}

          </div>
          <div>
            <span><span>Члены семьи:</span></span><br/>
            {getRow(user.family_members)}
          </div>

        </div>
      </div>

      <Space h={40}/>
    </div>
  )
}
