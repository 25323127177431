import {useEffect, useState} from "react";
import './index.sass'
import {Select, Table} from "antd";
import {observer} from "mobx-react-lite";
import {useHttp} from "../../../../hooks/http.hook";
import {useHistory} from "react-router-dom";
import Admin from "../../../../store/Admin/Admin";
import {Button} from "../../../../components/Button";
import {columns} from "./allTableColumn";
import {PaginationForTable} from "../../../../components/Pagination";
import _ from "lodash";
import {ModalInfo} from "../../../Competencies/components/ModalInfo";
import {PopUpAdd} from "../../components/PopUpAdd";
import {toJS} from "mobx";
import {MilkLine} from "../../../../components/MilkLine";
import {AppstoreAddOutlined, DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {CustomTable} from "../../../../components/CustomTable";
import {Space} from "../../../../components/Space";
import {InputSearch} from "../../../../components/InputSearch/Input";
import DelConfirm from "../../../../components/DelConfirm";

const Option = Select.Option

export const CoursesAdmin = observer(() => {
  const [countAll, setCountAll] = useState(0)
  let [modalShow, setModalShow] = useState({type:null, data:null})
  const [activeCat, setActiveCat] = useState(null)
  let [childListOpen, setChildListOpen] = useState([])
  const [showModal, setShowModal] = useState('')
  const [searchInput, setSearchInput] = useState('')

  const {request} = useHttp()
  const history = useHistory()

  const coursesData = Admin.getCoursesAndChild()

  useEffect(()=>{
    Admin.fetchCat(request, 'Курс')
  }, [])


  useEffect(()=>{
    if(coursesData && coursesData.length) {
      let count = 0
      coursesData.forEach(x => count += x.courses.length)
      setCountAll(count)
    }
  }, [coursesData])

  const modelShowFn = (item) => {
    setModalShow({type:`info`, data: item})
  }

  const addChildList = (id) => {
    let list = [...childListOpen]

    let f = list.findIndex(x => x === id)

    if(f>-1) list.splice(f,1)
    if(f === -1) list.push(id)

    setChildListOpen(list)
  }

  const goTo = (gr) => {
    history.push('/admin/courses/new', {edit: {category_id: gr.id}} )
  }

  const editChild = (child,gr) => {
    let copy = _.cloneDeep(child)
    history.push('/admin/courses/new', {edit: {...copy,category_id: gr.id }})
  }

  const getRowClassName = (el) => {
    if(el.key === 'mustHave' || el.key === 'noMust') return 'title_must_row'
    if(!el.type_learning) return 'title_row'
  }

  const filterByName = (data, text) => {
    if(!text) return data
    let textSearch = text.toLowerCase()
    let final = []

    data.forEach(x => {
      let c = x.courses.filter(xx => {

        if(x.categoryName.toLowerCase().includes(textSearch)) return xx

        if(xx.course_name.toLowerCase().includes(textSearch)) return xx
        if(xx.provider && xx.provider.toLowerCase().includes(textSearch)) return xx

        let findByDetails = false
        xx.course_dates.forEach(cd => {
          if(cd.begin_date.toLowerCase().includes(textSearch)) findByDetails = true
          if(cd.place_learning.toLowerCase().includes(textSearch)) findByDetails = true
        })
        if(findByDetails) return xx
      })

      if(c.length) final.push({...x,courses:c })
    })

    return final
  }

  const saveParentItem = (textParentItem) => {
    Admin.addCatCourse(request, textParentItem)
    setShowModal(null)
  }

  const editParent = (group) => {
    setShowModal({
      edit: true,
      name: group.categoryName,
      id: group.id
    })
  }

  const editParentFn = (newName) => {
    let data = {
      categoryName: newName,
      "id": showModal.id,
      "categoryType": "Курс"
    }

    Admin.editCatCourse(request, data)
    setShowModal(null)
  }

  const delParent = (group) => {
    Admin.delCatCourse(request, group.id)
  }



  const delChild = (id) => {
    Admin.delCourse(request, id)
  }


  const getCoursesTable  = () => {
    let filterData = filterByName(coursesData,searchInput)
    let arrAll = []

    const pushEl = (item, arr,gr) => {
      let course_dates = _.sortBy(item.course_dates, 'begin_date');

      let element = {
        key: `c_${item.id}`,
        name:
          <div className={'flexCenterVert firstChar'}>
            <span style={{marginLeft: '20px'}}>{item.course_name}</span>
            <i className={`infoBtn`} onClick={()=>modelShowFn(item)}/>
          </div> ,
        required:  item.required ? "Да" : "Нет",
        place: course_dates.map(x=> <p className={'noWrapLine'}> {x.place_learning} </p>),
        type_learning: item.type_learning,
        cost: item.cost,
        course_dates: course_dates.map(x=> <span> {x.begin_date } <br/> </span> ) ,
        available_places: course_dates.map(x=> <div> {x.available_places} </div>),
        provider: item.provider,
        fn: <div className={'fnsBtnsTable'}>
          <EditOutlined title={'Редактировать запись'}  onClick={()=>editChild(item,gr)}/>
          <DelConfirm title={'Удалить запись'} saveFn={()=>delChild(item.id)}/>
        </div>
      }

      arr.push(element)
    }

    filterData.forEach((gr, i) => {
      let keyReq = `req_${gr.categoryName}`
      let canDelParent = gr.courses.length === 0

      arrAll.push({
        key: keyReq,
        name: <div className={'flex'}> {gr.categoryName}
          <p className={`arrowTable ${!childListOpen.includes(keyReq) ? "arrowTableUp" : ""}`}
             onClick={()=>addChildList(keyReq)}/>
        </div>,
        fn: <div className={'fnsBtnsTable'}>
          <AppstoreAddOutlined title={'Добавить курс'} onClick={()=>goTo(gr)}/>
          <EditOutlined title={'Редактировать запись'}  onClick={()=>editParent(gr)}/>
          {canDelParent && <DeleteOutlined title={'Удалить запись'} onClick={()=>delParent(gr)}/>}
        </div>
      })

      if(!childListOpen.includes(keyReq)) {
        gr.courses.forEach(x => pushEl(x, arrAll,gr))
      }
    })

    return arrAll
  }

  const SearchBlock = () => {
    return (
      <div className="search">
        <div className={'inputZone'}>
          <div className={`searchShos`}>
            <InputSearch placeholder={'Поиск курса...'}
                         cb={(e)=>setSearchInput(e.target.value)}
                         cleanAll={()=>setSearchInput('')}
            />
          </div>
        </div>
      </div>
    )
  }


  return (
    <div id={'CoursesAdmin'} className={'userPage'}>
      <div className="title">Курсы</div>
      <MilkLine/>

      <Space h={15}/>

      <div style={{display: `flex`, flexDirection: `row-reverse`,
        justifyContent: `space-between`, marginBottom: '10px'}}
      >
        <Button name={'Добавить категорию'} cb={()=>setShowModal({new: true})}/>
        {SearchBlock()}
      </div>

      <CustomTable
        columns={columns}
        dataSource={getCoursesTable()}
        tableHeight={window.innerHeight - 246 - 45 -15}
      />

      {modalShow?.type==='info' && <ModalInfo closeFn={()=>setModalShow(null)} data={modalShow.data} />}

      {showModal?.new && <PopUpAdd
        title={'НАИМЕНОВАНИЕ КАТЕГОРИИ КУРСА'}
        save={saveParentItem}
        closeFn={()=>setShowModal(null)}
      />}

      {showModal?.edit && <PopUpAdd
        title={'НАИМЕНОВАНИЕ КАТЕГОРИИ КУРСА'}
        save={editParentFn}
        okText={'Сохранить'}
        defValue={showModal.name}
        closeFn={()=>setShowModal(null)}
      />}

    </div>
  )
})

