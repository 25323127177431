import {useState} from "react";
import './index.sass'

let Base = ({size}) => {

  return (
    <svg width={size} height={size}
         version="1.1"
         id='close_btn_global'
         xmlns="http://www.w3.org/2000/svg" x="0" y="0"
         viewBox="0 0 13.3 13.3">
      <path id="Закрытие" className="st0"

          d="M13 .3c.4.4.4 1 0 1.4L1.7 13c-.4.4-1 .3-1.4-.1-.3-.4-.3-1 0-1.3L11.6.3c.4-.4 1-.4 1.4 0zM1.7.3L13 11.6c.4.4.3 1-.1 1.4-.4.3-1 .3-1.3 0L.3 1.7C-.1 1.3-.1.7.2.3c.4-.4 1-.5 1.5 0 0-.1 0 0 0 0z"/>
      <path transform="rotate(45.001 6.617 6.61)" className="st0" d="M5.6 5.6h2.1v2H5.6z"/>
      <path  transform="rotate(45.001 6.542 6.776)" className="st0" d="M5.5 5.6h2v2.3h-2z"/>
</svg>
  )
}


export const CloseBtn = ({left , size, top, right=null,fn}) => {


  return (
    // <div className="close_btn" onClick={onClick}/>
    <div style={{left:left , right: right , top: top}} className={'global_close_btn'} onClick={fn}>
      <Base className="close_btn_position" size={size}/>
    </div>
  )
}
