import {useEffect, useState} from "react";
import {DeleteOutlined} from "@ant-design/icons";
import ModalConfirm from "../ModalConfirm";


export default ({ saveFn}) => {
  let [modalOpenConfirm, setModalOpenConfirm] = useState({open:false, fn:null})

  const delOpen = () => {
    setModalOpenConfirm(
      {open: true,
        save: saveFn}
    )
  }

  return (
    <>
      <DeleteOutlined title={'Удалить запись'} onClick={delOpen}/>
      {modalOpenConfirm.open && <ModalConfirm
        saveFn={modalOpenConfirm}
        closeFn={()=>setModalOpenConfirm({open: false, fn:null})}/>}
    </>
  )
}
