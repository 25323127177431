import {makeAutoObservable, toJS} from "mobx";

class StatisticStore {
  departments = [{
    id: 0,
    name: `Вся компания`,
    statistic: {
      totalCountPeople: 0
    }
  }]

  constructor() {
    makeAutoObservable(this)
  }

  getData(){
    return this.departments.filter(x => x.name !== 'Уволенные')
  }

  getStatistic(id){
    return this.departments.find(x => x.id === id).statistic
  }

  clearDate(){
    this.departments = [{
      id: 0,
      name: `Вся компания`,
      statistic: {
        totalCountPeople: 0
      }
    }]
  }

  fetchData(request) {
    request(`/api/departments/getDepartments/0/999`)
      .then(data => {
        data.forEach(x => x.statistic = {})
        this.departments.push(...data)

        this.fetchStatistic(request, 0, true)
      })
  }

  fetchStatistic(request, id, first){
    if(!first){
      let fIndex = this.departments.findIndex(x => {
        if(x.id === id && x.statistic.hasOwnProperty('totalCountPeople')) return true
      })
      if(fIndex > -1 ) return
    }

    request(`/api/statistic/getStatisticByDepartmentId/${id}`)
      .then(data => {
        this.departments.forEach(x => {
          if(x.id === id) x.statistic = data
        })
      })
  }

}

export default new StatisticStore()