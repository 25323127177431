import {useEffect, useState} from "react";
import "./index.sass"
import {Input} from "../../../../../components/Input/Input";
import Admin from "../../../../../store/Admin/Admin";
import {useHttp} from "../../../../../hooks/http.hook";
import {Button} from "../../../../../components/Button";
import {Select} from "antd";
import {toJS} from "mobx";
import {useHistory} from "react-router-dom";
import {Space} from "../../../../../components/Space";
import {CloseBtn} from "../../../../../components/CloseBtn";
import {MilkLine} from "../../../../../components/MilkLine";
import {Modal} from "../../../../../components/Modal";
import {SchooseModal} from "./schooseModal";
import {SchooseModal2} from "./schooseModal2";
import {PopUpClose} from "../../../../../components/PopUpClose";


const Option = Select.Option

const base = (data) => {
  return {
    "id": data.id || null,
    "department_id": data.department?.id || null,
    "name": data.name || "",
    "instruction": data.instruction || "",
    "organization_id": 1,
    "parent_id": data.parent_id || null
  }
}

export const NewPosition = (prop) => {
  const [edit, setEdit] = useState(null)
  const [error, setError] = useState(false)
  const [clear, setClear] = useState(false)
  const [data, setData] = useState(base({}))
  const [competencesNew, setCompetencesNew] = useState([])
  const [coursesNew, setCoursesNew] = useState([])
  const [modalOpen, setModalOpen] = useState(null)
  const [saveComp, setSaveComp ] = useState([])
  let [popUpCloseShow, setPopUpCloseShow] = useState(false)
  let [haveChange, setHaveChange] = useState(false)
  let [block, setBlock] = useState(false)

  const {request} = useHttp()
  const history = useHistory()

  useEffect(()=>{
    Admin.fetchDepartments(request)
    let editState = history.location?.state
    const getCompCategories = Admin.getCompParentChildNotFree()
    const getCoursesCategories = Admin.getCoursesAndChild()

    if(editState){
      let r1 = update(getCompCategories, editState.competences)
      let r2 = update2(getCoursesCategories, editState.courses)
      setCompetencesNew(r1)
      setCoursesNew(r2)

      let data = base(editState)
      setData(data)
      setEdit(true)
    }else{
      setCompetencesNew(getCompCategories)
      setCoursesNew(getCoursesCategories)
    }
  }, [])


   let dep = Admin.getDepartments2()

  const getPositions = Admin.getPositions()


  const update = (all, data) => {
    let ob = {}
    data.forEach(x => ob[x.competence.id] = x.req_level)

    all.forEach(gr => {
      gr.comp.forEach(x => {

        if(ob[x.id]){
          x.active = true
          x.req_level = ob[x.id]
        }
      })
    })
    return all
  }

  const update2 = (all, data) => {
    let arr = []
    data.forEach(x => arr.push(x.id))

    all.forEach(gr => {
      gr.courses.forEach(x => {
        if(arr.includes(x.id)){
          x.active = true
        }
      })
    })
    return all
  }

  useEffect(()=>{
    setData(current => {
      let copy = {...current}

      let findId = dep.find(x=> x.name !== 'Уволенные')
      copy.department_id = findId?.id
      return copy
    })
  }, [dep] )


  const save = async () => {
    if(block) return
    if(!data.name || !data.parent_id){
      setError(true)
      return
    }

    setBlock(true)
    if(edit){
      await Admin.editPosition(request, data, saveComp, coursesNew)
    }else{
       await Admin.addPosition(request, data, saveComp, coursesNew)
    }
    setBlock(false)

    goBackTrue()
  }

  const saveInput = (e, key) => {
    setHaveChange(true)
    let copy = {...data}
    copy[key] = e.target.value
    setData(copy)
  }


  const goBack = () => {
    if(haveChange){
      setPopUpCloseShow(true)
      return
    }

    goBackTrue()
  }

  const goBackTrue = () => {
    history.push("/admin/positions")
  }

  const changeSelect = (id , type) => {
    let copy = {...data}
    copy[type] = id
    setData(copy)
  }


  const saveCompetencies = (saveData) => {
    setSaveComp(saveData)
    setCompetencesNew(saveData)
  }

  const saveCourses = (saveData) => {
    setCoursesNew(saveData)
  }

  const compList = () => {
    return (
      <div>
        {competencesNew.map(x=>{

          let f = x.comp.find(ff => ff.active)
          if(!f) return ;

          return(
            <div key={x.categoryName} className="gridList">

              <span>{x.categoryName}</span>

              <div className="listInner ">
                {x.comp.map(xx => {
                  if(!xx.active) return
                  let level = xx.req_level === 'На_практике' ? 'На практике' : xx.req_level
                  return <div className={'gridList2'} key={xx.id}>
                    <span>{xx.comp_name}</span>
                    <span>{level}</span>
                  </div>
                })}
              </div>

              <Space h={15}/>
            </div>
          )
        })}

      </div>
    )
  }

  const coursesList = () => {
    return (
      <div>
        {coursesNew.map(x => {
          let f = x.courses.find(ff => ff.active)
          if(!f) return ;

          return(
            <div key={x.categoryName} className="gridList">

               <span>{x.categoryName}</span>

               <div className="listInner ">
                 {x.courses.map(xx => {
                   if(!xx.active) return
                   return <div className={'gridList2'} key={xx.id}>
                     <span>{xx.course_name}</span>
                     <span>{xx.req_level}</span>
                   </div>
                 })}
               </div>

               <Space h={15}/>
            </div>
          )
        })}

      </div>
    )
  }

  return (
    <div id={'newPosition'} className={'newPosition'}>
      <div className="title">{ edit ? 'Сохранить ' : 'Создать '} должность</div>
      <MilkLine/>

      <Space h={30}/>

      <CloseBtn size={20} fn={goBack} top={10} right={0}/>

      <div className={'gridAdmin'}>

        <p>НАЗВАНИЕ ДОЛЖНОСТИ</p>
        <Input
          defValue={data.name}
          error={error}
          clear={clear}
          cb={(e)=>saveInput(e,`name`)}
        />

        <p>ДЕПАРТАМЕНТ</p>
        <Select
          className={'editAdminSelect'}
                onChange={(id)=>changeSelect(id, `department_id`)}
          value={data.department_id} >
          {dep.map(x=> {
            if(x.name === 'Уволенные') return
            return (
              <Option key={x.id} value={x.id}> {x.name} </Option>
            )
          })}
        </Select>

        <p>РУКОВОДЯЩАЯ ДОЛЖНОСТЬ</p>
        <div>
          <Space h={10}/>
          <Select className={`editAdminSelect ${error && !data.parent_id ? "empty_info": ""} `} style={{width: '550px'}}
                  onChange={(id)=>changeSelect(id ,'parent_id')} value={data.parent_id} >
            <Option key={0} value={0}> Не выбрано </Option>

            {getPositions.map(x=> {
              return <Option key={x.id} value={x.id}>
                <div className={`posBtw`}>
                  <span> {x.name}  </span>
                  <span className={'dep'}> {x.department.name} </span>
                </div>
              </Option>
            })}
          </Select>
        </div>


        <p>ДОЛЖНОСТНАЯ ИНСТРУКЦИЯ</p>
        <div className="text_area">
           <textarea
             className={'areaAdmin'}
             onChange={(e)=>saveInput(e,`instruction`)}
             value={data.instruction}
           />
        </div>


        <p>КОМПЕТЕНЦИИ</p>
        <div className={'flexColumn'}>
          <div className={'flex'}>
            <Button
              cb={()=>setModalOpen('competencies')}
              name={!competencesNew.length ? 'ВЫБРАТЬ' : 'ИЗМЕНИТЬ'}/>
          </div>
        </div>

        <p></p>
        <div className={'flexColumn'}>
          {compList()}
        </div>

        <p>ОБУЧЕНИЯ</p>
        <div className={'flexColumn'}>
          <div className={'flex'}>
            <Button
              cb={()=>setModalOpen('course')}
              name={!coursesNew.length ? 'ВЫБРАТЬ' : 'ИЗМЕНИТЬ'}/>
          </div>
        </div>

        <p></p>
        <div className={'flexColumn'}>{coursesList()}</div>


        <p></p>
        <div style={{display: 'flex'}}>
          <Button
            addStyleClass={block ? 'block_color' : ""}
            name={edit ? 'Сохранить' : 'Сохранить'} cb={save}/>
        </div>

      </div>

      <Space h={15}/>

      {modalOpen==="competencies" &&
      <Modal title={'ДОБАВЬТЕ КОМПЕТЕНЦИИ'} addClass={'w80'} closeFn={()=>setModalOpen(null)} >
        <SchooseModal data={competencesNew} save={saveCompetencies} close={()=>setModalOpen(null)}/>
      </Modal>}

      {modalOpen==="course" &&
      <Modal title={'ДОБАВЬТЕ КУРСЫ'} addClass={'w60'} closeFn={()=>setModalOpen(null)}>
        <SchooseModal2 data={coursesNew} save={saveCourses} close={()=>setModalOpen(null)}/>
      </Modal>}

      {popUpCloseShow && <PopUpClose save={goBackTrue} closeFn={()=>setPopUpCloseShow(false)} />}


    </div>
  )
}
