import {useCallback, useState} from "react";
import config from '../config/default.js'
import {useAuth} from "./auth.hook";
import axios from "axios";

const serverUrl = process.env.REACT_APP_API_URL || config.serverUrl

export const useHttp = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const {token, logout} = useAuth()

  const requestAxios = useCallback(async (url, file) => {
    let headers = {
      "Authorization": "Bearer " + token,
      "Content-Type": "multipart/form-data"
    }
    let urlFull = serverUrl + url

    return await axios.post(urlFull, file, {headers: headers})
      .then(r => {
        return true
      })
      .catch(r => false)
  }, [])

  const request = useCallback(async (url, method = 'GET', body = null, headers = {}, files) => {
    setLoading(true)

    try {
      if (body && !files) {
        body = JSON.stringify(body)
        headers["Content-Type"] = "application/json"
        headers["Accept"] = "application/json"
      }
      if (files) {
        headers["Accept"] = "application/json"
        headers["Content-Type"] = "multipart/form-data"
      }

      if (token) headers.Authorization = "Bearer " + token
      let urlFull = serverUrl + url
      const response = await fetch(urlFull, {method, body, headers})

      if (response.status === 401) {
        logout()

        return;
        // let u = User.getSelfUsername()
        // if (u) {
        //   setModalOpen(true)
        //   return;
        // } else {
        //   logout()
        // }
      }

      if (response.status === 500) {
        throw false
      }

      let data = {}
      try {
        if (response.status === 404) {
          data = []
        } else {
          data = await response.json()
        }
      } catch (er) {
        console.log(er)
      }

      if (!response.ok) {
        throw new Error(data.message || ' something wrong')
      }

      setLoading(false)
      return data
    } catch (e) {
      setLoading(false)
      setError(e.message)
      throw e
    }
  }, [])

  const clearError = () => {
    setError(null)
  }

  const reAuthRequest = async (headers,user_id) => {
    let urlFull = serverUrl + `/api/auth/newToken`
    try{
      setTimeout( async ()=>{
        const response = await fetch(urlFull, {method:`get`, body: null, headers})
        let t = await response.text()

        localStorage.setItem(`userData`, JSON.stringify({
          token: t,
          user_id: user_id
        }))

        reAuthRequest({Authorization: `Bearer `+ t }, user_id)

      },300000)

    }catch (er){
      logout()
    }

      // let u = User.getSelfUsername()
      // if (u) {
      //   setModalOpen(true)
      //   return;
      // } else {
      //   logout()
      // }
  }

  return {loading, request, requestAxios, error, reAuthRequest, clearError}
}

