import React, {useState} from "react";
import ReactDOM from "react-dom";
import {Modal} from "../../../../components/Modal";
import {Button} from "../../../../components/Button";
import {Space} from "../../../../components/Space";
import './index.sass'
import {InputMail} from "../../../../components/InputMail/Input";
import { notification } from 'antd';


export const ModalForget = ({closeFn}) => {
  let [email, setEmail] = useState('')
  let [error, setError] = useState(false)
  let [clickOne, setClickOne] = useState(false)

  const checkEmailType = (email) => {
    const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
    return EMAIL_REGEXP.test(email);
  }

  const sendForget = () => {
    if(clickOne) return;

    if(!email || !checkEmailType(email)){
      setError(true)
      return
    }

    setClickOne(true)

    setTimeout(()=>{
      openNotificationWithIcon('success')
      closeFn()
    }, 800)
  }

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: `Письмо успешно отправлено на ${email} почту`,
      className: 'text_notification',
      duration: 3
    });
  };


  const changeHandler = (e) => {
    setEmail(e.target.value)
    setError(false)
  }

  return (

    <Modal
      closeFn={closeFn}
      title={'ВОССТАНОВЛЕНИЕ ПАРОЛЯ'}
    >

      <Space h={44}/>

      <div className={'grid'}>
        <label className='input_label' htmlFor={'forget_main'}>Почта</label>

        <InputMail
          error={error}
          cb={changeHandler}
          name='username'
          id='username'
          inputMode='email'
        />

      </div>

      <Space h={108}/>

      <div className={'line'}>
        <div style={{width: '151px', alignSelf: 'center'}}>
          <span className='cancel_btn text_main_2' onClick={closeFn}>
            ОТМЕНА
          </span>
        </div>

        <div className='line_row'>
          <span className={'line_info'}>
            На почту придёт письмо
            с восстановлением пароля
          </span>

          <Button
            error={error}
            cb={sendForget}
            name={'Отправить'}
          />
        </div>
      </div>

    </Modal>
  )
}
