import {useEffect, useState} from "react";
import './index.sass'
import {Input} from "../../../../components/Input/Input";
import {Space} from "../../../../components/Space";
import {Button} from "../../../../components/Button";
import {useHttp} from "../../../../hooks/http.hook";
import Admin from "../../../../store/Admin/Admin";
import {observer} from "mobx-react-lite";
import {AvatarCompany} from "./avatar";



export const Company = observer(() => {
  const [company, setCompany] = useState({})
  const {request} = useHttp()

  useEffect(() => {
    Admin.fetchOrganizationInfo(request)
  }, [])

  const companyGet = Admin.getOrganizationInfo()

  useEffect(()=>{
    if(companyGet.id) setCompany(companyGet)
  }, [companyGet])

  const inputSet = (e, key) => {
    let text = e.target.value
    let copy = {...company}
    copy[key] = text
    setCompany(copy)
  }

  const save = () => {
    Admin.fetchOrganizationUpdate(request, company)
  }

  return (
    <div id={'CompanyAdmin'} className={'userPage'}>

      <div className="title">О компании</div>

      <Space h={15}/>


      <div className={'gridAdmin'}>

        <div></div>
        <AvatarCompany/>

        <div>НАИМЕНОВАНИЕ КОМПАНИИ</div>
        <Input defValue={company.name} cb={(e)=>inputSet(e,`name`)}/>

        <div>СФЕРА ДЕЯТЕЛЬНОСТИ</div>
        <Input defValue={company.form_activity} cb={(e)=>inputSet(e,`form_activity`)}/>

        <div>АДРЕС</div>
        <Input  defValue={company.address} cb={(e)=>inputSet(e,`address`)}/>

        <div>НОМЕР ТЕЛЕФОНА</div>
        <Input defValue={company.phone} cb={(e)=>inputSet(e,`phone`)}/>

        <div>ЭЛЕКТРОННЫЙ АДРЕС</div>
        <Input defValue={company.email} cb={(e)=>inputSet(e,`email`)}/>

        <p></p>
        <div style={{display: `flex`}}>
          <Button name={'Сохранить'} cb={save}/>
        </div>
      </div>

    </div>
  )
})
