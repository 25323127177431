import {useEffect, useState} from "react";
import './index.sass'
import {Button} from "../../../../components/Button";
import {useHistory} from "react-router-dom";
import Admin from "../../../../store/Admin/Admin";
import {useHttp} from "../../../../hooks/http.hook";
import {observer} from "mobx-react-lite";
import {Table} from "antd";
import {Space} from "../../../../components/Space";
import {PaginationForTable} from "../../../../components/Pagination";
import _ from 'lodash'
import {MilkLine} from "../../../../components/MilkLine";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {toJS} from "mobx";
import {CustomTable} from "../../../../components/CustomTable";
import DelConfirm from "../../../../components/DelConfirm";

const columns = [
  {
    title: 'НАИМЕНОВАНИЕ ДОЛЖНОСТИ',
    dataIndex: 'name',
    key: 'name',
  },

  {
    title: 'ДЕПАРТАМЕНТ',
    dataIndex: 'department',
    key: 'department',
    className: 'centerColumn'
  },
  {
    title: 'КОМПЕТЕНЦИИ',
    dataIndex: 'comp',
    key: 'comp',
    className: 'centerColumn'
  },
  {
    title: 'КУРСЫ',
    dataIndex: 'course',
    key: 'course',
    className: 'centerColumn'
  },
  {
    title: 'СОТРУДНИКОВ',
    dataIndex: 'workers',
    key: 'workers',
    className: 'centerColumn'
  },

  {
    title: '',
    dataIndex: 'fn',
    key: 'fn',
  },
];


export const Positions = observer(() => {
  const history = useHistory()
  const {request} = useHttp()

  useEffect(()=>{
    Admin.fetchPositions(request)
    Admin.fetchCat(request, `Компетенция`)
    Admin.fetchCat(request, `Курс`)
  }, [])

  const goTo = () => {
    history.push("/admin/positions/new")
  }

  const goToEdit = (item) => {
    let copy = _.cloneDeep(item)
    history.push(`/admin/positions/${copy.id}`, {edit: true, ...copy })
  }


  const getPositions = Admin.getPositions()

  const delElement = (id) => {
    Admin.deletePosition(request, id)
  }

  let dataSource = () => {
    return getPositions.map(x => {
      let workersCount = 0
      if(x.workers?.length){
        x.workers.forEach(x => {
          if(x.name !== 'Вакант') workersCount =workersCount + 1
        })
      }

      let canDel = !(x.courses.length || x.competences.length || (x.workers && x.workers.length))

      return {
        key: x.id,
        name: x.name,
        department: x.department.name,
        comp: x.competences.length,
        course: x.courses.length,
        workers: workersCount,
        fn: <div className={'fnsBtnsTable'}>
          <EditOutlined title={'Редактировать запись'}  onClick={()=>goToEdit(x)}/>
          {canDel && <DelConfirm title={'Удалить запись'} saveFn={()=>delElement(x.id)}/>}

        </div>
      }
    })
  }

  const getRowClassName = (el) => {
    return ''
  }

  return (
    <div id={'userPage'} className={'userPage'}>
      <div className="title">Должности</div>
      <MilkLine/>

      <Space h={15}/>

      <div style={{display: `flex`, flexDirection: `row-reverse`, marginBottom: '10px'}}>
        <Button name={'Добавить должность'} cb={goTo}/>
      </div>

      <CustomTable
        rowClassName={getRowClassName}
        columns={columns}
        dataSource={dataSource()}
        tableHeight={window.innerHeight - 246 - 15 - 22}
      />

    </div>
  )
})
