import React from 'react';
// @ts-ignore
import {BrowserRouter as Router} from "react-router-dom";
import {useRoutes} from "./routes";
import {useAuth} from "./hooks/auth.hook"
import {AuthContext} from "./context/AuthContext";
import './index.sass'

function App() {
    const {token, login, logout, userId , modalOpen, modalClose} = useAuth()
    const isAuth = !!token && !!userId
    const routes = useRoutes(isAuth)

    return (
        <AuthContext.Provider value={{
            token, login, logout, isAuth , userId, modalOpen, modalClose
        }}>
            <Router>
                <div className={'container'}>
                    {routes}
                </div>
            </Router>
        </AuthContext.Provider>
    );
}

export default App;
