import {useState, useCallback, useEffect, useRef} from "react";

const localStorageName = 'userData'

const findInStorage = (field) => {
  const data = JSON.parse(localStorage.getItem(localStorageName))
  if(data && data[field]) return data[field]
}

export const useAuth = () => {
  const [token, setToken] = useState(findInStorage('token'))
  const [userId, setUserId] = useState(0)
  const [modalOpen, setModalOpen] = useState(false)

  const login = useCallback((jwtToken,  user_id)=>{

    setToken(jwtToken)
    setUserId(user_id)

    localStorage.setItem(localStorageName, JSON.stringify({
      token:jwtToken,
      user_id: user_id
    }))

  }, [])


  const modalClose = () => {
    setModalOpen(false)
  }

  const logout = useCallback(()=>{
    setUserId(null)
    setToken(null)

    localStorage.removeItem(localStorageName)
    window.location.href = "/login";
  }, [])

  useEffect(()=>{
    const data = JSON.parse(localStorage.getItem(localStorageName))
    if(data && data.token && data.user_id){
      login(data.token, data.user_id)
    }
  }, [login])

  return {login, logout ,userId , token , modalClose, modalOpen}
}