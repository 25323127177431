import {useEffect, useState} from "react";
import {Input} from "../../../../../components/Input/Input";
import {Space} from "../../../../../components/Space";
import   {
  DeleteOutlined,
  PlusCircleTwoTone
} from '@ant-design/icons';
import {toJS} from "mobx";

const base = {
  begin_date: 0,
  place_learning: "",
  available_places: 0,
  course_id: 0
}

export const LearnTimes = ({save, course_dates}) => {
  const [learnTimes, setLearnTimes] = useState([{...base, idd: Math.random()}])

  useEffect(()=>{
    if(course_dates.length){
      setLearnTimes(course_dates)
    }
  }, [course_dates])

  const addOneRow = () => {
    setLearnTimes([...learnTimes, {...base, idd: Math.random()}])
  }

  const deleteOneRow = (iter) => {
    let copy = [...learnTimes]
    copy.splice(iter, 1)
    setLearnTimes(copy)
  }

  const onChange = (row, key, value) => {
    let copy = [...learnTimes]
    let item = copy.find( x => x.idd === row.idd)
    item[key] = value.target.value
    setLearnTimes(copy)

    save(copy)
  }

  return (
    <>
      <Space h={30}/>

      <div className={'gridAdmin'} >
        <div style={{alignSelf: `start`}} className={'titl1e'}>ГРАФИК ОБУЧЕНИЯ</div>

        <div className={'flex graf'}>
          {learnTimes.map((x,i) => {

            let last = learnTimes.length === i+1
            let one = learnTimes.length === 1
            return (
              <div className={`itemDate`} key={x.idd}>

                <div className={`titleDate`}>
                  ОБУЧЕНИЕ № {i+1}
                  {!one && <span className={'m10'}><DeleteOutlined onClick={()=>deleteOneRow(i)}/></span>}
                  <span className={'m10'}>{last && <PlusCircleTwoTone onClick={addOneRow}/> }</span>
                </div>

                <Space h={15}/>
                <div className="itemOne">
                  <div className={'itemTit'}>ДАТА НАЧАЛА ОБУЧЕНИЯ</div>
                  <input className={`inpDate`}
                         value={x.begin_date}
                         onChange={(val)=>onChange(x,'begin_date', val )}
                         type="date"
                  />
                </div>

                <div className="itemOne">
                  <div className={'itemTit'}>МЕСТО ОБУЧЕНИЯ</div>
                  <Input defValue={x.place_learning} cb={(val)=>onChange(x,'place_learning', val )}  />
                </div>

                <div className="itemOne">
                  <div className={'itemTit'}>КОЛИЧЕСТВО МЕСТ</div>
                  <Input defValue={x.available_places} cb={(val)=>onChange(x,'available_places', val )} />
                </div>

                <Space h={15}/>
              </div>
            )})}
        </div>

      </div>

    </>
  )
}
