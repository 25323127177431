import './index.sass'
import {Space} from "../../../components/Space";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {useEffect, useState} from "react";
import {useHttp} from "../../../hooks/http.hook";
import DetailUser from "../../../store/DetailUser";
import {AvatarProfile} from "../../Profile/components/avatar/AvatarProfile";
import {Tabs} from "antd";
import RequirementsTable from "./RequirementsTable";
import Competencies from "./Competencies";
import CompetenciesEmployees from "../../../store/CompetenciesEmployees";
import {CloseBtn} from "../../../components/CloseBtn";


export const DetailPage = () => {
  let [tableHeight, setTableHeight] = useState(500)

  const {id} = useParams()
  const [user, setUser] = useState(DetailUser.getUserById(id))
  const history = useHistory()
  const {request} = useHttp()

  useEffect(() => {
    CompetenciesEmployees.getCompByPositionId(id , request)
  }, [])

  useEffect(()=>{
    setTableHeight(window.innerHeight - 354 - 40)
  }, [])

  const hideFn = () => {
    history.push('/employees')
  }

  if(!user.name) return null

  const {avatar_url, name, family, surname, position } = user

  return (
    <div id={'EmployeesDetailPage'}>
      <div className={'title'}>Сотрудники</div>
      <Space h={15}/>

      <div className={`itemLine`}>
        <div className={`positionItemInner`}>

          <div className="avatarBlock">
            <AvatarProfile
              avatar_url={avatar_url ? id+"_"+avatar_url : null}
              size={70}
              edit={false}
              companyAvatar={!avatar_url}
            />
          </div>

          <div className="infoByPos">
            <span className={'posValue'}>{family} {name} {surname}</span>
            <span className={'posName'}> {position.name} </span>
          </div>

        </div>
        <CloseBtn top={10} right={0} size={20} fn={hideFn}/>

      </div>

      <Tabs
        defaultActiveKey="1"
        type="card"
        items={[
          {
            label: `Требования`,
            key: 1,
            children: <RequirementsTable tableHeight={tableHeight} userId={id}/>,
          },
          {
            label: `Компетенции`,
            key: 2,
            children: <Competencies tableHeight={tableHeight} userId={id}/> ,
          },
        ]}
      />

    </div>
  )
}
