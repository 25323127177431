import {useEffect, useState} from "react";
import {Input} from "../../../../components/Input/Input";
import {Space} from "../../../../components/Space";
import {DeleteOutlined, PlusCircleTwoTone} from "@ant-design/icons";

export const SaveModeAnyLines = ({defValue, save, clear, rowKey, type=1}) => {
  let [rows, setRows] = useState([{key:"first", title:"", value:""}])
  let [edit, setEdit] = useState(false)
  let [keyVal, setKeyVal] = useState([`КЕМ ПРИХОДИТСЯ` , `ФИО`])

  useEffect(()=>{
    if(type === 2){
      setKeyVal([`ФИО` , `ТЕЛЕФОН`])
    }
  }, [])

  useEffect(()=>{
    if(edit) return
    if(!defValue) return
    setEdit(true)

    let newRows = []
    let lines = defValue.split(',')

    lines.forEach(x=> {
      let r = {key: Math.random(), title:"", value:""}
      let keyVal = x.split(':')

      r.title = keyVal[0]
      r.value = keyVal[1]
      newRows.push(r)
    })

    setRows(newRows)
  },[defValue])

  useEffect(()=>{
    let r = getSaveData(rows)
    if(!r) return
    let e = {target:{value:r}}
    save(e)
  },[rows] )

  const getSaveData = (rows) => {
    let txt = ""
    let count = rows.length
    rows.forEach((x,i) => {
      let last = i+1 === count
      if(x.title && x.value){
        txt += `${x.title}:${x.value}${last ? "" :","}`
      }
    })
    return txt
  }


  const addRow = () => {
    let copy = [...rows]
    copy.push({key: Math.random(), title:"",  value:""})
    setRows(copy)
  }

  const delRow = (key) => {
    if(rows.length === 1) return
    let copy = rows.filter(x=> x.key !== key)
    setRows(copy)
  }

  const saveRow = (e, key, type ) => {
    let f = rows.filter(x => {
      if(x.key === key){
        x[type] = e.target.value
      }
      return x
    })
    setRows(f)
  }

  return (
    <div>
      {rows.map((row, i) => {
        let canDel = i>0
        let last = rows.length === i+1

        return (
            <div key={row.key} >
              <div className={`titleDate`}>
                ЧЛЕН СЕМЬИ № {i+1}
                {canDel && <span className={'m10'}><DeleteOutlined onClick={()=>delRow(row.key)}/></span>}
                <span className={'m10'}>{last && <PlusCircleTwoTone onClick={addRow}/> }</span>
              </div>

              <Space h={15}/>

              <div className="itemOne">
                <div className={'itemTit'}>{keyVal[0]}</div>
                <Input
                  defValue={row.title}
                  clear={clear}
                  cb={(e)=>saveRow(e, row.key, `title`)}
                />
              </div>
              <div className="itemOne">
                <div className={'itemTit'}>{keyVal[1]}</div>
                <Input
                  defValue={row.value}
                  clear={clear}
                  cb={(e)=>saveRow(e, row.key, `value`)}
                />
              </div>
            </div>
        )})}
    </div>
  )
}
