import {useEffect, useState} from "react";
import './imdex.sass'
import {UserOutlined} from "@ant-design/icons";
import {Avatar} from "antd";
import {ModalAvatarChange} from "../ModalAvatarChange";
import {observer} from "mobx-react-lite";
import Admin from "../../../../../../store/Admin/Admin";
import {toJS} from "mobx";

export const AvatarProfile = observer(({size}) => {
  let [choosePhotoModal, setChoosePhotoModal] = useState(false)
  let [logo_url, setLogoUrl] = useState(null)

  const companyGet = Admin.getOrganizationInfo()

  useEffect(()=>{
    if(companyGet.logo_url){
      let url = companyGet.logo_url.replace('/var/www/edp_front/files/avatars/', '')
      setLogoUrl(url)
    }
  }, [companyGet])


  let src = logo_url
    ? `http://edp.proteger.pro/files/avatars/${companyGet.id}_${logo_url}`
    : require('../../../../../../img/company.png')

  return (
    <div className='avatar'>

      <div className="uploadLayer"
           onClick={()=>setChoosePhotoModal(true)}
           style={{width: size, height: size}}>
        {size>200 && <span>{logo_url ? "Изменить фотографию": "Установить фотографию"}</span>}
      </div>

      {/*<Avatar onClick={()=>setChoosePhotoModal(true)} size={size} icon={<UserOutlined/>}/>*/}

      <img width={size} height={size}
           src={src}
           alt="" className={'userAvatar'}/>


      {choosePhotoModal && <ModalAvatarChange id={companyGet.id} close={()=>setChoosePhotoModal(false)}/>}
    </div>
  )
})
