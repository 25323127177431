import {useEffect, useState} from "react";
import './index.sass'

export const InputMail = ({id, cb , name, inputMode, type, error}) => {
  let [text, setText] = useState('')
  let [errorType, setErrorType] = useState(false)
  let [errorText, setErrorText] = useState(false)


  const change = (e) => {
    setErrorType(false)
    setErrorText(false)
    setText(e.target.value)

    cb(e)
  }

  const checkEmailType = () => {
    const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
    return EMAIL_REGEXP.test(text);
  }

  useEffect(() => {
    if(!error) return;
    if(!text) {
      setErrorText(true)
      return;
    }
    if(!checkEmailType()) setErrorType(true)

  }, [error , text ] )

  return (
    <div className={'wrap'}>
      {errorType && <div className={`wrong_mail`}/>}
      {errorText && <div className={`empty_info`}/>}

      <input
        id={id || null}
        className={`inputText ${(errorText || errorType) ? 'input_error' : ''}`}
        onChange={change}
        name={name}
        value={text}
        inputMode={inputMode || 'text'}
        type={type || "text"}/>
    </div>

  )
}