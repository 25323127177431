import {makeAutoObservable, toJS} from "mobx";
import moment from "moment";
import _ from 'lodash'

class EstimatedStore {
  data = []

  constructor() {
    makeAutoObservable(this)
  }

  getData(){
    return this.data
  }

  fetchData(request){
    let sort = {[`y${moment().year()}`]: {
        year: moment().year(),
        items: []
      }}
    let r = []
    request(`/api/payslips/getPayslips/0/999`)
      .then(data => {
        let sored1 = _.sortBy(data, 'month')
        let sored2 = _.sortBy(sored1, 'year')


        sored2.forEach(x => {
          if(!sort[`y${x.year}`]){
            sort[`y${x.year}`] = {
              year: x.year,
              items: [{filename: x.filename , month:x.month}]
            }
          }else{
            sort[`y${x.year}`].items.push({filename: x.filename , month:x.month})
            }
          }
        )

        let keys = Object.keys(sort);
        keys.forEach(x => {
          let m = 0
          let c = 1
          sort[x].items.forEach(x => {
            if(x.month !== m){
              m = x.month
              c = 1
            }
            x.title = `Выплата ${c}`
            c = c+1
          })

          r.push(sort[x])
        })

        this.data = r
      })
  }
}


export default new EstimatedStore()
