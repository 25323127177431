import {Table} from "antd";
import CoursesStore from "../../store/CoursesStore";
import {observer} from "mobx-react-lite";
import {toJS} from "mobx";
import moment from "moment";
import {ModalInfo} from "../Competencies/components/ModalInfo";
import {useEffect, useState} from "react";
import _ from 'lodash'
import {PaginationForTable} from "../../components/Pagination";
import {columns} from "./allTableColumn";
import {CustomTable} from "../../components/CustomTable";


export const CoursesTable = observer(({tableHeight= 500 , searchText}) => {
  let [modalShow, setModalShow] = useState({type:null, data:null})
  let [tableCount, setTableCount] = useState(0)
  let [childListOpen, setChildListOpen] = useState([])

  let coursesData = CoursesStore.get()

  useEffect(()=>{
    let count = 0
    coursesData.forEach(x => count+= x.courses.length)
    setTableCount(count)
  }, [coursesData])


  const modelShowFn = (item) => {
    setModalShow({type:`info`, data: item})
  }

  const filterByName = (data, text) => {

    if(!text) return data
    let textSearch = text.toLowerCase()
    let final = []
    data.forEach(x => {
      let c = x.courses.filter(xx => {
        if(x.title.toLowerCase().includes(textSearch)) return xx

        if(xx.course_name.toLowerCase().includes(textSearch)) return xx
        if(xx.provider && xx.provider.toLowerCase().includes(textSearch)) return xx

        let findByDetails = false
        xx.course_dates.forEach(cd => {
          if(cd.begin_date.toLowerCase().includes(textSearch)) findByDetails = true
          if(cd.place_learning.toLowerCase().includes(textSearch)) findByDetails = true
        })
        if(findByDetails) return xx
      })

      if(c.length) final.push({...x,courses:c })
    })
    return final
  }

  const addChildList = (id) => {
    let list = [...childListOpen]

    let f = list.findIndex(x => x === id)

    if(f>-1) list.splice(f,1)
    if(f === -1) list.push(id)

    setChildListOpen(list)
  }

  const getCoursesTable  = () => {
    let filterData = filterByName(coursesData,searchText)

    let mustHave = [{
      key: 'mustHave',
      name: <div className={'groupBlock'}>Обязательны к обучению</div>,
    }]

    let over = [{
      key: 'noMust',
      name: <div className={'groupBlock'}>Не обязательные</div>,
    }]

    const pushEl = (item, arr) => {
      let course_dates = _.sortBy(item.course_dates, 'begin_date');

      let element = {
        key: `c_${item.id}`,
        name:
          <div className={'flexCenterVert firstChar'}>
            <span style={{marginLeft: '20px'}}>{item.course_name}</span>
            <i className={`infoBtn`} onClick={()=>modelShowFn( item)}/>
          </div> ,
        place: course_dates.map(x=> <p className={'noWrapLine'}> {x.place_learning} </p>),
        type_learning: item.type_learning,
        cost: item.cost,
        course_dates: course_dates.map(x=> <span> {x.begin_date } <br/> </span> ) ,
        available_places: course_dates.map(x=> <div> {x.available_places} </div>),
        provider: item.provider,
      }

      arr.push(element)
    }

    filterData.forEach((gr, i) => {
      let requiredFlag = false
      let noRequiredFlag = false

      gr.courses.forEach(x => {
        let keyReq = `req_${gr.title}`
        let keyNReq = `nreq_${gr.title}`

        if(x.required && !requiredFlag){
          requiredFlag = true
          mustHave.push({
            key: keyReq,
            name: <div className={'flex'}> {gr.title}
              <p className={`arrowTable ${!childListOpen.includes(keyReq) ? "arrowTableUp" : ""}`}
                 onClick={()=>addChildList(keyReq)}/>
            </div>
          })
        }
        if(x.required && !childListOpen.includes(keyReq)) pushEl(x, mustHave)

        if(!x.required && !noRequiredFlag){
          noRequiredFlag = true
          over.push({
            key: keyNReq,
            name: <div className={'flex'}> {gr.title}
              <p className={`arrowTable ${!childListOpen.includes(keyNReq) ? "arrowTableUp" : ""}`}
                 onClick={()=>addChildList(keyNReq)}/>
            </div>
          })
        }
        if(!x.required && !childListOpen.includes(keyNReq)) pushEl(x, over)
      })
    })

    let r = []
    if(mustHave.length>1) r = [...mustHave]
    if(over.length>1) r = [...r , ...over]
    return r
  }


  const getRowClassName = (el) => {
    if(el.key === 'mustHave' || el.key === 'noMust') return 'title_must_row'
    if(!el.type_learning) return 'title_row'
  }

  return  <>
    {modalShow?.type==='info' && <ModalInfo closeFn={()=>setModalShow(false)} data={modalShow.data} />}

    <CustomTable
      rowClassName={getRowClassName}
      columns={columns}
      dataSource={getCoursesTable()}
      tableHeight={window.innerHeight - 246 - 15 - 44}
    />

  </>
})
