import {Modal} from "../../../../components/Modal";
import "./index.sass"
import {ButtonsForModal} from "../../../../components/ButtonsForModal";

export const ModalInfoSave = ({ closeFn, saveFn}) => {

  const saveForm = () => {
    saveFn()
    closeFn()
  }

  return (
    <Modal
      closeFn={closeFn}
      title={'Отправить на согласование'}
    >
      <div className={'modalInfoSave'}>
        <div className={'modalInfoSave_text'}>Вы действительно хотите отправить компитенции на согласование?</div>

        <ButtonsForModal okText={'Отправить'} save={saveForm} cancel={closeFn}/>
      </div>

    </Modal>
  )
}
