import {useEffect, useState} from "react";
import './index.sass'
import "../../../Organization/index.sass"
import {MilkLine} from "../../../../components/MilkLine";
import {InputSearch} from "../../../../components/InputSearch/Input";
import {Space} from "../../../../components/Space";
import OrganizationStore from "../../../../store/OrganizationStore";
import {useHttp} from "../../../../hooks/http.hook";
import ShosStore from "../../../../store/Admin/ShosStore";
import {toJS} from "mobx";
import DetailUser from "../../../../store/DetailUser";
import {TreeItem} from "./TreeItem"
import {observer} from "mobx-react-lite";
import {BioBlock} from "../../../Organization/BioBlock";

let ArrowDownSVG = () => {
  return   <svg viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0h24v24H0z" fill="none">
    </path>
    <path d="m13 16.172 5.364-5.364 1.414 1.414L12 20l-7.778-7.778 1.414-1.414L11 16.172V4h2v12.172z"
          fill="#b0b0b0"
          className="arrowDownFill">
    </path>
  </svg>
}


export const Shos = observer(() => {
  let [clearText, setClearText] = useState(false)
  let [searchList, setSearchList] = useState([])
  let [searchShow, setSearchShow] = useState(false)
  let [treeCopy, setTreeCopy] = useState(null)
  let [parentIds, setParentsIds] = useState([])
  let [showBio, setShowBio] = useState({start: false, item: {}, leader: {}})


  let {request} = useHttp()

  let tree = OrganizationStore.getTree()

  useEffect(()=>{
    OrganizationStore.fetchData(request)
  }, [])

  useEffect(()=>{
    document.addEventListener('click', (e) => {
      let el = document.querySelector('.blockResult')
      if(!e.target.className ||( typeof e.target.className !== 'string') ) return;
      let self =  e.target.className.includes(`inputText`)
      if(el && !el.contains(e.target) && !self){
        setSearchShow(false)
      }
    })
  }, [])

  const movePositionByUser = () => {
    //Обновление должности у сотрудника. Поле vacantUserId
    // задается только при перемещении на вакантную должность,
    // при увольнении и резервировании поле не используется

    ShosStore.movePositionByUser(request)
  }

  const backToStart = () => {
    setTreeCopy(false)
    setSearchShow(false)
    setSearchList([])
    setClearText(true)
    setTimeout(()=>setClearText(false), 100)
  }

  const clickInput = () => {
    if (searchList.length) setSearchShow(true)
  }

  const blockShowUp = (selfId) => {
    let u = DetailUser.getUserById(selfId)

    let leaderId = u.leader.id

    if(leaderId === 1 ){
      blockHideShow(leaderId, true)
      setTreeCopy(false)
      return;
    }

    blockHideShow(leaderId, true)
    searchByTree(leaderId)
  }

  useEffect(() => {
    setSearchShow(searchList.length > 0)
  }, [searchList])


  const searchByTree = (id) => {
    setSearchShow(false)
    let el = null

    if(typeof id === 'string' && id.includes('p')){
      let idPosition = parseInt(id.replace('p',''))
      let result = []

      let findFn = (arr, id) => {
        arr.forEach(x => {
          if(x.position.id === id){
            result.push(x)
          }
          findFn(x.final, id)
        })
      }

      findFn(tree, idPosition)
      setTreeCopy(result)
      return;
    }

    let findFn = (arr, id) => {
      let f = arr.find(x => x.worker.id === id)

      if (f) {
        el = f;
        return;
      }
      arr.forEach(x => findFn(x.final, id))
    }

    findFn(tree, id)

    if(el) setTreeCopy([el])
  }

  const blockHideShow = (id, onlyOpen) => {
    let copy = [...parentIds]
    if (parentIds.includes(id) && !onlyOpen) {
      let f = parentIds.findIndex(x => x === id)
      copy.splice(f, 1)
      setParentsIds(copy)
    } else {
      copy.push(id)
      setParentsIds(copy)

      if(treeCopy && treeCopy.length >= 2) searchByTree(id)
    }
  }

  const inputSearch = (e) => {
    let r = []
    let treeFetch = OrganizationStore.getTreeFetch()
    let searchText = e.target.value.toLowerCase()

    if (!searchText) {
      setSearchList([])
      setTreeCopy(null)
      return;
    }

    if(searchText.length < 3){
      return;
    }

    treeFetch.forEach(x => {
      if (x.name.toLowerCase().includes(searchText)) {
        r.push({id: `p${x.id}`, name: x.name})
      }

      x.workers.forEach(xx => {
        let fullName = xx.family + " " + xx.name + " " + xx.surname

        if (xx.name !== 'Вакант' && fullName.toLowerCase().includes(searchText)) {
          r.push({id: xx.id, name: fullName})
        }else if("вакантная должность".includes(searchText)) {
          if(xx.name === 'Вакант') r.push({id: xx.id, vakant : true, name: "Вакантная должность"})
        }
      })
    })

    setSearchList(r)
  }

  const onEnter = () => {
    setSearchShow(false)

    let r = []
    if (!searchList.length) {
      return;
    }

    let findFn = (arr) => {
      arr.forEach(x => {
        if (searchList.find(xx => xx.id === `p${x.position.id}`) ||
          searchList.find(xx => xx.id === x.worker.id) ||
          searchList.find(xx => xx.vakant && x.worker.name === 'Вакант')
        ) {
          r.push(x)
        }

        findFn(x.final)
      })
    }

    findFn(tree)

    setParentsIds([])
    setTreeCopy(r)
  }

  const searchByTreeVakants = () => {
    setSearchShow(false)

    let r = []
    if (!searchList.length) {
      return;
    }

    let findFn = (arr) => {
      arr.forEach(x => {
        if (x.worker.name === 'Вакант') r.push(x)
        findFn(x.final)
      })
    }

    findFn(tree)

    setParentsIds([])
    setTreeCopy(r)
  }

  const SearchBlock = () => {
    return (
      <div className="search">
        <div className={'inputZone'}>
          <div className={`searchShos`}>
            <InputSearch placeholder={'Поиск сотрудника...'}
                         clear={clearText}
                         cb={inputSearch}
                         onClick={clickInput}
                         cleanAll={backToStart}
                         onEnter={onEnter}
            />
          </div>

          {searchShow && <div className="blockResult">
            {searchList.map((x,i) => {
              if(i) return
              if(searchList.findIndex(x => x.vakant) > -1){
                return <span onClick={searchByTreeVakants} key={'vakant'}>Вакантная должность</span>
              }
            })}

            {searchList.map((x,i) => {
              if(x.vakant) return
              return <span onClick={() => searchByTree(x.id)} key={x.id}>{x.name}</span>
            })}

          </div>
          }
        </div>
      </div>
    )
  }

  const getTree = (parent, upBtn) => {
    let result = []

    let domGeneration = (self, level, spaceType, leader) => {
      if(upBtn && !level && self.worker.id !== 1) {
        let el = <Space h={15}/>
        result.push(el)
      }

      let lines = spaceType.map(x => {
        let className = x === false ? 'noneLine' : x === 0 ? 'lineVertical' : `lineAnger`
        return <div className={className}/>
      })

      let el = (
        <div key={self.worker.id} className={`itemLine`}>
          {lines && lines.map(l => l)}

          {upBtn && !level && self.worker.id !== 1 &&
          <div
            style={{left: spaceType.length * 73}}
            onClick={() => blockShowUp(self.worker.id)}
            className={`sesPosition seeMore upBtn ${parentIds.includes(self.worker.id) ? `seeHide` : ""}`}
          >
            <ArrowDownSVG/>
          </div> }

          <TreeItem x={self} first={!level} showBioFn={showBioFn} leader={leader}/>

          {self.final.length > 0 && (
            <div
              style={{left: spaceType.length * 73}}
              onClick={() => blockHideShow(self.worker.id)}
              className={`sesPosition seeMore ${parentIds.includes(self.worker.id) ? `seeHide` : ""}`}
            >
              <ArrowDownSVG/>
            </div>
          )}
        </div>
      )

      result.push(el)

      parentIds.includes(self.worker.id) &&
      self.final.forEach((child, ii, arr) => {
        let last = arr.length - 1 === ii

        let copySpaces = spaceType.map(l => {
          if (l === 1) return false
          if (l === 0) return 0
          if (l === false) return false
        }) || []

        if (last) copySpaces.push(1)
        if (!last) copySpaces.push(0)

        domGeneration(child, level + 1, copySpaces, self)
      })
    }

    domGeneration(parent, 0, [], parent)

    return result
  }

  const selfClick = (e) => {
    //клик срабатывает когда в зоне элемента, остальные false
    if(e?.target?.className){
      let click = e.target.className
      if(click.trim() === 'btn_gold') return false
    }

    let el = e.target
    let end = false
    while (!end || el.tagName !== 'HTML'){
      if( el?.className?.trim().includes('positionItemInner')  )  end = true
      el = el.parentNode
    }
    return end
  }


  const showBioFn = (e, item, id, hide, leader) => {
    if (e) e.stopPropagation()



    if (hide) {
      setShowBio({start: false, item: null})
      return;
    }

    if(!selfClick(e)){
      return
    }

    setShowBio({start: true, item: item, leader: leader})
    if(id !== 'empty') OrganizationStore.fetchDataByUser(request, id, item)
  }

  return (
    <div id={'shosPageAdmin'} className={'shosPage'}>
      <div className="title">ОРГАНИЗАЦИОННАЯ СТУКТУРА</div>
      <MilkLine/>
      <Space h={30}/>

      {SearchBlock()}
      <Space h={20}/>

      {!treeCopy && tree.map((x) => getTree(x))}
      {treeCopy && treeCopy.map(x => getTree(x, true))}
      {showBio.start && <BioBlock
        hideFn={() => showBioFn(null, null, null, true)}
        leaderClick={()=>blockShowUp(showBio.item.worker.id)}
        item={showBio.item}/>}


    </div>
  )
})
