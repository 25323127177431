export const columns = [
  {
    title: 'Наименования обучения',
    dataIndex: 'name',
    key: 'name',
    className: 'widthStatic',
    width: 300
  },

  {
    title: 'Форма обучения',
    dataIndex: `type_learning`,
    key: 'type_learning',
    className: 'centerColumn',
    width: 100

  },
  {
    title: 'Место обучения',
    dataIndex: 'place',
    key: 'place',
    className: 'centerColumn minW',
    width: 200

  },
  {
    title: 'Даты проведения',
    dataIndex: 'course_dates',
    key: 'course_dates',
    className: 'centerColumn',
    width: 100
  },
  {
    title: 'Количество оставшихся мест',
    dataIndex: 'available_places',
    key: 'available_places',
    className: 'centerColumn',
    width: 150

  },
  {
    title: 'Цена курса',
    dataIndex: 'cost',
    key: 'cost',
    className: 'centerColumn',
    width: 100
  },
  {
    title: 'Учебное заведение',
    dataIndex: 'provider',
    key: 'provider',
    className: 'centerColumn',
    width: 100
  }
];