import {useEffect, useState} from "react";
import Notifications from "../../../../store/Notifications";
import {observer} from "mobx-react-lite";
import "./index.sass"
import User from "../../../../store/User";
import {toJS} from "mobx";
import {useHttp} from "../../../../hooks/http.hook";
import {useHistory} from "react-router-dom";

let svgDown =
  <svg className={'svgDownB'} viewBox="0 0 512 512" width={'20'} height={'20'} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M504 256c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zM212 140v116h-70.9c-10.7 0-16.1 13-8.5 20.5l114.9 114.3c4.7 4.7 12.2 4.7 16.9 0l114.9-114.3c7.6-7.6 2.2-20.5-8.5-20.5H300V140c0-6.6-5.4-12-12-12h-64c-6.6 0-12 5.4-12 12z"
      className="svgPath"
      fill='#cb8d00'
    >
    </path>
</svg>

let svgUp =
  <svg viewBox="0 0 512 512"
       xmlns="http://www.w3.org/2000/svg" width={'20'} height={'20'}>
    <path
      fill='#cb8d00'
      d="M8 256C8 119 119 8 256 8s248 111 248 248-111 248-248 248S8 393 8 256zm292 116V256h70.9c10.7 0 16.1-13 8.5-20.5L264.5 121.2c-4.7-4.7-12.2-4.7-16.9 0l-115 114.3c-7.6 7.6-2.2 20.5 8.5 20.5H212v116c0 6.6 5.4 12 12 12h64c6.6 0 12-5.4 12-12z"
      className="svgPath">
    </path>
  </svg>

export const NotificationPopUp = observer(({ close, closeItem}) => {
  const [showAllList, setShowAllList] = useState(false)
  let notifications = Notifications.getData()
  const history = useHistory()
  let {request} = useHttp();
  let user = User.getUser()

  const reedAll = () => {
    Notifications.reedAll(request)
    close()
  }

  const reedOne = (e, item) => {
    Notifications.reedOne(request, item.id)
    if(notifications.length === 1) close()
    e.stopPropagation()
  }

  const showAllBlock = () => {
    setShowAllList(prev => !prev)
  }

  const getBtn = () => {
    let countN = notifications.length - 3
    let text = showAllList ? "Свернуть до трёх" : `ЕЩЕ ${countN}`

    if(countN<1){
      return <span
        className={'actionSpan text_gold'}
      >  </span>
    }

    return <div className={`flex simpleSvg ${showAllList ? "up": "down"}`}>
      {showAllList ? svgUp: svgDown}
      <span
        onClick={showAllBlock}
        className={`actionSpan text_gold leftM`}
      > {text} </span>
    </div>
  }

  const getText = (item) => {
     return <span
       className={'message_def'}
       dangerouslySetInnerHTML={{__html: item.message}}
     />

    //
    // if(item.type === 'comp'){
    //   if(item.message.length === 2){
    //     return <span className={'message_def'}>Уважаемый,
    //       <span className={'message_bold'}> {user.name} {user.surname} </span>
    //       просим Вас рассмотреть компетенции у
    //       <span className={'message_bold'}> Фамилия И.О.</span>
    //     </span>
    //   }
    //
    //   if(item.message.length === 1){
    //     return <span className={'message_def'}>Уважаемый,
    //       <span className={'message_bold'}> {user.name} {user.surname} </span>
    //       статус ваших компетенций был изменён!</span>
    //   }
    // }
    //
    // if(item.type === 'course'){
    //   if(item.message.length === 1){
    //     return <span className={'message_def'}>Уважаемый,
    //       <span className={'message_bold'}> {user.name} {user.surname} </span>
    //      обязательное обучение
    //       <span className={'message_bold'}> {item.message[0]} </span>
    //        истекает через 30 дней. Вам необходимо пройти повторное обучение!
    //     </span>
    //   }
    // }
    //
    // if(item.type === 4){
    //   return <span className={'message_def'}>Уважаемый,
    //       <span className={'message_bold'}> {user.name} {user.surname} </span>
    //      у вашего сотрудника
    //       <span className={'message_bold'}> Фамилия И.О. </span>
    //        истекает через 30 дней обязательное обучение
    //     <span className={'message_bold'}> название курса</span>!
    //         Пожалуйста обеспечьте своевременное прохождение обучение для
    //     <span className={'message_bold'}> Фамилия И.О. </span>
    //     </span>
    // }
    // if(item.type === 5){
    //   return <span className={'message_def'}>Уважаемый,
    //       <span className={'message_bold'}> {user.name} {user.surname} </span>
    //      у сотрудника
    //       <span className={'message_bold'}> Фамилия И.О. </span>
    //        истекает через 30 дней обязательное обучение
    //     <span className={'message_bold'}> название курса</span>!
    //         Пожалуйста обеспечьте своевременное прохождение обучение для
    //     <span className={'message_bold'}> Фамилия И.О. </span>
    //     </span>
    // }
    //
    // if(item.type === 6){
    //   return <span className={'message_def'}>Уважаемый,
    //       <span className={'message_bold'}> {user.name} {user.surname} </span>
    //      обязательное обучение
    //       <span className={'message_bold'}> название курса </span>
    //        было просрочено! Срочно необходимо пройти повторное обучение!
    //     </span>
    // }
    // if(item.type === 7){
    //   return <span className={'message_def'}>Уважаемый,
    //       <span className={'message_bold'}> {user.name} {user.surname} </span>
    //      у вашего сотрудника
    //       <span className={'message_bold'}>  Фамилия И.О. </span>
    //     истек срок обязательного обучения
    //       <span className={'message_bold'}>  название курса</span>!
    //     Пожалуйста срочно обеспечьте своевременное прохождение обучение для
    //       <span className={'message_bold'}>  Фамилия И.О.</span>!
    //     </span>
    // }
  }

  const goTo = (item) => {
    // if(item.category === 'Уведомление'){
    //   history.push(`/competencies`);
    // }

    if(!item.user_about_id && item.category === 'Требование'){
      history.push(`/courses`);
    }

    if(item.user_about_id && item.category === 'Требование'){
      history.push(`/employees`);
    }

    if(item.user_about_id && item.category === 'Компетенция'){
      history.push(`/employees`);
    }

    if(!item.user_about_id && item.category === 'Компетенция'){
      history.push(`/competencies`);
    }

    if(!item.user_about_id && item.category === 'Расчетный_лист'){
      history.push(`/estimated`);
    }

    close()
  }

  return (
    <div className="popUp2" id={'container_notification'} >
      <div >
        <div className={`notification_block`}>
          {notifications.map((item, count) => {
            if(!showAllList && (count>2)) return ;
            let message = getText(item)

            return (
              <div
                key={item.id}
                className="notification_block__item__wrap">

                <div
                  onClick={()=>goTo(item)}
                  className='notification_block__item'>
                  { message }

                  <p className={'block_date'}>{item.date}</p>

                  <div
                    className={'reedClose'}
                    onClick={(e)=>reedOne(e, item)}/>
                </div>

              </div>
            )
          })}
        </div>


        <div className="row2">
          {getBtn()}

          <span onClick={reedAll}
                className={'actionSpan text_gold'}>Все прочитаны</span>
        </div>
      </div>
    </div>
  )
})
