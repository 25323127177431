import {makeAutoObservable, toJS} from "mobx";
import User from "./User";
import _ from "lodash"
import {notification} from "antd";
import moment from "moment";
import {useHttp} from "../hooks/http.hook";


class Competencies {
  competencies = []
  updateIds = []
  history = []
  request = null


  constructor() {
    makeAutoObservable(this)
  }



  setComp(competencies){
    let comp = formatData(competencies)
    this.competencies = sortByNameCourse(comp)
  }

  getComp(){
    return this.competencies
  }

  getHistory(byId){
    return this.history[byId] || []
  }

  get getUpdateStatus(){
    return this.updateIds.length
  }

  getUpdateCount(){
    return this.updateIds.length
  }

  //ОТПРАВИТЬ НА СОГЛАСОВАНИЕ
  approve(request){
    if(this.updateIds.length === 0) return;

    let user = User.getUser()

    this.updateIds.forEach(id => {
      let local = null

      this.competencies.forEach(gr => {
        gr.comp.forEach(itemComp => {
          if(itemComp.id === id) {
            local = itemComp
          }
        })
      })

      let status = user.id===999 ? "Согласовано" : "На_рассмотрении"


      let reqData = {
        "competence_id": id,
        "owner": user.id,
        "evaluation": local.actual.evaluation_local,
        "status": status,
        "acc_text": local.actual.acc_text,
        "notice": local.actual.notice,
      }

      request(`/api/competencies/approve`,'post', reqData )
        .then(data => {

          local.actual.evaluation = reqData.evaluation
          local.actual.evaluation_local = null
          local.actual.status = status

          local.history.splice(0,0,data)
          this.history[id].splice(0,0,data)

          notification.success({
            message: `Компетенция успешно отправлена на согласование` ,
            className: 'text_notification',
            duration: 3
          });
        })
    })

    this.updateIds = []
  }

  //сопроводительный текст сохранение
  saveAccText(data, text){
    let user = User.getUser()

    let reqData = {
      "competence_id": data.id,
      "owner": user.id,
      "evaluation": data.actual.evaluation,
      "status": data.actual.status,
      "acc_text": text,
    }

    this.request(`/api/competencies/approve`,'post', reqData )
      .then((d) => {

        data.actual.acc_text = text

        data.history.splice(0,0,d)
        this.history[data.id].splice(0,0,d)

        notification.success({
          message: `Форма успешно сохранена` ,
          className: 'text_notification',
          duration: 3
        });
      })
  }


  updateLocalEvaluation(item, type){
    if(item.actual.evaluation === type){
      item.actual.evaluation_local = null

      let f = this.updateIds.findIndex(x => x === item.id)
      this.updateIds.splice(f,1)
    }else{
      item.actual.evaluation_local = type
      if(!this.updateIds.includes(item.id)) this.updateIds.push(item.id)
    }

  }


  fetchHistory(request){
    this.request = request
    let user = User.getUser()
    request(`/api/competencies/getCompetenceHistoryByOwnerId/${user.id}/0/1000`)
      .then(data => {
        this.history = this.sortComp(data)
      })
  }


  sortComp (data) {
    const dataObj = []

    data.forEach(x => {
      if(dataObj[x.id_competence]){
        dataObj[x.id_competence].push(x)
      }else{
        dataObj[x.id_competence] = [x]
      }
    })

    dataObj.forEach((x)=> {
      x = _.reverse(x)
    })

    this.competencies.forEach(gr => {
      gr.comp.forEach(c => {
        if(dataObj[c.id]) {
          c.history = dataObj[c.id]
          c.actual = c.history[0]
        }
      })
    })
    return dataObj
  }

}

export default new Competencies()

const sortByNameCourse = (data) => {
  data = _.sortBy(data, 'categoryName')

  data.forEach(x => {
    x.comp = _.sortBy(x.comp, [(o) => o.comp_name.toLowerCase() ])
  })

  return data

}


export const formatData = ( competencies ) => {
  let compIds = competencies
  let parentComp = []

  compIds.forEach(x => x.id = x.competence.id)
  compIds = _.sortBy(compIds ,'id')

  compIds.forEach( x => {
    let parent = x.competence.category
    parent.comp = []

    let comp = {
      id: x.competence.id,
      req_level: x.req_level,
      comp_name: x.competence.comp_name,
      description: x.competence.description,

      actual:{
        acc_text: "",
        evaluation: "Не выбрано",
        evaluation_local: null,
        notice: "",
        status: "Новое",
      },
      history: []
    }

    const index = parentComp.findIndex(item => item.categoryName === parent.categoryName)
    if(index > -1){
      parentComp[index].comp.push(comp)
    }else{
      parent.comp.push(comp)
      parentComp.push(parent)
    }
  })

  return parentComp
}