import {useEffect, useState} from "react";
import {BackBtn2} from "../BackBtn2";

export const MilkLine = () => {
  const [vis, setVis] = useState(false)

  useEffect(()=>{
    document.addEventListener('scroll', ()=>{
      if(window.scrollY > 5){
        setVis(true)
      }else{
        setVis(false)
      }
    })
  },[])


  if(!vis) return null

  return (
    <>
      <BackBtn2/>
      <div  className={'topMilkShoww'}/>

    </>
  )
}
