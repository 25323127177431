import {useRef, useState} from "react"
import {Modal} from "../../../../../../components/Modal";
import './index.sass'
import Cropper from "react-cropper";
import {ButtonsForModal} from "../../../../../../components/ButtonsForModal";
import "cropperjs/dist/cropper.css";
import {useHttp} from "../../../../../../hooks/http.hook";
import Admin from "../../../../../../store/Admin/Admin";

export const ModalAvatarChange = ({id, close}) => {
  const [image, setImage] = useState(null);
  const [cropper, setCropper] = useState()
  const { requestAxios, request } = useHttp()
  const [block, setBlock] = useState(false)

  const linkref = useRef()

  const save = () => {
    if (typeof cropper !== "undefined") {
      let f = cropper.getCroppedCanvas().toDataURL()

      function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

        while(n--){
          u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, {type:mime});
      }

      let image = dataURLtoFile(f,`${Math.random()}img.png`);

      let formData = new FormData();

      formData.append('file', image);
      formData.append('id', id);

      requestAxios('/api/organizations/uploadlogo', formData )
        .then(r => {
          Admin.fetchOrganizationInfo(request)
        })

      setBlock(true)

      setTimeout(()=>{
        close()
      }, 1000)
    }
  }


  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();

    reader.onload = () => {
      setImage(reader.result);
    };

    reader.readAsDataURL(files[0]);
  };

  const loadClick = () => {
    linkref.current.click()
  }

  return (
    <Modal title={'Смена фотографии'} closeFn={close}>
      <>
        <div className={'modalPhotoZone'}>
          <input style={{opacity: 0}}  accept="image/*" type="file" ref={linkref} onChange={onChange} />

          <div className={'photoZone'}>
            {image &&
            <Cropper
              style={{ height: 400, width: "100%" }}
              zoomTo={0.5}
              initialAspectRatio={1}
              preview=".img-preview"
              src={image}
              viewMode={2}
              minCropBoxHeight={200}
              dragMode={'none'}
              aspectRatio={1}
              minCropBoxWidth={200}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false}
              onInitialized={(instance) => {
                setCropper(instance);
              }}
              guides={true}
            />
            }

            {!image && <div className={'loadImg'} onClick={loadClick}>
              Загрузить фото
            </div>}
          </div>

        </div>


        <ButtonsForModal addClasses={block ? `block_color` : ''} save={save} cancel={close}/>
      </>

    </Modal>
  )
}

