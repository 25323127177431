import {makeAutoObservable} from "mobx";
import OrganizationStore from "../OrganizationStore";
import UsersList from "./UsersList";

class ShosStore {
  data = []

  constructor() {
    makeAutoObservable(this)
  }

  upDatePositions(request){
    OrganizationStore.fetchData(request)
    UsersList.fetchList(request)
  }


  // использую при +
  // Надо на готовую  должность с вакантом поставить человека
  async movePositionByUser(request, userId, vacantUserId){
    return request(`/api/users/movePositionByUser/Move/${userId}/${vacantUserId}` , 'put')
      .then(data => {
        this.upDatePositions(request)
      })
  }

  // использую при +
  // Надо удалить ваканда с корнями
  async cancelUserByPosition(request, userId){
    return request(`/api/users/movePositionByUser/Cancel/${userId}/0` , 'put')
      .then(data => {
        this.upDatePositions(request)
        return data
      })
  }

  // использую при +
  // Надо УВОЛИТЬ ЧЕЛОВЕКА с корнями
  async dismissUserByPosition(request, userId){
    return request(`/api/users/movePositionByUser/Dismiss/${userId}/0` , 'put')
      .then(data => {
        this.upDatePositions(request)
        return data
      })
  }

  // использую при  СНЯТЬ С ДОЛЖНОСТИ
  // ЧЕЛОВЕК получает должность в РЕЗЕРВ
  async reserveUserByPosition(request, userId){
    return request(`/api/users/movePositionByUser/Reserv/${userId}/0` , 'put')
      .then(data => {
        this.upDatePositions(request)
        return data
      })
  }


  async createVacant(request, positionId, leader_id){
    const userData = {
      "name": "Вакант",
      "surname": "Вакантович",
      "family": "Вакантов",
      "organization_id": 1,
      "department_id": 1,
      "position_id": positionId,
      // "status": "На_работе",
      "e_mail": "vakant@gmail.com",
      "leader_id": leader_id,
      "active": true,
      "username": "mist1eria",
      "roles": [],
      "password": '123',
      "status": "Вакант"
    }
    return request('/api/users/register',`post`, userData )
      .then( resp => {
        return resp
      })
      .catch(e => {
        return false
      })
  }


  async createPosition(request, positionId, leader_id, user_id){
    let vacant = await this.createVacant(request, positionId, leader_id)
    if(!vacant) return;

    if(user_id){
      await this.movePositionByUser(request, user_id, vacant.id)
    }else{
      this.upDatePositions(request)
    }
    return true
  }

}

export default new ShosStore()