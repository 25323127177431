import React, {useContext, useState} from "react";
import {InputPassword} from "../../../../components/InputPassword/Input";
import {ButtonsForModalAuth} from "../../../../components/ButtonsForModalAuth";
import {Modal} from "../../../../components/Modal";
import {AuthContext} from "../../../../context/AuthContext";
import {useHttp} from "../../../../hooks/http.hook";
import User from "../../../../store/User";

export const ModalReAuth = ({ close }) => {
  let [error, setError] = useState(false)
  let [pass, setPass] = useState("")
  const {logout, login} = useContext(AuthContext)
  const {request, } = useHttp()

  const goAuth = async () => {
    let username = User.getSelfUsername()

    let form = {
      username: username,
      password: pass
    }

    try{
      const data = await request('/api/auth/login', 'POST', {...form})
      login(data.token, data.user_id)
      close()
    }catch (error){
      logout()
    }
  }

  const setPwd = (e) => {
    setPass(e.target.value)
  }

  return (
    <Modal
      closeFn={()=>{}}
      closeHide={true}
      title={'Авторизация'}
    >
      <div id={'info_modal_id'} className={'modalBodyBlock'}>
        <div className={'grid'}>

          <label className='input_label' htmlFor={'pass'}>Пароль</label>

          <InputPassword
            error={error}
            cb={setPwd}
            name='password'
            id='pass'
            type='password'
          />
        </div>
        <ButtonsForModalAuth save={goAuth} cancel={logout}/>
      </div>
    </Modal>
  )
}
