import {useEffect, useState} from "react";
import {InputSearch} from "../../../../../components/InputSearch/Input";
import {useHttp} from "../../../../../hooks/http.hook";
import {toJS} from "mobx";
import UsersList from "../../../../../store/Admin/UsersList";

export const SearchByList = ({save,value, error}) => {
  let [searchShow, setSearchShow] = useState(false)
  let [searchList, setSearchList] = useState([])
  const [searchInput, setSearchInput] = useState('')
  const {request} = useHttp()
  //const [activeSelect, setActiveSelect] = useState(null)
  const [name, setName] = useState('')
  const [error2, setError2] = useState(false)

  let users = UsersList.getUsersNotNull()

  useEffect(()=>{
    UsersList.fetchList(request)
  }, [])

  useEffect(()=>{
    if(error && !name){
      setError2(true)
      setTimeout(()=>{setError2(false)}, 1)
    }
  }, [error])

  useEffect(()=>{
    if(users.length){
      users.forEach(x => {
        if(value === x.id){
          let fullName = x.family +" "+ x.name +" "+ x.surname
          setName(fullName)
        }
      })
    }
  }, [value, users])

  useEffect(()=>{
    if(!searchInput) {
      setSearchShow(false)
      setSearchList([])
      return;
    }

    setName(searchInput)

    let filteredList = users.filter(x => {
      if (x.name !== 'Вакант'){
        let fullName = (x.family +" "+ x.name +" "+ x.surname).toLowerCase().includes(searchInput.toLowerCase())
        let t_num = x.t_number && x.t_number.includes(searchInput)
        if(t_num || fullName) return x
      }
    })

    if(filteredList.length>0) {
      setSearchShow(true)
    }else{
      setSearchShow(false)
    }

    setSearchList(filteredList)
  }, [searchInput])


  const clickInput = () => {
    if(searchList.length>0) setSearchShow(true)
  }

  const backToStart = () => {
    setSearchShow(false)
    setSearchList([])
    save(null)
  }

  const searchClick = (x) => {
    let fullName = x.family +" "+ x.name +" "+ x.surname
    setName(fullName)
    save(x.id)
    setSearchShow(false)
  }


  return (
    <div className="search2" >
      <div className={'inputZone'} style={{width: '350px'}}>
        <div className={`searchShos`}>
          <InputSearch placeholder={'Поиск сотруника по ФИО'}
                       cb={(e)=>setSearchInput(e.target.value)}
                       onClick={clickInput}
                       cleanAll={backToStart}
                       defValue={name}
                       error2={error2}
          />
        </div>

        {searchShow && <div className="blockResult">
          {searchList.map(x => {
            let fullName = x.family +" "+ x.name +" "+ x.surname
            return  <span className={`line`} onClick={()=>searchClick(x)} key={x.id}>
                <span>{fullName}</span>
              </span>
          })}
        </div>
        }
      </div>
    </div>
  )
}
