import {useEffect, useState} from "react";
import {MilkLine} from "../../../../components/MilkLine";
import {Space} from "../../../../components/Space";
import {Button} from "../../../../components/Button";
import {Table} from "antd";
import {PaginationForTable} from "../../../../components/Pagination";
import UsersListStore from "../../../../store/Admin/UsersList";
import {observer} from "mobx-react-lite";
import "./index.sass"
import {useHistory} from "react-router-dom";
import {useHttp} from "../../../../hooks/http.hook";
import Admin from "../../../../store/Admin/Admin";
import _ from 'lodash'
import {InputSearch} from "../../../../components/InputSearch/Input";
import {DeleteOutlined, EditOutlined , UserOutlined, EyeOutlined} from "@ant-design/icons";
import {CustomTable} from "../../../../components/CustomTable";
import User from "../../../../store/User";
import {ModalConfirm} from "../Shos/ModalConfirm";
import DelConfirm from "../../../../components/DelConfirm"
// import eye from '../../../../img/eye2/eye.svg'

let Eye = () => (
  <svg height="512px" id="eyesvg3"
       version="1.1" viewBox="60 100 400 300"
       width="512px"
       xmlns="http://www.w3.org/2000/svg"
       className={'eyesvg3'}
  >
    <g>
      <path d="M447.1,256.2C401.8,204,339.2,144,256,144c-33.6,0-64.4,9.5-96.9,29.8C131.7,191,103.6,215.2,65,255l-1,1l6.7,6.9   C125.8,319.3,173.4,368,256,368c36.5,0,71.9-11.9,108.2-36.4c30.9-20.9,57.2-47.4,78.3-68.8l5.5-5.5L447.1,256.2z M256,336   c-44.1,0-80-35.9-80-80c0-44.1,35.9-80,80-80c44.1,0,80,35.9,80,80C336,300.1,300.1,336,256,336z"/>
      <path d="M250.4,226.8c0-6.9,2-13.4,5.5-18.8c-26.5,0-47.9,21.6-47.9,48.2c0,26.6,21.5,48.1,47.9,48.1s48-21.5,48-48.1v0   c-5.4,3.5-11.9,5.5-18.8,5.5C266,261.6,250.4,246,250.4,226.8z"/>
    </g>
  </svg>
)

const columns = [
  {
    title: 'Имя',
    dataIndex: 'name',
    key: 'name',
  },

  {
    title: 'Табельный №',
    dataIndex: 't_number',
    key: 't_number',
  },
  {
    title: 'Должность',
    dataIndex: 'position',
    key: 'position',
  },
  {
    title: '',
    dataIndex: 'fn',
    key: 'fn',
  }
];


export const UsersList = observer( () => {
  const [searchInput, setSearchInput] = useState('')
  const [countAll, setCountAll] = useState(0)
  const [reedMode, setReedMode] = useState(true)
  const history = useHistory()


  const {request} = useHttp()

  useEffect(()=>{
    UsersListStore.fetchList(request)
    Admin.fetchDepartments(request)
    Admin.fetchPositions(request)


    let user = User.getUser()
    let roles = user.roles

    if(roles.includes('ADMIN')) setReedMode(false)
  },[])

  let list = UsersListStore.getUsersNotNull2()

  useEffect(()=>{
    if(list && list.length)setCountAll(list.length)
  }, [list])



  const goTo = (item) => {
    if(item){
      let copy = _.cloneDeep(item)
      history.push('/admin/users/new', {user: copy})
    }else{
      history.push('/admin/users/new')
    }
  }

  const goToUser = (item) => {
    let copy = _.cloneDeep(item)
    history.push('/admin/users/reed', {user: copy})
  }

  const delElement = async (id) => {
    await UsersListStore.deleteUser(request, id)
    UsersListStore.fetchList(request)
  }

  const filterByOptions = (list) => {
    let sText = searchInput.toLowerCase()

    list.sort((a,b) => {
      if(a.family > b.family) return 1
      if(a.family < b.family) return -1
    })
    
    return list.filter(x => {
      if(x.t_number && x.t_number.includes(sText)) return x

      let fullName = x.family  +" "+x.name+" "+x.surname.toLowerCase()
      if (fullName.toLowerCase().includes(sText))return x
    })
  }

  let dataSource = () => {
    let f = filterByOptions(list)

    return f.map(x => {
      let canDel = x.id !== 1
      return {
        key: x.id,
        name: x.family + " " + x.name  +" "+ x.surname,
        position: x.position.name,
        t_number: x.t_number,
        fn: reedMode
          ?
            <div className={'fnsBtnsTable'}>
              <EyeOutlined title={'Открыть профиль'} onClick={()=>goToUser(x)} />
            </div>
          :
            <div className={'fnsBtnsTable'}>
              <EyeOutlined title={'Открыть профиль'}  onClick={()=>goToUser(x)}/>
              <EditOutlined title={'Редактировать запись'}  onClick={()=>goTo(x)}/>
              {canDel && <DelConfirm title={'Удалить запись'} saveFn={()=>delElement(x.id)}/>}
            </div>
      }
    })
  }

  const SearchBlock = () => {
    return (
      <div className="search">
        <div className={'inputZone'}>
          <div className={`searchShos`}>
            <InputSearch placeholder={'Поиск сотруника...'}
               cb={(e)=>setSearchInput(e.target.value)}
               cleanAll={()=>setSearchInput('')}
            />
          </div>
        </div>
      </div>
    )
  }


  return (
    <div id={'usersList'} className={'usersList'}>

      <div className="title">Список пользователей</div>
      <MilkLine/>

      <Space h={15}/>

      <div style={{display: `flex`, flexDirection: `row-reverse`,
        marginBottom: '10px', justifyContent: `space-between`} }>
        {reedMode && <div/>}
        {!reedMode && <Button name={'Создать пользователя'} cb={()=>goTo()}/>}
        {SearchBlock()}

      </div>

      <CustomTable
        columns={columns}
        dataSource={dataSource()}
        tableHeight={window.innerHeight - 206 + 15} />

    </div>
  )
})
