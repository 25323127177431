import {createContext} from "react";

function noop () {}

export const AuthContext = createContext({
  token: null,
  userId: 0,
  login: noop,
  logout: noop,
  isAuth: false,
  modalOpen: false,
  modalClose: noop
  // admin: false
})