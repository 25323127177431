import {useEffect, useState} from "react";
import './index.sass'
import {Select, Table} from "antd";
import {Button} from "../../components/Button";
import {useHttp} from "../../hooks/http.hook";
import EstimatedStore from "../../store/EstimatedStore";
import {toJS} from "mobx";
import moment from "moment";
import {Space} from "../../components/Space";
import _ from 'lodash'

const Option = Select.Option

const columns = [
  {
    title: 'Месяц',
    dataIndex: 'month',
    key: 'month',
  },
  {
    title: 'Выплата',
    dataIndex: 'type',
    key: 'type',
    className: 'centerColumn'
  },
  {
    title: '',
    dataIndex: `fn`,
    key: 'fn',
    className: 'centerColumn'
  }
]

export const Estimated = () => {
  const [monthActive, setMonthActive] = useState(-1)
  const {request} = useHttp()
  const data = EstimatedStore.getData()
  const [yearActive, setYearActive] = useState(moment().year())
  const months = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сенябрь", "Октябрь", "Ноябрь", "Декабрь",]


  useEffect(()=>{
    EstimatedStore.fetchData(request)
  }, [])

  const getCoursesTable  = () => {
    let findYear = data.find(x => x.year === yearActive)

    if(!findYear) return []

    let r = findYear.items.map((x,i) => {
      if(monthActive>-1 && x.month !== monthActive) return
      return {
        key: x.name,
        month: months[x.month-1],
        type: x.title,
        fn: <div className={'row2'}>
        <Button cb={()=>seeBtn(x)} name={'Просмотреть'} />
        <Button cb={()=>saveBtn(x)} name={'Скачать'} />
      </div>,
      }
    })

    r =  _.uniq(r)
    r =  _.compact(r)

    return (r.length === 0 ) ? null : r
  }

  const saveBtn = (item) => {
    let url = `http://edp.proteger.pro/files/payslips/${item.filename}`
    window.open(url);
  }

  const seeBtn = (item) => {
    let url = `http://edp.proteger.pro/files/payslips/${item.filename}`
    window.open(url);
  }


  return (
    <div id='MoneyPage'>
      <div className={'title'}>Расчётные листы</div>

      <Space h={20}/>

      <Select
        onChange={(year)=>setYearActive(year)}
        defaultValue={yearActive}
        className={'selectMoney'}
      >
        {data.map(x => <Option className={'selectOpt'} value={x.year}>{x.year}</Option>)}
      </Select>

      <Select
        onChange={(value) => setMonthActive(value) }
        defaultValue={monthActive}
        className={'selectMoney'}
      >
        <Option className={'selectOpt selectDef'} value={-1}>Не выбрано</Option>
        {months.map((x, i) => <Option className={'selectOpt'} value={i+1}>{x}</Option>)}
      </Select>

      <Space h={20}/>

      <Table  dataSource={getCoursesTable()}
              columns={columns}
              bordered={false}
              pagination={false}
              className={'EstimatedTable'}
      />

    </div>
  )
}
