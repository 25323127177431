import './index.sass'
import {Modal} from "../Modal";
import {ButtonsForModal} from "../ButtonsForModal";

export const PopUpClose = ({closeFn, save  }) => {

  return (
    <Modal
      closeFn={closeFn}
      title={'Сохранить изменения'}
    >
      <div id={'info_modal_id'}>
        <div className={'text_main_name_close'}>
          <p>{`У вас есть несохранённые данные. Хотите выйти без сохранения?` }</p>
        </div>
      </div>

      <ButtonsForModal okText={'Выйти'} save={save} cancel={closeFn}/>
    </Modal>
  )
}
