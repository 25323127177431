import React, {useContext, useEffect, useState} from "react";
import "./index.sass"
import {PoweroffOutlined, SettingOutlined} from "@ant-design/icons";
import {AuthContext} from "../../../../context/AuthContext";
import {withRouter} from "react-router-dom";
import IconAdm from './adminIcon'
import IconProfile from './profileicon'
import User from "../../../../store/User";
import {toJS} from "mobx";


export const ExitPopUp = withRouter((props) => {
  const [adminMode, setAdminMode] = useState(false)
  const [adminPage, setAdminPage] = useState(false)

  let {logout} = useContext(AuthContext)

  useEffect(()=>{
    let user = User.getUser()
    if(user.roles.includes('ADMIN') ||
      user.roles.includes('BOOKER') ||
      user.roles.includes('TEACHER')
    ){
      setAdminMode(true)
    }
  }, [])

  useEffect(()=>{
    if(window.location.href.includes('admin')){
      setAdminPage(true)
    }else{
      setAdminPage(false)
    }

  },[])


  const goTo = (link) => {
    props.history.push(`/${link}`);
    props.close()
  }

  const goToByRole = () => {
    props.history.push(`/admin/users`)
    props.close()
  }

  return (
    <div className={'popUpExit'}>
      <div className='popUp_item color_rev' onClick={()=>goTo(`settings`)}>
        <span className={'popUp_item_title'}>Настройки</span>
        <div className='popUp_icon'>
          <SettingOutlined/>
        </div>
      </div>

      {adminMode &&
        <>
          {!adminPage && <div className='popUp_item color_rev' onClick={goToByRole}>
            <span className={'popUp_item_title'}>Администратор</span>
            <div className='popUp_icon'>
              <IconAdm/>
            </div>
          </div>}

          {adminPage && <div className='popUp_item color_rev' onClick={()=>goTo(`profile`)}>
            <span className={'popUp_item_title'}>Пользователь</span>
            <div className='popUp_icon'>
              <IconProfile/>
            </div>
          </div>}
        </>
      }


      <div className='popUp_item color_rev' onClick={logout}>
        <span className={'popUp_item_title'} >Выход</span>
        <div className='popUp_icon exit'>
          <PoweroffOutlined/>
        </div>
      </div>
    </div>
  )
})
