import React, {useContext, useEffect, useRef, useState} from "react";
import {ExitPopUp} from "../ExitPopUp";
import {observer} from "mobx-react-lite";
import User from "../../../../store/User";
import {useHttp} from "../../../../hooks/http.hook";
import "./index.sass"
import {NotificationPopUp} from "../NotificationPopUp";
import Notifications from "../../../../store/Notifications";
import DetailUser from "../../../../store/DetailUser";
import {AuthContext} from "../../../../context/AuthContext";
import {ModalReAuth} from "../../../Login/components/ModalReAuth";

export const Header = observer(() => {
  const [popUp, setPopup] = useState(false)
  const [popUp2, setPopup2] = useState(false)
  let {modalOpen , modalClose} = useContext(AuthContext)

  const popUp2Ref = useRef()

  const {request} = useHttp()
  const el = useRef()
  let notificationsCount = Notifications.getNewCount

  useEffect(()=>{
      document.addEventListener('click', (e)=>{
        if(el.current && !el.current.contains(e.target)) setPopup(false)
        if(!popUp2Ref.current.contains(e.target)) setPopup2(false)
      })
  },[])

  useEffect(() => {
    User.fetchData(request)
    Notifications.fetchData(request)
    DetailUser.fetchDataAll(request)
  }, [])


  const popUp2Open = (e) => {
    if(!notificationsCount && !popUp2) return;
    let elCheck = document.querySelector('#container_notification')

    if(elCheck && elCheck.contains(e.target)) return;
    setPopup2(prev => !prev)
  }

  const popUpOpen = (e) => {
    if (e.target.className !== 'name_span') return;
    setPopup(prev => !prev)
  }


  let userInfo = User.getUser()
  if(!userInfo.name) return null


  return (
    <div className="header">

      <div className='back_btn' onClick={()=>{}}>
        {/*Назад*/}
      </div>

      <div className={`rightZone`}>
        <div ref={popUp2Ref} className={'flexRow ringWrap'} onClick={popUp2Open} >
          <div className={`ring ${notificationsCount && 'new'}`}>
            {popUp2 && <NotificationPopUp close={()=>setPopup2(false)}/>}
          </div>
          <span className={!notificationsCount && 'defCursor'}>{notificationsCount}</span>
        </div>

        <div ref={el} onClick={popUpOpen} className="name">
          <span className='name_span'> {userInfo.family} {userInfo.name[0]}. </span>
          {popUp && <ExitPopUp close={()=>setPopup(false)}/>}
        </div>
      </div>

      {modalOpen && <ModalReAuth close={modalClose}/> }

    </div>
  )
})


