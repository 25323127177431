import {Space} from "../../components/Space";
import {useEffect, useState} from "react";
import './index.sass'
import {AvatarProfile} from "../Profile/components/avatar/AvatarProfile";
import {toJS} from "mobx";
import { useHistory } from "react-router-dom";
import User from "../../store/User";
import DetailUser from "../../store/DetailUser";
import {useHttp} from "../../hooks/http.hook";
import {observer} from "mobx-react-lite";

export const Employees = observer(() => {
  const [data , setData] = useState([])
  const {request} = useHttp()
  const history = useHistory();

  //self user
  const user = User.getUser()


  useEffect(()=>{
    let d = []

    if(user.subjects.length>0){
      user.subjects.forEach(x => {
        if(x.name === "Вакант") return false

        DetailUser.fetchUserById(request, x.id)
        DetailUser.fetchUserStatistic(request, x.id)

        let item = {
          id : x.id,
          position: '',
          worker: {
            name: x.name,
            family: x.family,
            surname: x.surname,
            avatar_url: x.avatar_url,
            id:x.id
          },
          course: `ok`,
          comp: 'ok'
        }

        d.push(item)
      })
    }

    setData(d)

  }, [user])

  const toDetailPage = (item) => {
    history.push(`/employees/${item.id}`);
  }

  const treeItem = (x) => {
    let u = DetailUser.getUserById(x.id)
    let position = u?.position?.name
    let stat = DetailUser.getEmployeesStatistic(x.id)

    let requirementsStatus = stat.requirements || 'ok'
    let competenciesStatus = stat.competencies || 'ok'

    let requirementsStatusTitle = stat.requirements === 'ok' ? 'Всё хорошо' : 'Есть просроченые'
    let competenciesStatusTitle = stat.competencies === 'ok' ? 'Всё одобрено' : 'Требуется одобрение'


    return (
      <>
        <div className={`itemLine`} onClick={()=>toDetailPage(x)}>
          <div className={`positionItemInner`}>

            <div className="avatarBlock">
              {!x.worker && <AvatarProfile
                avatar_url={null}
                size={70}
                edit={false}
                companyAvatar={true}
              />}
              {x.worker && <AvatarProfile
                avatar_url={x.worker.avatar_url ? x.worker.id +"_"+x.worker.avatar_url : null}
                size={70}
                edit={false}
                companyAvatar={!x.worker.avatar_url}

              />}
            </div>

            <div className="infoByPos">
              <span className={'posValue'}>{x.worker.family} {x.worker.name} {x.worker.surname}</span>
              <span className={'posName'}> {position} </span>
            </div>

          </div>
        </div>
        <div className={`table_center table_status ${requirementsStatus}`} title={requirementsStatusTitle}/>
        <div className={`table_center table_status ${competenciesStatus}`} title={competenciesStatusTitle}/>
      </>
    )
  }

  return (
    <div id='EmployeesPage'>
      <div className={'title'}>Сотрудники</div>
      <Space h={15}/>

      <div className="gridTable">
        <div className={'freeLine'}/>
        <span className={'table_center gridTable_title'}>ОБЯЗАТЕЛЬНЫЕ КУРСЫ</span>
        <span className={'table_center gridTable_title'}>КОМПЕТЕНЦИИ</span>

        {data.map(x => treeItem(x))}
      </div>


    </div>
    )
})
