import React, {useState} from "react";
import {Input} from "../../../../components/Input/Input";
import {Modal} from "../../../../components/Modal";
import {Button} from "../../../../components/Button";
import {Space} from "../../../../components/Space";
import './index.sass'

export const ModalReset = ({closeFn}) => {
  let [pass, setPass] = useState('')
  let [pass2, setPass2] = useState('')


  const sendForget = () => {
    closeFn()
  }

  const changeHandler = (e) => {
    // setEmail(e.target.value)
  }

  return (

    <Modal
      closeFn={closeFn}
      title={'ВОССТАНОВЛЕНИЕ ПАРОЛЯ'}
    >

      <Space h={44}/>

      <div className={'grid'}>
        <label className='input_label' htmlFor={'forget_main'}>Пароль</label>

        <Input
          cb={changeHandler}
          name='username'
          id='username'
          inputMode='email'
        />

      </div>

      <Space h={16}/>

      <div className={'grid'}>
        <label className='input_label' htmlFor={'forget_main'}>Повторите пароль</label>

        <Input
          cb={changeHandler}
          name='username'
          id='username'
          inputMode='email'
        />

      </div>


      <Space h={58}/>

      <div className={'line'}>
        <div style={{width: '151px', alignSelf: 'center'}}>
          <span className='cancel_btn text_main_2' onClick={closeFn}>
            ОТМЕНА
          </span>
        </div>

        <div className='line_row'>

          <Button
            cb={sendForget}
            name={'ИЗМЕНИТЬ'}
          />
        </div>
      </div>

    </Modal>
  )
}
