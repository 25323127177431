import React from "react";
import {LeftMenu} from "../../components/LeftMenu"
import './index.sass'
import './patination.sass'
import {Header} from "./components/Header/Header";

export const Base = ({children, admin}) => {

  return (
    <div className='base_wrap'>

      <div className="menu_wrap">
        <LeftMenu admin={admin}/>
      </div>

      <div className="body">
        <Header/>

        <div className="main_block">
          {children}
        </div>
      </div>

    </div>
  )
}

