import React, {useEffect, useRef, useState} from "react";
import './index.sass'
import _ from "lodash";

export const InputPassword = ({id, cb ,clear, name, inputMode, type, error}) => {
  let [text, setText] = useState('')
  let [errorStyle, setErrorStyle] = useState(false)
  let [showPassword, setShowPassword] = useState(false)


  useEffect(()=>{
    if(error && !text) setErrorStyle(true)
  }, [error] )


  useEffect(()=>{
    if(clear) setText('')
  }, [clear] )


  const change = (e) => {
    setText(e.target.value)
    cb(e)
    setErrorStyle(false)
  }


  return (
    <div className={'wrap wrap_password'}>
      {errorStyle && <div className={`empty_info`}/>}
      <input
        id={id || null}
        className={`inputText  ${errorStyle ? 'input_error' : ''}`}
        onChange={change}
        name={name}
        autoComplete={false}
        value={text}
        inputMode={inputMode || 'text'}
        type={showPassword ? "text" : "password"}
      />

      <label
        htmlFor={id}
        onClick={()=>setShowPassword(prev => !prev)}
        className={`input_password_eye ${showPassword ? 'show_eye' : 'hide_eye'}`}
      />
    </div>

  )
}