import {useEffect, useState} from "react";
import './index.sass'
import {Input} from "../../../../components/Input/Input";
import {Space} from "../../../../components/Space";
import {Button} from "../../../../components/Button";
import {useHttp} from "../../../../hooks/http.hook";
import {observer} from "mobx-react-lite";
import Admin from "../../../../store/Admin/Admin";
import {toJS} from "mobx";
import {notification, Table} from "antd";
import {useHistory} from "react-router-dom";
import {PopUpAdd} from "../../components/PopUpAdd";
import {PaginationForTable} from "../../../../components/Pagination";
import {MilkLine} from "../../../../components/MilkLine";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {CustomTable} from "../../../../components/CustomTable";
import DelConfirm from "../../../../components/DelConfirm";

const columns = [
  {
    title: 'НАИМЕНОВАНИЕ ОТДЕЛА',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '',
    dataIndex: 'fn',
    key: 'fn',
  }
]

export const Departments = observer(() => {
  const [showModal, setShowModal] = useState(null)
  const [countAll, setCountAll] = useState(0)

  const {request} = useHttp()
  const history = useHistory()

  let dep = Admin.getDepartments()

  useEffect(()=>{
    Admin.fetchDepartments(request)
  }, [])

  useEffect(()=>{
    if(dep && dep.length)setCountAll(dep.length)
  }, [dep])

  const delElement = (id) => {
    Admin.deleteDepartment(request, id)
  }

  const editElement = (id) => {
    // Admin.deleteDepartment(request, id)
  }

  const save = (newName) => {
    if(!newName) return;

    Admin.addDepartment(request , newName)

    setShowModal(null)
  }

  const editFn = (newName) => {
    if(!newName) return;
    let data = {
      id: showModal.id,
      name: newName
    }
    Admin.editDepartment(request , data)
    setShowModal(null)
  }

  const editParent = (parent) => {
    setShowModal({
      new: false,
      edit: true,
      name: parent.name,
      id: parent.id
    })
  }


  let dataSource = () => {


    return dep.map(x => {
      return {
        key: x.id,
        name: x.name,
        fn: <div className={'fnsBtnsTable'}>
          <EditOutlined title={'Редактировать запись'}  onClick={()=>editParent(x)}/>
          <DelConfirm title={'Удалить запись'} saveFn={()=>delElement(x.id)}/>
        </div>,
      }
    })
  }


  return (
    <div id={'departmensAdmin'} className={'departmensAdmin'}>

      <div className="title">Отделы</div>
      <MilkLine/>

      <Space h={15}/>

      <div style={{display: `flex`, flexDirection: `row-reverse`, marginBottom: '10px'}}>
        <Button name={'Добавить отдел'} cb={()=>setShowModal({new: true})}/>
      </div>

      <CustomTable columns={columns}
                   dataSource={dataSource()}
                   tableHeight={window.innerHeight - 246 - 15} />

      {showModal?.new && <PopUpAdd
        title={'НАИМЕНОВАНИЕ ОТДЕЛА'}
        save={save}
        closeFn={()=>setShowModal(null)}
      />}

      {showModal?.edit && <PopUpAdd
        title={'НАИМЕНОВАНИЕ ОТДЕЛА'}
        save={editFn}
        okText={'Сохранить'}
        defValue={showModal.name}
        closeFn={()=>setShowModal(null)}
      />}
    </div>
  )
})
