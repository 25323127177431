import {useEffect, useState} from "react";
import './index.sass'
import {Input} from "../../../../components/Input/Input";
import {Button} from "../../../../components/Button";
import {useHttp} from "../../../../hooks/http.hook";
import {Select} from "antd";
import {Space} from "../../../../components/Space";
import Admin from "../../../../store/Admin/Admin";
import UserStore from "../../../../store/User";
import {observer} from "mobx-react-lite";
import {toJS} from "mobx";
import _ from 'lodash'
import {MilkLine} from "../../../../components/MilkLine";
import {useHistory} from "react-router-dom";
import UsersList from "../../../../store/Admin/UsersList";
import {PopUpClose} from "../../../../components/PopUpClose";
import {SaveModeAnyLines} from "./SaveModeAnyLines";
import {SaveModeEducation} from "./SaveModeEducation";

const Option = Select.Option

const baseData = {
  organization_id: null,
  department_id: null,
  position_id: 101,
  maritalStatus: 'Не_женат',
  gender: 'Мужской',
  leader_id: 0,
  roles: ["USER"],
  username: null,
  active: true,
  status: 'На_работе'
}

export const User = observer(() => {
  let [popUpCloseShow, setPopUpCloseShow] = useState(false)
  let [data, setData] = useState({})
  let [error, setError] = useState(false)
  let [clear, setClear] = useState(false)
  let [editMode, setEditMode] = useState(false)
  let [haveChange, setHaveChange] = useState(false)
  let [block, setBlock] = useState(false)


  const history = useHistory()
  const {request} = useHttp()

  const departments = Admin.getDepartments()
  const userStore = UserStore.getUser()
  const positions = Admin.getPositions()


  useEffect(() => {
    let user = history.location?.state?.user
    if (user) {
      user.position_id = user.position.id
      user.department_id = user.position.department.id
      setData(user)
      setEditMode(true)
    } else {
      baseDataSet()
    }
  }, [])

  const baseDataSet = () => {
    let {organization} = userStore?.position

    if (organization?.id) {
      baseData.organization_id = organization.id
    }
    if (departments[0]?.id) {
      baseData.department_id = departments[0].id
    }
    if (positions[0]?.id) {
      //baseData.position_id = positions[0]?.id
    }
    setData(baseData)
  }

  const saveInput = (e, key) => {
    setHaveChange(true)
    const text = e.target.value
    let copy = {...data}
    copy[key] = text
    setData(copy)
  }

  const saveSelect = (val, key) => {
    let copy = {...data}
    copy[key] = val
    setData(copy)
  }

  const saveSelectRoles = (val) => {
    let roles = _.uniq(val)
    let copy = {...data}
    copy['roles'] = roles
    setData(copy)
  }

  const save = async () => {
    if (checkHaveError()) return;
    if(block) return
    setBlock(true)
    if (editMode) {
      await UsersList.editUser(request, data)
    } else {
      await UsersList.createUser(request, data)
    }
    setBlock(false)
    goBackTrue()
  }


  const goBack = () => {
    if(haveChange){
      setPopUpCloseShow(true)
      return
    }
    goBackTrue()
  }

  const goBackTrue = () => {
    history.push(`/admin/users`)
  }


  const checkHaveError = () => {
    let {family, name, surname,  username, password} = data
    let find = true

    if (family && name && surname && username) {
      find = false
    }

    if (find) setError(Math.random())
    return find
  }

  const onChangeDate = (e, key) => {
    let date = e.target.value
    saveSelect(key, date)
  }


  return (
    <div id={'userPageAdmin'} className={'userPage'}>
      <div className="title">{editMode ? "Сохранить" : "Создать"} пользователя</div>
      <MilkLine/>
      <div className="close_btn_global" onClick={goBack}/>
      <Space h={30}/>
      {/*<div style={{display: `flex`, flexDirection: `row-reverse`, marginBottom: '10px'}}>*/}
      {/*  <Button name={editMode ? "Сохранить" : "Создать"} cb={save}/>*/}
      {/*</div>*/}


      <div className={'gridAdmin'}>

        <p> ФАМИЛИЯ </p>
        <Input defValue={data.family} error={error} clear={clear} cb={(e) => saveInput(e, `family`)}/>

        <p> ИМЯ </p>
        <Input defValue={data.name} error={error} clear={clear} cb={(e) => saveInput(e, `name`)}/>

        <p> ОТЧЕСТВО </p>
        <Input defValue={data.surname} error={error} clear={clear} cb={(e) => saveInput(e, `surname`)}/>

        <p>ТАБЕЛЬНЫЙ НОМЕР</p>
        <Input defValue={data.t_number} clear={clear} cb={(e) => saveInput(e, `t_number`)}/>

        <p>EMAIL РАБОЧИЙ</p>
        <Input defValue={data.username} error={error} clear={clear} cb={(e) => saveInput(e, `username`)}/>

        {!editMode &&
          <>
            <p>ПАРОЛЬ ПОЛЬЗОВАТЕЛЯ</p>
            <Input error={error} clear={clear} cb={(e) => saveInput(e, `password`)}/>
          </>
        }

        <p>РОЛИ</p>
        <Select className={`editUserSelect1 `}
                mode={'multiple'}
                value={data.roles}
                onChange={(value) => saveSelectRoles([`USER`, ...value], `roles`)}
        >
          <Option value={'ADMIN'}>Администратор</Option>
          <Option value={'BOOKER'}>Отдел оплаты труда</Option>
          <Option sele value={'TEACHER'}>Отдел обучения</Option>
          <Option value={'USER'}>Пользователь</Option>
        </Select>


        <p> ОТДЕЛ </p>
        <Select className={`editUserSelect`}
                value={data.department_id}
                onChange={(value) => saveSelect(value, `department_id`)}
        >
          {departments.map(x => {
            return <Option key={x.id} value={x.id}>{x.name}</Option>
          })}
        </Select>

        {/*<p> ДОЛЖНОСТЬ </p>*/}
        {/*<Select className={`editUserSelect`}*/}
        {/*        value={data.position_id}*/}
        {/*        onChange={(value) => saveSelect(value, `position_id`)}*/}
        {/*>*/}
        {/*  <Option value={101}>Не выбрано</Option>*/}

        {/*  {positions.map(x => {*/}
        {/*    if(data.department_id !== x.department.id) return*/}
        {/*    return <Option key={x.id} value={x.id}>{x.name}</Option>*/}
        {/*  })}*/}
        {/*</Select>*/}

        <p>EMAIL ДОМАШНИЙ</p>
        <Input
          defValue={data.e_mail}
          clear={clear}
          // error={error}
          cb={(e) => saveInput(e, `e_mail`)}/>


        <p>ПОЛ</p>
        <Select className={`editUserSelect`}
                value={data.gender}
                onChange={(value) => saveSelect(value, `gender`)}
        >
          <Option value={'Мужской'}>Мужской</Option>
          <Option value={'Женский'}>Женский</Option>
        </Select>

        <p> ДАТА РОЖДЕНИЯ </p>
        <input
          value={data.born_date}
          className={`inpDate`}
          onChange={(e) => onChangeDate(e, 'born_date')}
          type="date"
        />


        <p> РАБОЧИЙ ТЕЛЕФОН </p>
        <Input
          defValue={data.phone_work}
          clear={clear}
          cb={(e) => saveInput(e, `phone_work`)}/>

        <p> МОБИЛЬНЫЙ ТЕЛЕФОН</p>
        <Input
          defValue={data.phone_mobile}
          clear={clear} cb={(e) => saveInput(e, `phone_mobile`)}/>


        <p>НАЦИОНАЛЬНОСТЬ</p>
        <Input
          defValue={data.citizenship}
          clear={clear} cb={(e) => saveInput(e, `citizenship`)}/>



        <p>СЕМЕЙНЫЙ СТАТУС</p>
        <Select className={`editUserSelect`}
                value={data.maritalStatus}
                onChange={(value) => saveSelect(value, `maritalStatus`)}>
          <Option value={'Женат'}>Женат</Option>
          <Option value={'Не_женат'}>Не женат</Option>
        </Select>


        <p>ДАТА СВАДЬБЫ</p>
        <input
          value={data.wedding_date}
          className={`inpDate`}
          onChange={(e) => onChangeDate(e, 'wedding_date')} type="date"/>


        <div></div>
        <Space h={10}/>



        <p style={{alignSelf: `start`}}>ОБРАЗОВАНИЕ</p>
        <SaveModeEducation
          defValue={data.education}
          key={'education'}
          save={(e) => saveInput(e, `education`)}
        />



        <p style={{alignSelf: `start`}}>ЧЛЕНЫ СЕМЬИ</p>
        <SaveModeAnyLines
          defValue={data.family_members}
          key={'family_members'}
          type={1}
          save={(e) => saveInput(e, `family_members`)}
        />

        <p style={{alignSelf: `start`}}>КОНТАКТ С СЕМЬЁЙ</p>
        <SaveModeAnyLines
          defValue={data.family_contact}
          key={'family_contact'}
          type={2}
          save={(e) => saveInput(e, `family_contact`)}
        />


        <p></p>
        <div style={{display: 'flex'}}>
          <Button
            addStyleClass={block ? 'block_color' : ""}
            name={editMode ? "Сохранить" : "Создать"} cb={save}/>
        </div>
      </div>


      <Space h={20}/>

      {popUpCloseShow && <PopUpClose save={goBackTrue} closeFn={()=>setPopUpCloseShow(false)} />}

    </div>
  )
})
