import React, {useEffect, useRef} from "react";
import ReactDOM from "react-dom";
import './index.sass'
import {CloseBtn} from "../CloseBtn";

export const Modal = ({closeHide=false , closeFn, title,titlePadding=0, children, wrapClose, addClass = ''}) => {
  let refModal = useRef()

  useEffect(()=>{
    document.addEventListener('keypress', escPress)
    return document.removeEventListener('keydown', escPress)
  }, [])

  const escPress = (e) => {

  }

  const close = () => {
    closeFn()
  }


  const menuTarget = (e) => {
    if(e.target.id !== 'modal_wrap') return

    if(wrapClose){
      closeFn()
      return;
    }

    refModal.current.style.transform = "scale(1.055)"

    setTimeout(()=>{
      refModal.current.style.transform = "scale(1.025)"
    }, 75)

    setTimeout(()=>{
      refModal.current.style.transform = "scale(1)"
    }, 150)
  }

  const modal = (
    <div ref={refModal} onClick={menuTarget} id='modal_wrap' >
      <div className={`modal_window ${addClass}`}>

        {!closeHide && <CloseBtn top={22} right={22}  size={14}  fn={close}/>}

        <div style={{paddingLeft: titlePadding}} className='modal_title'>{title}</div>

        <div className='modal_content'>
          {children}
        </div>
      </div>
    </div>
  )


  return ReactDOM.createPortal(
    modal,
    document.getElementById('root')
  );
}