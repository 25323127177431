import {useEffect, useState} from "react";
import {Modal} from "../../../../components/Modal";
import "./index.sass"
import moment from "moment";
import {toJS} from "mobx";
import Competencies from "../../../../store/Competencies";
import {observer} from "mobx-react-lite";
import {Button} from "../../../../components/Button";
import DetailUser from "../../../../store/DetailUser";
import {useHttp} from "../../../../hooks/http.hook";
import {Space} from "../../../../components/Space";


export const ModalHistory = observer(({titlePadding, closeFn, data}) => {
  const {request} = useHttp()
  let history = data.history

  useEffect(()=>{
    history.forEach(x => {
      DetailUser.fetchUserById(request , x.approver_id)
    })
  }, [history])


  const getMomentFormat = (date) => {
    return moment(date).format('HH:mm DD/MM/YYYY')
  }

  return (
    <Modal
      closeFn={closeFn}
      title={'История оценки'}
      wrapClose={true}
      titlePadding={18}
    >
      <div id={'history_modal_id'}>
        {history.map(x => {
          let date = getMomentFormat(x.approve_date)
          let status = x.status.replace("_", " ")
          let evaluation =  x.evaluation.replace("_", " ")
          let approverUser = DetailUser.getUserById(x.approver_id)

          return (
            <>
              <div className='history_item' key={x.approve_date}>
                <div className="body">

                  <div className={'history_info_item'}>
                    <div className="date">{date}</div>
                    <div className="date justEnd">
                      {approverUser?.name && approverUser.family+" "+approverUser.name[0]+". "+approverUser.surname[0]+"." }
                    </div>

                    <span>Оценка: <span className={'green'}>{evaluation}</span> </span>
                    <span className={'justEnd'} >Статус: <span className={'green'}>{status}</span> </span>
                  </div>

                  <div className="text">{x.acc_text}</div>
                </div>
              </div>
            </>
          )
        })}



        <Space h={20}/>
        <div className={'flexCenter btnBott'}>
          <span className={'cancel_btn text_main_2 border_dashed'} onClick={closeFn}>Закрыть</span>
          <div></div>
        </div>

      </div>
    </Modal>
  )
})
