export default () => {
  return (
    <svg enable-background="new 0 0 64 64"
         height="17px" id="Layer_admin"
         version="1.1"
         viewBox="0 0 64 64" width="17px"
         xmlns="http://www.w3.org/2000/svg"
         >
      <path fill="#999999" d="M6.917,26.041h26.667c2.11,3.536,5.964,5.917,10.372,5.917c4.409,0,8.263-2.381,10.373-5.917h2.754  c3.4,0,6.167-2.767,6.167-6.167s-2.767-6.167-6.167-6.167h-2.754c-2.11-3.536-5.964-5.917-10.373-5.917  c-4.408,0-8.262,2.381-10.372,5.917H6.917c-3.4,0-6.167,2.766-6.167,6.167S3.517,26.041,6.917,26.041z M57.083,16.708  c1.746,0,3.167,1.42,3.167,3.167s-1.421,3.167-3.167,3.167h-1.479c0.275-1.011,0.436-2.07,0.436-3.167s-0.16-2.155-0.436-3.167  H57.083z M43.956,10.791c5.009,0,9.084,4.075,9.084,9.083c0,5.009-4.075,9.084-9.084,9.084s-9.083-4.075-9.083-9.084  C34.873,14.866,38.947,10.791,43.956,10.791z M6.917,16.708h25.391c-0.275,1.011-0.435,2.069-0.435,3.167s0.16,2.156,0.436,3.167  H6.917c-1.746,0-3.167-1.421-3.167-3.167S5.171,16.708,6.917,16.708z"/>
      <path fill='#999999' d="M57.082,37.959H30.417c-2.11-3.537-5.964-5.918-10.373-5.918c-4.409,0-8.263,2.381-10.374,5.918H6.917  c-3.4,0-6.167,2.766-6.167,6.166s2.767,6.167,6.167,6.167h2.754c2.111,3.536,5.964,5.917,10.373,5.917  c4.408,0,8.262-2.381,10.372-5.917h26.666c3.401,0,6.168-2.767,6.168-6.167S60.483,37.959,57.082,37.959z M6.917,47.292  c-1.746,0-3.167-1.421-3.167-3.167s1.421-3.166,3.167-3.166h1.478C8.12,41.97,7.96,43.028,7.96,44.125  c0,1.098,0.16,2.155,0.436,3.167H6.917z M20.044,53.209c-5.009,0-9.084-4.075-9.084-9.084s4.075-9.084,9.084-9.084  c5.008,0,9.083,4.075,9.083,9.084S25.052,53.209,20.044,53.209z M57.082,47.292h-25.39c0.275-1.012,0.435-2.069,0.435-3.167  c0-1.097-0.16-2.155-0.435-3.166h25.39c1.747,0,3.168,1.42,3.168,3.166S58.829,47.292,57.082,47.292z"/>
    </svg>
  )
}
