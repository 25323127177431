import {useState} from "react";
import './index.sass'
import {Space} from "../../../../components/Space";
import {Button} from "../../../../components/Button";
import {useHttp} from "../../../../hooks/http.hook";
import {notification} from "antd";


export const Files = () => {
  let [files, setFiles] = useState({})
  let {requestAxios} = useHttp()

  const sendNotif = (success, text) => {
    if(success){
      notification.success({
        message: `Данные загружены ${text}`,
        className: 'text_notification',
        duration: 2
      })
    }else{
      notification.error({
        message: `Что-то пошло не так. ${text}`,
        className: 'text_notification',
        duration: 2
      })
    }
  }

  const save = async () => {

    if(files.positions && files.departments && files.users){
      let a = await requestAxios('/api/departments/uploadDepartmentsFromCsv', files.departments )
      let c = await requestAxios('/api/positions/uploadPositionsFromCsv' , files.positions)
      let b = await requestAxios('/api/users/uploadUsersFromCsv' , files.users)

      Promise.all([a,b,c]).then(r => {
        let names = ['Департамент', 'Должность', 'Пользователи']
        r.forEach((i,iter) => sendNotif(!!i, names[iter]))
      })


      let elements =  [`file1` , `file2` , `file3`]

      elements.forEach(key => {
        let el = document.querySelector(`#${key}`)
        el.value = null
      })
      setFiles(null)
    }else{
      notification.error({
        message: `Загрузите трёх файлов осуществляется одновременно`,
        className: 'text_notification',
        duration: 3
      })
    }

  }


  const selectFile = (e, key) => {
    let payload = e.target.files

    let data = new FormData()
    let file = payload[0]

    data.append(`file`, file, file.name)

    let copy = {...files}
    copy[key] = data
    setFiles(copy)
  }


  return (
    <div id='FilesLoad'>
      <div className="title">Файлы компании</div>

      <div style={{display: `flex`, flexDirection: `row-reverse`, marginBottom: '10px'}}>
        <Button name={'Загрузить'} cb={save}/>
      </div>

      <div className='parentList'>
        <span>ФАЙЛ ОТДЕЛЫ</span>
        <input type='file' id={'file1'} onChange={(e)=>selectFile(e,`departments`)}/>
      </div>
      <Space h={15}/>

      <div className='parentList'>
        <span>ФАЙЛ ДОЛЖНОСТИ</span>
        <input type='file' id={'file2'} onChange={(e) => selectFile(e,'positions')}/>
      </div>
      <Space h={15}/>

      <div className='parentList'>
        <span>ФАЙЛ ПОЛЬЗОВАТЕЛИ</span>
        <input type='file' id={'file3'} onChange={(e)=>selectFile(e, `users`)}/>
      </div>

    </div>
  )
}
