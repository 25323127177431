import {useEffect, useState} from "react";
import {Modal} from "../../../../components/Modal";
import {Select} from "antd";
import {ButtonsForModal} from "../../../../components/ButtonsForModal";
import Admin from "../../../../store/Admin/Admin";
import {observer} from "mobx-react-lite";
import ShosStore from "../../../../store/Admin/ShosStore";
import {useHttp} from "../../../../hooks/http.hook";
import "./index.sass"
import {toJS} from "mobx";
import {Space} from "../../../../components/Space";
import UsersList from "../../../../store/Admin/UsersList";

const Option = Select.Option

export const ModalCreatePosition = observer(({leader ,positionParent, closeFn}) => {
  let [error, setError] = useState(null)
  let [position, setPosition] = useState(null)
  let [user, setUser] = useState(null)

  let {request} = useHttp()

  let positions = Admin.getPositions()
  let users = UsersList.getUsers()

  const selectPosition = (id) => {
    setPosition(id)
  }

  const selectUser = (id) => {
    setUser(id)
  }


  const save = async () => {
    if(!position){
      setError(true)
      return
    }
    await ShosStore.createPosition(request , position , leader, user )

    closeFn()
  }

  return (
    <div id={`modalCreatePosition`}>

      <Modal
        closeFn={closeFn}
        title={'Создание должности'}
      >

        <div>
          <Space h={15}/>

          <div><span className={'title_min'}>Выберите должность</span></div>

          <Select
            className={`editUserSelect3 ${error ? "empty_info2": ""}`}
            onChange={selectPosition}
          >
            {positions.map(x=>{
              if(x.parent_id !== positionParent || x.id === 1) return
              return  <Option key={x.id}>{x.name}</Option>
            })}
          </Select>

          <Space h={15}/>

          <div><span className={'title_min'}>Выберите сотрудника</span></div>
          <Select
            className={'editUserSelect3'}
            value={user}
            onChange={selectUser}>
            <Option value={null}>Не выбрано</Option>

            {users.map(x=>{
              if(x.position.id !== 101 || x.name == 'Вакант') return
              let fullName = x.family + " " + x.name +" "+ x.surname
              return <Option value={x.id} key={x.id}>{fullName}</Option>
            })}
          </Select>
        </div>


        <ButtonsForModal save={save} okText={'Cоздать'} cancel={closeFn} addClasses={[]}/>

      </Modal>
    </div>

  )
})
