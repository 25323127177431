import {useEffect, useState} from "react";
import {Modal} from "../../../../components/Modal";
import {Select} from "antd";
import {ButtonsForModal} from "../../../../components/ButtonsForModal";
import Admin from "../../../../store/Admin/Admin";
import {observer} from "mobx-react-lite";
import ShosStore from "../../../../store/Admin/ShosStore";
import {useHttp} from "../../../../hooks/http.hook";
import "./index.sass"
import {toJS} from "mobx";
import {Space} from "../../../../components/Space";
import UsersList from "../../../../store/Admin/UsersList";

const Option = Select.Option

export const ModalCreateUserToPosition = observer(({x, leader ,positionParent, closeFn}) => {
  let [error, setError] = useState(null)
  let [userId, setUserId] = useState(null)
  let [vacantId, setVacantId] = useState(null)
  let seflPositionId = x.position.id

  let {request} = useHttp()

  let positions = Admin.getPositions()
  let users = UsersList.getUsers()

  const selectUser = (id) => {
    setError(false)
    setUserId(id)
  }


  const save = async () => {
    if(!userId) {
      setError(true)
      return
    }
    vacantId = x.worker.id
    await ShosStore.movePositionByUser(request , userId , vacantId )
    closeFn()
  }

  return (
    <div id={`modalCreatePosition`}>

      <Modal
        closeFn={closeFn}
        title={'Переместить сотрудника'}
      >

        <div>
          <Space h={15}/>

          <div><span className={'title_min'}>Должность</span></div>

          <Select className={'editUserSelect3'}
            value={seflPositionId}
            disabled={true}
          >
            {positions.map(x=>{
              return  <Option key={x.id} value={x.id}>{x.name}</Option>
            })}
          </Select>

          <Space h={15}/>

          <div><span className={'title_min'}>Выберите сотрудника</span></div>

          <Select
            className={`editUserSelect3 ${error ? "empty_info2" : ""}`}
            value={userId}
            onChange={selectUser}>
            <Option value={null}>Не выбрано</Option>

            {users.map(x=>{
              if(x.position.id !== 101 || x.name === 'Вакант') return
              let fullName = x.family + " " + x.name +" "+ x.surname
              return <Option value={x.id} key={x.id}>{fullName}</Option>
            })}
          </Select>
        </div>


        <ButtonsForModal save={save} okText={'Переместить'} cancel={closeFn} addClasses={[]}/>

      </Modal>
    </div>

  )
})
