import {useEffect, useState} from "react";
import './index.sass'
import {Button} from "../../../../components/Button";
import {Select, Table} from "antd";
import {useHistory} from "react-router-dom";
import {useHttp} from "../../../../hooks/http.hook";
import Admin from "../../../../store/Admin/Admin";
import {observer} from "mobx-react-lite";
import {PopUpAdd} from "../../components/PopUpAdd";
import {toJS} from "mobx";
import {MilkLine} from "../../../../components/MilkLine";
import {DeleteOutlined, AppstoreAddOutlined , EditOutlined} from "@ant-design/icons";
import {CustomTable} from "../../../../components/CustomTable";
import {Space} from "../../../../components/Space";
import DelConfirm from "../../../../components/DelConfirm";


const columns = [
  {
    title: 'НАИМЕНОВАНИИЕ КОМПЕТЕНЦИИ',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '',
    dataIndex: 'fn',
    key: 'fn',
  }
];

export const CompetenciesAdmin = observer(() => {
  const {request} = useHttp()
  const history = useHistory()
  let [childListOpen, setChildListOpen] = useState([])
  const [showModal, setShowModal] = useState(null)


  useEffect(()=>{
    Admin.fetchCat(request, 'Компетенция')
  }, [])


  const getCompCategories = Admin.getCompParentChild()

  const addChildList = (id) => {
    let list = [...childListOpen]

    let f = list.findIndex(x => x === id)

    if(f>-1) list.splice(f,1)
    if(f === -1) list.push(id)

    setChildListOpen(list)
  }

  const goTo = (parent) => {
    history.push('/admin/competencies/new', {parentId: parent.id})
  }


  const saveParentComp = (text) => {
    Admin.addCat(request, 'Компетенция', text)
    setShowModal(null)
  }

  const delParent = (parent) => {
    Admin.deleteCategoriesById(request, parent.id)
  }

  const editParent = (parent) => {
    setShowModal({
      new: false,
      edit: true,
      name: parent.categoryName,
      obj: parent
    })
  }

  const editParentSave = (name) => {
    let {id} = showModal.obj
    let data =  {
      "id": id,
      "categoryName": name,
      "categoryType": "Компетенция"
    }
    Admin.editCategoriesById(request, data)
    setShowModal(null)
  }

  const editCompetencies = (parent , comp) => {
    history.push(
      '/admin/competencies/new',
      {parentId: parent.id , compId:comp.id, edit: true, object: JSON.stringify(comp)}
    )
  }

  const deleteCompetencies = (id) => {
    Admin.delComp(request, id)
  }

  const dataSource = () => {
    let r = []

    getCompCategories.forEach(parent=>{
      let canDelParent = !parent.comp.length

      r.push({
        key: parent.categoryName,
        name: <div className={'flex'}> {parent.categoryName}
          <p className={`arrowTable ${!childListOpen.includes(parent.id) ? "arrowTableUp" : ""}`}
             onClick={()=>addChildList(parent.id)}/>
        </div>,

        item: parent,
        fn: <div className={'fnsBtnsTable'}>
          <AppstoreAddOutlined title={'Добавить Компетенцию'} onClick={()=>goTo(parent)}/>
          <EditOutlined title={'Редактировать запись'}  onClick={()=>editParent(parent)}/>
          {canDelParent && <DeleteOutlined title={'Удалить запись'} onClick={()=>delParent(parent)}/>}
        </div>,


      })

      !childListOpen.includes(parent.id) && parent.comp.forEach(item => {
        r.push({
          key: `child_${item.id}`,
          name: <div className={'flexCenterVert firstChar'}>
            <span style={{marginLeft: '20px'}}>{item.comp_name}</span>
            {/*<i className={`infoBtn`} onClick={()=>modelShowFn('info', item)}/>*/}
          </div>,
          fn: <div className={'fnsBtnsTable'}>
            <EditOutlined title={'Редактировать запись'}  onClick={()=>editCompetencies(parent, item)}/>
            <DelConfirm title={'Удалить запись'} saveFn={()=>deleteCompetencies(item.id)}/>
          </div>,
          item: item
        })
      })
    })

    return r
  }

  const getRowClassName = (el) => {
    if(el.item.comp) return 'title_row'
  }


  return (
    <div id={'CompetenciesAdmin'} className={'userPage'}>
      <div className="title">Компетенции</div>
      <MilkLine/>

      <Space h={15}/>

      <div style={{display: `flex`, flexDirection: `row-reverse`, marginBottom: '10px'}}>
        <Button name={'Добавить категорию'} cb={()=>setShowModal({new: true})}/>
      </div>

      <CustomTable
        rowClassName={getRowClassName}
        columns={columns}
        dataSource={dataSource()}
        tableHeight={window.innerHeight - 246 - 15} />


      {showModal?.new && <PopUpAdd
        title={'НАИМЕНОВАНИИЕ КАТЕГОРИИ'}
        save={saveParentComp}
        closeFn={()=>setShowModal(null)}
      />}

      {showModal?.edit && <PopUpAdd
        title={'НАИМЕНОВАНИИЕ КАТЕГОРИИ'}
        save={editParentSave}
        defValue={showModal.name}
        okText={'Сохранить'}
        closeFn={()=>setShowModal(null)}
      />}

    </div>
  )
})
