import {useEffect, useState} from "react";
import './index.sass'
import {notification, Select} from "antd";
import {Button} from "../../../../../components/Button";
import {Input} from "../../../../../components/Input/Input";
import Admin from "../../../../../store/Admin/Admin";
import {useHttp} from "../../../../../hooks/http.hook";
import {toJS} from "mobx";
import {Space} from "../../../../../components/Space";
import {useHistory} from "react-router-dom";
import {MilkLine} from "../../../../../components/MilkLine";
import {PopUpClose} from "../../../../../components/PopUpClose";

const Option = Select.Option


export const NewComp = (props) => {
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [error, setError] = useState(false)
  const [clear, setClear] = useState(false)
  const history = useHistory()
  const getCats = Admin.getCats()
  const {request} = useHttp()
  const [activeCat, setActiveCat] = useState(history.location.state.parentId)
  const [editMode, setEdit] = useState(false)
  let [popUpCloseShow, setPopUpCloseShow] = useState(false)
  let [haveChange, setHaveChange] = useState(false)


  useEffect(()=>{
    let {edit, parentId, object} = history.location.state

    if(edit) {
      setEdit(true)
      let {description, comp_name} = JSON.parse(object)
      setDescription(description)
      setName(comp_name)
    }
  }, [])


  const editItem = () => {
    if(!name) {
      setError(true)
      return
    }
    let catF = getCats.find(x => x.id === activeCat)

    let data = {
      "id": history.location.state.compId,
      "comp_name": name,
      "category": {
        "id": activeCat,
        "categoryName": catF.categoryName,
        "categoryType": "Компетенция",
      },
      "description": description,
      "req_level": "Ознакомлен"
    }

    Admin.editComp(request , data)

    goBackTrue()
  }


  const save = async () => {
    if(!activeCat) return;

    if(!name) {
      setError(true)
      return
    }

    let catF = getCats.find(x => x.id === activeCat)

    let data = {
      "comp_name": name,
      "category": {
        "id": activeCat,
        "categoryName": catF.categoryName,
        "categoryType": "Компетенция",
      },
      "description": description,
      "req_level": "Ознакомлен"
    }

    await Admin.addComp(request , data)

    setDescription('')
    setName(false)
    setClear(Math.random())

    goBackTrue()
  }


  const goBack = () => {
    if(haveChange){
      setPopUpCloseShow(true)
      return
    }

    goBackTrue()
  }

  const goBackTrue = () => {
    history.push("/admin/competencies")
  }

  const changeInput = (e) => {
    setHaveChange(true)
    setName(e.target.value)
  }
  const changeText = (e) => {
    setHaveChange(true)
    setDescription(e.target.value)
  }

  return (
    <div id={'NewComp'} className={'userPage'}>
      <div className="title">Формирование компетенции</div>
      <MilkLine/>

      <Space h={30}/>

      <div className="close_btn_global" onClick={goBack}/>





      <div className={'gridAdmin'}>
        <div>ТИП КОМПЕТЕНЦИИ</div>

        <Select
          className={`editUserSelect`}
          value={activeCat}
          onChange={(id)=>setActiveCat(id)}
        >
          {getCats.map(x=>{
            return <Option key={x.id} value={x.id}> {x.categoryName} </Option>
          })}
        </Select>

        <div>НАЗВАНИЕ КОМПЕТЕНЦИИ</div>
        <Input clear={clear} error={error} defValue={name} cb={changeInput} />


        <div>ОПИСАНИЕ</div>
        <div className="text_area">
          <textarea
            className={'areaAdmin'}
            onChange={changeText}
            value={description}
          />
        </div>

        <div></div>
        <div style={{display: `flex`}}>
          {editMode && <Button name={"Сохранить"} cb={editItem}/>}
          {!editMode && <Button name={'Создать'} cb={save}/>}
        </div>

      </div>
      {popUpCloseShow && <PopUpClose save={goBackTrue} closeFn={()=>setPopUpCloseShow(false)} />}
    </div>
  )
}
