import {useEffect, useState} from "react";
import './imdex.sass'
import {UserOutlined} from "@ant-design/icons";
import {Avatar} from "antd";
import DetailUser from "../../../../store/DetailUser";
import User from "../../../../store/User";
import {toJS} from "mobx";
import {ModalAvatarChange} from "../ModalAvatarChange";

export const AvatarProfile = ({avatar_url=false, size, edit=true, companyAvatar=false}) => {
  let [choosePhotoModal, setChoosePhotoModal] = useState(false)

  let me = User.getUser()
  let companyLogo = me.position.organization.logo_url
  let uri = ""
  if(companyLogo){
    uri = `http://edp.proteger.pro/files/avatars/1_` + companyLogo
  }else{
    uri = require('../../../../img/company.png')
  }

  let src = companyAvatar ?
     uri :
    `http://edp.proteger.pro/files/avatars/${avatar_url}`

  return (
    <div className='avatar'>

      {edit && <div className="uploadLayer"
           onClick={()=>setChoosePhotoModal(true)}
           style={{width: size, height: size}}>
        {size>200 && <span>{avatar_url ? "Изменить фотографию": "Установить фотографию"}</span>}
      </div>}

      {(avatar_url||companyAvatar) ?
        <img width={size} height={size}
             src={src}
             alt="" className={'userAvatar'}/> :
        <Avatar onClick={()=>setChoosePhotoModal(true)} size={size} icon={<UserOutlined/>}/>
      }
      {choosePhotoModal &&
      edit && <ModalAvatarChange close={()=>setChoosePhotoModal(false)}/>}
    </div>
  )
}
