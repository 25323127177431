import "./index.sass"
import {Space} from "../../components/Space";
import {Table , Tabs} from "antd";
import {CoursesTable} from "./CoursesTable";
import {RequirementsTable} from "./RequirementsTable";
import {useHttp} from "../../hooks/http.hook";
import {useEffect, useState} from "react";
import CoursesStore from "../../store/CoursesStore";
import {Input} from "../../components/Input/Input";
import {BackBtn} from "../../components/BackBtn";
import {InputSearch} from "../../components/InputSearch/Input";

export const Courses = () => {
  const [searchText, setSearchText] = useState('')

  let {request} = useHttp()

  useEffect(()=>{
    CoursesStore.fetchData(request)
  }, [])

  const searchTextSave = (e) => {
    setSearchText(e.target.value)
  }

  const cleanAll = () => {
    setSearchText(null)
  }

  return (
    <div id='CoursesPage'>
      <div className={'title'}>Курсы</div>
      <Space h={15}/>

      <BackBtn backBtn={false} okFn={()=>{}}/>

      <div className="flexRow searchTable">
        <InputSearch placeholder={'Поиск...'} cb={searchTextSave} cleanAll={cleanAll}/>
      </div>

      <Tabs
        defaultActiveKey="1"
        type="card"
        items={[
          {
            label: `Требования`,
            key: 1,
            children: <RequirementsTable  searchText={searchText}/>,
          },
          {
            label: `Список курсов`,
            key: 2,
            children: <CoursesTable  searchText={searchText}/> ,
          },

        ]
        }
      />

    </div>
  )
}
