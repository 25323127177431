import {useState} from "react";
import "./index.sass"
import {AvatarProfile} from "./avatar/AvatarProfile";


export const AvatarCompany = () => {

  return (
    <div id={'avatarCompanyZone'}>
      <AvatarProfile
        size={250}
        companyAvatar={true}
      />
    </div>
  )
}
