import {useCallback, useEffect, useMemo, useState} from "react";
import {AvatarProfile} from "../../../Profile/components/avatar/AvatarProfile";
import OrganizationStore from "../../../../store/OrganizationStore";
import {observer} from "mobx-react-lite";
import {BioBlock} from "../../../Organization/BioBlock";
import {useHttp} from "../../../../hooks/http.hook";
import {toJS} from "mobx";
import {ModalCreatePosition} from "./ModalCreatePosition";
import {Button} from "../../../../components/Button";
import {Space} from "../../../../components/Space";
import ShosStore from "../../../../store/Admin/ShosStore";
import {ModalCreateUserToPosition} from "./ModalCreateUserToPosition";
import {ModalConfirm} from "./ModalConfirm";

export const TreeItem = observer(({showBioFn , x, first, leader = {}}) => {
  let [modal, setModal] = useState(false)
  let [modalSetUser, setModalSetUser] = useState(false)
  let [vacant, setVacant] = useState(false)
  let [hasSubjects, setHasSubjects] = useState(false)
  let [modalOpenConfirm, setModalOpenConfirm] = useState({open:false, fn:null})


  let {request} = useHttp()

  useEffect(()=>{
    if(x.worker.name === 'Вакант') setVacant(true)
    setHasSubjects(!!x.worker.subjects.length)
  }, [])


  let bioHuman = x.worker && x.worker.name && (x.worker.id !== 'empty')

  let bioFn = bioHuman ?
    (e) => showBioFn(e, x, x.worker.id, false, x.leader) :
    (e) => showBioFn(e, x, false, false, x.leader)

  let avatarUrl = (!x.worker || x.worker.id === 'empty' || !x.worker.avatar_url) ? null :
    x.worker.id + "_" + x.worker.avatar_url

  const blockShowUp = (selfId) => {}

  const fullName = x.worker.name !== 'Вакант' ? x.worker.family+" "+x.worker.name+" "+x.worker.surname : 'Вакантная должность'


  const dismiss = () => {
    ShosStore.dismissUserByPosition(request, x.worker.id)
  }

  const dismissOpen = () => {
    setModalOpenConfirm({open: true, save: dismiss , text:'Уволить сотрудника'})
  }

  const reserve = () => {
    ShosStore.reserveUserByPosition(request, x.worker.id)
  }

  const reserveOpen = () => {
    setModalOpenConfirm({open: true, save: reserve, text:'Снять с должности'})
  }

  const cancel = () => {
    ShosStore.cancelUserByPosition(request, x.worker.id)
  }

  const cancelOpen = () => {
    setModalOpenConfirm({open: true, save: cancel, text:'Рассформировать позицию'})
  }

  const dopFns = () => {
    return (
      <div className={'dopFns'} >
        <Button cb={()=>setModal(true)} name={'Создать подчинённого'}/>
        {x.worker.id !== 1 &&
        <>
          {!vacant &&
          <>
            <Space h={5}/>
            <Button cb={dismissOpen} name={'Уволить сотрудника'}/>
            <Space h={5}/>
            <Button cb={reserveOpen} name={'Снять с должности'}/>
          </>}

          {vacant &&
          <>
            <Space h={5}/>
            <Button cb={()=>setModalSetUser(true)} name={'Выбрать сотрудника'}/>
            <Space h={5}/>
            {!hasSubjects && <Button cb={cancelOpen} name={'Расформировать'}/>}
          </>}

        </>}
      </div>
    )
  }

  return (
    <div
      className={`positionItemInner ${!first ? "lineBefore" : ""} `}
      onClick={(e) => bioFn(e)}
    >

      <div className="avatarBlock">
        <AvatarProfile
          avatar_url={avatarUrl}
          size={70}
          edit={false}
          companyAvatar={!avatarUrl}
        />
      </div>

      <div className="infoByPos">
        {bioHuman && <span className={'posValue'}>
          {fullName}

        </span>}

        <span title={x.position.name} className={'posName'}> {x.position.name} </span>

        {dopFns()}

      </div>

      {modal && <ModalCreatePosition positionParent={x.position.id} leader={x.worker.id} closeFn={()=>setModal(false)}/>}
      {modalSetUser && <ModalCreateUserToPosition x={x} positionParent={x.position.id} leader={x.worker.id} closeFn={()=>setModalSetUser(false)}/>}
      {modalOpenConfirm.open && <ModalConfirm saveFn={modalOpenConfirm} closeFn={()=>setModalOpenConfirm({open: false, fn:null})}/>}
    </div>
  )
})
