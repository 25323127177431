import {useEffect, useState} from "react";
import {Table} from "antd";
import {PaginationForTable} from "../Pagination";

export const CustomTable = ({dataSource , columns , tableHeight=500 , rowClassName}) => {
  const [countAll, setCountAll] = useState(0)
  let [onPageCount, setOnPageCount] = useState('20')
  let [dataPage, setDataPage] = useState([])
  let [page, setPage] = useState(0)

  useEffect(()=>{
    // if(!dataSource.length) return

    let list = []
    let from = page*parseInt(onPageCount)
    let to = page*onPageCount+parseInt(onPageCount)

    dataSource.forEach((x, i)=>{
      if(from <= i && to > i){
        list.push(x)
      }
    })

    setDataPage(list)
    setCountAll(dataSource.length)
  },[dataSource, page])


  let changePage = (type) => {
    setPage(prev => type ? prev+1 : prev-1)
  }

  let changeItemsOnPage = () => {}

  return (
    <>
      <Table
        dataSource={dataPage}
        pagination={false}
        columns={columns}
        scroll={{ y: tableHeight }}
        rowClassName={rowClassName || null}
        // className={'tableMin'}
        // bordered={false}
      />

      <PaginationForTable
        countAll={countAll}
        changePage={changePage}
        changeItemsOnPage={changeItemsOnPage}
        onPageCount={onPageCount}
        setOnPageCount={setOnPageCount}/>

    </>
  )
}
