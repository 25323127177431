import React, {useContext, useEffect, useState, useCallback , useRef} from "react";
import {useHttp} from "../../hooks/http.hook";
import {AuthContext} from "../../context/AuthContext";
import "./index.sass"
import {InputMail} from "../../components/InputMail/Input";
import {Space} from "../../components/Space";
import {Button} from "../../components/Button";
import {ModalForget} from "./components/ModalForget";
import {ModalReset} from "./components/ModalReset";
import {InputPassword} from "../../components/InputPassword/Input";

const useSyncRef = (current) => {
  const ref = useRef(current);
  ref.current = current;

  return ref;
};
const useSyncRef2 = (current) => {
  const ref = useRef(current);
  ref.current = current;

  return ref;
};

export const Login = (props) => {
  const auth = useContext(AuthContext)
  const { request,reAuthRequest  } = useHttp()

  let [modalShow, setModalShow] = useState(false)
  let [modalShowReset, setModalShowReset] = useState(false)
  let [error, setError] = useState(false)
  let [dataError, setDataError] = useState(false)

  let [username, setUsername] = useState("")
  let [password, setPassword] = useState("")

  const textRef = useSyncRef(username);
  const textRef2 = useSyncRef2(password)

  useEffect(()=>{
    if(props.forget) setModalShowReset(true)
  },[])

  const changeHandler = (event) => {
    setError(false)
    setDataError(false)

    if(event.target.name === `username`){
      setUsername(event.target.value)
    }
    if(event.target.name === `password`){
      setPassword(event.target.value)
    }
  }

  const checkEmailType = (email) => {
    const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
    return EMAIL_REGEXP.test(email);
  }

  const loginHandler = useCallback(async () => {
    username = textRef.current
    password = textRef2.current

    if(!username || !password || !checkEmailType(username)) {
      setError(true)
      return
    }

    try{
      const data = await request('/api/auth/login', 'POST', {username, password})
      auth.login(data.token,data.user_id,request)

      reAuthRequest({Authorization: `Bearer `+ data.token },  data.user_id)

    }catch (error){
      setError(true)
      setDataError(true)
    }
  }, [textRef , textRef2])




  const enterFn = useCallback((e) => {
    if(e.key === 'Enter') loginHandler()
  }, [loginHandler])


  useEffect(()=>{
    document.addEventListener('keydown', enterFn)
    return () => document.removeEventListener('keydown', enterFn)
  }, [enterFn])



  return (
    <div className='loginPageStyle'>

      <div className='center '>

        <img src={require('../../img/logo_lock.png')} alt={'logo'}/>

        <Space h={40}/>

        <div className={'grid'}>
          <label className='input_label' htmlFor={'username'}>Почта</label>

          <InputMail
            error={error}
            cb={changeHandler}
            name='username'
            id='username'
            inputMode='email'
          />

          <label className='input_label' htmlFor={'pass'}>Пароль</label>

          <InputPassword
            error={error}
            cb={changeHandler}
            name='password'
            id='pass'
            type='password'
          />

          <div></div>

          <div className='row_space_between border_top'>

            <Button
              error={error}
              cb={loginHandler}
              name={'ВОЙТИ В СИСТЕМУ'}
            />

            {dataError && <span className={`wrong_data`}>Не верная почта или пароль</span>}

            <span className='btn_forget text_main_1' onClick={() => setModalShow(true)}> ЗАБЫЛИ? </span>
          </div>

        </div>

      </div>


      {modalShow && <ModalForget
        closeFn={()=>setModalShow(false)}
       />}

      {modalShowReset && <ModalReset
        closeFn={()=>setModalShowReset(false)}
       />}
    </div>
  )
}

