import {Button} from "../Button";

export const ButtonsForModal = ({addClasses , cancel, save, okText='Cохранить'}) => {

  return (
    <div style={{alignItems: `center`}} className={'row2'}>
      <span className={'cancel_btn text_main_2 border_dashed'} onClick={cancel}>Отмена</span>
      <Button name={okText} addStyleClass={addClasses} cb={save}>Сохранить</Button>
    </div>
  )
}
