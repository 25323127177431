import {Modal} from "../../../../components/Modal";
import "./index.sass"
import {ButtonsForModal} from "../../../../components/ButtonsForModal";
import {useState} from "react";
import {toJS} from "mobx";

export const ModalNote = ({ closeFn, data, edit=false, saveFn}) => {
  const [text , setText] = useState(data.actual.notice)

  const onChange = (e) => {
    setText(e.target.value)
  }

  const saveForm = () => {
    saveFn(text)
    closeFn()
  }

  return (
    <Modal
      closeFn={closeFn}
      title={'Комментарий'}
      wrapClose={!edit}
      titlePadding={edit ? 10 : 0}
    >
      {!edit && <div id={'info_modal_id'} className={'modalBodyBlock'}>
        {text || 'Комментариев нет'}
      </div>}

      {edit && <>
        <div className={'text_area'}>
          <textarea
            onChange={onChange}
            disabled={!edit}
            name="text"
            rows="8"
            placeholder={'Укажите комментарий'}
            value={text || ""}
          />
        </div>

        <ButtonsForModal save={saveForm} cancel={closeFn}/>
      </>}

    </Modal>
  )
}
