import {useState} from "react";
import './index.sass'
import {Modal} from "../../../../components/Modal";
import {ButtonsForModal} from "../../../../components/ButtonsForModal";
import {Input} from "../../../../components/Input/Input";

export const PopUpAdd = ({closeFn, defValue, title, save , okText = 'Создать' }) => {
  const [text, setText] = useState(null)
  const [error, setError] = useState(false)

  const saveForm = () => {
    if(text) save(text)

    if(!text) {
      setError(true)
      closeFn()

      setTimeout(()=>{
        setError(false)
      }, 300)
    }
  }

  return (
    <Modal
      closeFn={closeFn}
      title={title}
    >
      <div id={'info_modal_id'}>
        <div className={'text_main_name'}>
          <Input defValue={defValue} error={error} cb={(e)=>setText(e.target.value)}/>
        </div>
      </div>

      <ButtonsForModal okText={okText} save={saveForm} cancel={closeFn}/>
    </Modal>
  )
}
