import React, {useState} from "react";
import {InputPassword} from "../../components/InputPassword/Input";
import {Button} from "../../components/Button";
import './index.sass'
import {Space} from "../../components/Space";
import {useHttp} from "../../hooks/http.hook";
import {notification} from "antd";


export const Settings = () => {
  let [old, setOld] = useState("")
  let [newPassword, setNewPassword] = useState("")
  let [confirmNewPassword, setConfirmNewPassword] = useState("")
  let [error, setError] = useState(false)
  let [clear, setClear] = useState(false)

  let {request} = useHttp()


  const formOk = () => {
    notification.success({
      message: `Пароль изменён`,
      className: 'text_notification',
      duration: 3
    })
  }

  let save = () => {
    if(!newPassword || !confirmNewPassword || !old){
      setError('Все поля обязательные для заполнения')
      return
    }
    if(newPassword !== confirmNewPassword){
      setError('Пароль и поддтверждение пароля должны совпадать')
      return
    }

    let data = {
      "old_password": old,
      "new_password": newPassword
    }

    request(`/api/auth/password/new`, 'post', data)
      .then(data => {
        clearAll()
        formOk()
      })
      .catch(e => {
        setError('Старый пароль указан не верно')
      })

  }

  const clearAll = () => {
    setOld('')
    setNewPassword('')
    setConfirmNewPassword('')

    setClear(true)
    setTimeout(()=>{
      setClear(false)
    }, 100)
  }

  let saveInput = (e) => {
    setError(false)
    let pwd = e.target.value
    setOld(pwd)
  }

  let saveInput2 = (e) => {
    setError(false)
    let pwd = e.target.value
    setNewPassword(pwd)
  }

  let saveInput3 = (e) => {
    setError(false)
    let pwd = e.target.value
    setConfirmNewPassword(pwd)
  }

  return (
    <div id={'settingsPage'}>
      <div className={`title`}>Смена пароля</div>
      <Space h={25}/>


      <div>Старый пароль</div>
      <InputPassword clear={clear} error2={error} cb={saveInput}/>

      <Space h={15}/>

      <div>Новый пароль</div>
      <InputPassword clear={clear} error2={error} cb={saveInput2}/>

      <Space h={15}/>

      <div>Повторите пароль</div>
      <InputPassword clear={clear} error2={error} cb={saveInput3}/>



      <div className={'btnPosition textCenter'}>

        <Button
          error={error}
          cb={save}
          name={"Изменить"}/>

        {error && <span className={`wrong_data`}>{error}</span>}

      </div>
    </div>
  )
}
