import React, {useEffect} from "react";
import "./index.sass"

export const StatusPopUp = ({change , close}) => {
  const status = [
    {title:'ОТПУСК' , key:"Отпуск"},
    {title:'НА РАБОТЕ' , key:"На_работе"},
    {title:'БОЛЬНИЧНЫЙ' , key:"Больничный"},
    {title:'ВЫХОДНОЙ' , key:"Выходной"}
  ]

  const clickClose = (e) => {
    let el = document.querySelector('#statusLine')
    if(!el.contains(e.target)) close()
  }

  useEffect(()=>{
    document.addEventListener('click', clickClose)
    return () => document.removeEventListener('click', clickClose)
    }, [])

  const clickFn = (e,status) => {
    e.stopPropagation()
    change(status)
    close()
  }

  return (
    <div className={'popUp'}>

      {status.map(item => {
        return (
          <div key={item.key} className='popUp_item color_rev clean_item'
               onClick={(e)=>clickFn(e,item.key)}
          >
            <span className={'popUp_item_title'}>{item.title}</span>
          </div>
        )
      })}

    </div>
  )
}
