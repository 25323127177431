import {useEffect, useState} from "react";
import {Select} from "antd";
import "./index.sass"

const Option = Select.Option

export const PaginationForTable  = ({countAll , changePage, onPageCount, setOnPageCount}) => {
  let [popUp, setPopUp] = useState(false)
  let [pagesAll, setPagesAll] = useState(0)
  let [pageTarget, setPageTarget] = useState(0)

  let [from, setFrom] = useState(0)
  let [to, setTo] = useState(0)

  let [rightActive, setRightActive] = useState(false)
  let [leftActive, setLeftActive] = useState(false)


  useEffect(()=>{
    if(!countAll) return

    setFrom(1)

    let pagesCount = Math.floor(countAll / parseInt(onPageCount))
    setPagesAll(parseInt(pagesCount))

    if(parseInt(pagesCount) >= 1){
      setRightActive(true)
      setTo(parseInt(onPageCount))
    }else{
      setTo(countAll)
    }

  }, [countAll])

  const pageNext = (type) => {
    if((type && !rightActive) || (!type && !leftActive) ) return

    if(type){
      // next
      setLeftActive(true)
      setRightActive(pagesAll>(pageTarget+1))
      setPageTarget(prev => prev+1)

      setFrom((pageTarget+1) * parseInt(onPageCount))

      let too = (pageTarget+1) === pagesAll ? countAll : (pageTarget+1) * parseInt(onPageCount) + parseInt(onPageCount)
      setTo(too)
    }else{
      //prev
      setRightActive(true)
      setLeftActive( (pageTarget-1)!==0)
      setPageTarget(prev => prev-1)

      setFrom(pageTarget-1 === 0 ? 1 : (pageTarget-1) * parseInt(onPageCount))
      let too = (pageTarget-1) * parseInt(onPageCount) + parseInt(onPageCount)
      setTo(too)
    }

    changePage(type)
  }

  const selectOption = (val) => {
    setOnPageCount(val)
    setPopUp(false)
  }

  return (
    <div className={'paginationForTable'}>
      <span>Результатов на странице:</span>

      <div className="selectCount">
        <span
          onClick={()=>setPopUp(prev => !prev)}
          className={'arrDown'}>{onPageCount}
        </span>

        {popUp && <div className="popUpPagination">
          <div
            className={`${onPageCount === '20' ? 'activeEl' : ''}`}
            onClick={()=>selectOption('20')}
          >20</div>
          <div
            className={`${onPageCount === '50' ? 'activeEl' : ''}`}
            onClick={()=>selectOption('50')}>50</div>
          <div
            className={`${onPageCount === 'Всё' ? 'activeEl' : ''}`}
            onClick={()=>selectOption('Всё')}>Всё</div>
        </div>
        }
      </div>

      <span className={'countAll'}> {from}-{to} из {countAll} </span>

      <span className={`prev ${leftActive ? 'active' : ''}`} onClick={()=>pageNext(false)}/>
      <span className={`next ${rightActive ? 'active' : ''}`} onClick={()=>pageNext(true)}/>
    </div>
  )
}
