import {makeAutoObservable} from "mobx";
import moment from "moment";
import _ from "lodash"
import 'moment/locale/ru';

class Notifications {
  notifications = []

  constructor() {
    makeAutoObservable(this)
  }

  getData() {
    return this.notifications
  }

  fetchData(request){
    request(`/api/notifications/list`)
      .then(data => {
        let notifications = data.filter(x => {

          if(x.message.includes('@')){
            let [id, text] = x.message.split('@')

            x.message = text
            x.user_about_id = id
          }


          x.date = moment(x.date, 'YYYY-MM-DD').format('DD MMM YYYY')
          return !x.delivered
        })

        notifications = _.sortBy(notifications , 'date')
        notifications = _.reverse(notifications)

        this.notifications = notifications

      })
  }

  reedAll(request){
    request(`/api/notifications/readed`, 'post', {"all": true})
      .then(data => {
        this.notifications = []
      })
  }


  reedOne(request , id){
    request(`/api/notifications/readed`, 'post', {"id": id})
      .then(data => {
        this.notifications = this.notifications.filter(x => x.id !== id)
      })
  }

  get getNewCount(){
    return this.notifications.length
  }

}

export default new Notifications()