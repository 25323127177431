import {makeAutoObservable, toJS} from "mobx";
import User from "./User";
import _ from "lodash"
import {notification} from "antd";
import DetailUser from "./DetailUser";
import Competencies, {formatData} from "./Competencies";
import RequarementsEmployees from "./RequarementsEmployees";

class CompetenciesEmployees {
  competencies = []
  updateIds = []
  history = []

  constructor() {
    makeAutoObservable(this)
  }


  getCompById(id){
    let u = DetailUser.getUserById(id)
    this.competencies = formatData(u.competencies)
    return this.competencies
  }

  getCompByPositionId(userId, request){
    let u = DetailUser.getUserById(userId)

    request(`/api/positions/getPositionById/${u.position.id}`)
      .then(r => {
        this.competencies = formatData(r.competences)

        RequarementsEmployees.setCourses(request , r.courses , userId)

        this.fetchUserByIdHistory(request , userId)
      })
  }

  getComp(){
    return this.competencies
  }

  getHistory(byId){
    return this.history[byId] || []
  }

  get getUpdateStatus(){
    return this.updateIds.length
  }

  //локальные изменения
  updateLocalEvaluation(item, type){
    if(item.actual.evaluation === type){
      item.actual.evaluation_local = null

      let f = this.updateIds.findIndex(x => x === item.id)
      this.updateIds.splice(f,1)
    }else{
      item.actual.evaluation_local = type
      if(!this.updateIds.includes(item.id)) this.updateIds.push(item.id)
    }

  }

  async approveDecline(userId , request, ids, approveStatus){
    let status = approveStatus ? "Согласовано" : "Не_согласовано"

    let promises = []

    ids.forEach(id => {
      let local = null

      this.competencies.forEach(gr => {
        gr.comp.forEach(itemComp => {
          if(itemComp.id === id) {
            local = itemComp
          }
        })
      })

      let reqData = {
        "competence_id": id,
        "owner": userId,
        "evaluation": local.actual.evaluation_local || local.actual.evaluation,
        "status": status,
        "acc_text": local.actual.acc_text,
        "notice": local.actual.notice,
      }

      let p = new Promise((resolve, reject) => {
        request(`/api/competencies/approve`,'post', reqData)
          .then(d => {
            local.history.splice(0,0,d)
            this.history[id].splice(0,0,d)

            resolve(local,reqData)
          })
      })
      promises.push(p)
    })

    return Promise.all(promises).then((items) => {
      items.forEach((local)=> {
        local.actual.evaluation = local.actual.evaluation_local || local.actual.evaluation
        local.actual.evaluation_local = null
        local.actual.status = status
        local.show = false
      })

      let text = items.length === 1 ? "Компетенция успешно изменена" : "Компетенции успешно изменены"
      notification.success({
        message: text,
        className: 'text_notification',
        duration: 3
      });

      return true
    });

  }

  saveNoteCompetencies(userId , request, text, comp){

    let reqData = {
      "competence_id": comp.id,
      "owner": userId,
      "evaluation": comp.actual.evaluation,
      "status": comp.actual.status,
      "acc_text": comp.actual.acc_text,
      "notice": text
    }

    request(`/api/competencies/approve`,'post', reqData )
      .then((data) => {
        comp.actual.notice = text

        comp.history.splice(0,0,data)
        this.history[comp.id].splice(0,0,data)


        notification.success({
          message: `Форма успешно сохранена` ,
          className: 'text_notification',
          duration: 3
        });
      })
  }


  fetchUserByIdHistory(request , id){
    request(`/api/competencies/getCompetenceHistoryByOwnerId/${id}/0/1000`)
      .then(data => {
        this.history = this.sortComp(data)
      })
  }

  sortComp (data) {
    const dataObj = []

    data.forEach(x => {
      if(dataObj[x.id_competence]){
        dataObj[x.id_competence].push(x)
      }else{
        dataObj[x.id_competence] = [x]
      }
    })

    dataObj.forEach((x)=> {
      x = _.reverse(x)
    })

    this.competencies.forEach(gr => {
      gr.comp.forEach(c => {
        if(dataObj[c.id]) {
          c.history = dataObj[c.id]
          c.actual = c.history[0]

        }
      })
    })
    return dataObj
  }

}

export default new CompetenciesEmployees()