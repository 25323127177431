import {useEffect, useState} from "react";
import {Modal} from "../../../../components/Modal";
import {ButtonsForModal} from "../../../../components/ButtonsForModal";
import {observer} from "mobx-react-lite";


export const ModalConfirm = observer(({ saveFn , closeFn}) => {

  const save = async () => {
    saveFn.save()
    closeFn()
  }


  return (
    <div id={`modalCreatePosition`}>

      <Modal
        closeFn={closeFn}
        title={'Подтвердите действие'}
      >
        <div id={'info_modal_id'}>
          <div className={'text_main_name_close'}>
            <p>{`Вы действительно хотите выполнить это действие?` }</p>
          </div>
        </div>

        <ButtonsForModal save={save} okText={'Продолжить'} cancel={closeFn} addClasses={[]}/>
      </Modal>
    </div>

  )
})
