import {useEffect, useState} from "react";
import Admin from "../../../../../../store/Admin/Admin";
import "./index.sass"
import {observer} from "mobx-react-lite";
import {ButtonsForModal} from "../../../../../../components/ButtonsForModal";
import {toJS} from "mobx";

export const SchooseModal = observer(({save , data , close}) => {
  let [childList, setChildList] = useState([])
  let [allData, setAllData] = useState(data || [] )
  let [activeChild, setActiveChild] = useState(null)


  let [activeParentId, setActiveParentId] = useState(null)
  let [activeChildId, setActiveChildId] = useState(null)



  let levels = ['Ознакомлен','Знает','На_практике','Эксперт']

  useEffect(()=>{

    if(allData.length){
      let comp = allData[0].comp
      setChildList(comp)
    }
  }, [])

  const selectParent=(item)=>{
    setChildList(item.comp || [])

    setActiveChild({})

    setActiveParentId(item.id)
  }

  const selectChild=(item)=>{
    setActiveChild(item)

    setActiveChildId(item.id)
  }


  const saveForm =() => {
    save(allData)
    close()
  }

  const countActive = (data) => {
    let d = data.comp

    let count = 0
    d.forEach(x => {
      if (x.active) count = count + 1
    })

    return count
  }

  const activeChildLevel = (level) => {
    if(activeChild.req_level === level) {
      activeChild.req_level = null
      activeChild.active = false
      activeChild.delete = true
      return
    }

    if(activeChild.req_level !== level) {
      activeChild.active = true
      activeChild.add = true
      activeChild.req_level = level
      return
    }

    if(activeChild.delete){
      activeChild.active = true
      activeChild.delete = false
      activeChild.req_level = level
      return
    }
  }

  return (
    <>
      <div id={'SchooseModal'} style={{display: 'block'}}>
        <div className="grid3">

          <div className="list1">

            <div className={`gridTitle`}>Типы компетенций</div>

            {allData.map(x => {
              let count = countActive(x)
              if(!x.comp?.length) return

              return (
                <div className={'itemMainTable'}>

                  <div
                    onClick={()=>selectParent(x)}
                    className={`${activeParentId===x.id ? "activeBorder" : ""}  ${count ? "list1Name" : 'list1NameDef'}`}
                    key={x.id}>

                    <div>
                      <div className={'itemNameCat'}>{x.categoryName}</div>
                      <div className="flex countLine">
                        <div className={'all'}>всего: <span className={'val'}>{x.comp.length}</span> </div>
                        <div className={'all'}>выбрано: <span className={'val'}>{count}</span> </div>
                      </div>
                    </div>
                  </div>

                </div>
              )
            })}
          </div>

          <div className="list2">
            <div className={`gridTitle`}>Компетенции</div>


            {childList.map(x=> {
              let active = x.active && x.req_level

              return (
                <div className={'itemMainTable'}>
                  <div
                    onClick={()=>selectChild(x)}
                    className={`itemNameCat ${activeChildId===x.id ? "activeBorder" : ""}  ${active ? "list1Name" : "list1NameDef"}`}
                    key={x.id}>
                    {x.comp_name}
                  </div>
                </div>
              )
            })}
          </div>

          <div className="list3">
            <div className={`gridTitle`}>Уровень</div>


            {levels.map(x => {
              if(!activeChild) return
              let active = activeChild.req_level === x

              return (
                <div className={'itemMainTable'}>
                  <div
                    onClick={()=>activeChildLevel(x)}
                    className={`itemNameCat ${active ? 'list1Name' : 'list1NameDef'}`}
                    key={x}
                  >
                    {x.replace("_"," ")}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <ButtonsForModal  save={saveForm} cancel={close} />
    </>
  )
})
