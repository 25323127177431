import {makeAutoObservable, toJS} from "mobx";
import _ from "lodash"
import {requireMerge, sortByReqCourse} from "./CoursesStore";

class RequarementsEmployees {
  courses = {
    "0": []
  }

  constructor() {
    makeAutoObservable(this)
  }

  getCoursesByUserId(UserId){
    if(!this.courses[UserId]) return []
    return this.courses[UserId]
  }

  async setCourses(request, courses, userId){

    if(this.courses[userId]) return;

    let requirementsByOwnerId = await this.fetchRequired(request ,userId)
    let sorted = sortByReqCourse(courses)
    let r = requireMerge(sorted , requirementsByOwnerId)
    this.courses[userId] = r
  }

  async fetchRequired(request, id){
    return request(`/api/requirements/getRequirementsByOwnerId/${id}/0/9999`)
      .then(data => {
        return data
      })
      .catch(e => {
        return []
      })
  }
}

export default new RequarementsEmployees()


