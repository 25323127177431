import {useEffect, useState} from "react";
import {createPortal} from "react-dom";
import './index.sass'
let timeOut;

export const BackBtn2 = ({okFn, backBtn }) => {
  let [vis, setVis] = useState(false)

  const upFn = () => {
    let top = Math.max(document.body.scrollTop,document.documentElement.scrollTop);
    if(top > 0) {
      window.scrollBy(0,-100);
      timeOut = setTimeout(()=>upFn(),20);
    } else clearTimeout(timeOut);

  }

  useEffect(()=>{
    document.addEventListener('scroll', ()=>{
      if(window.scrollY > 30){
        setVis(true)
      }else{
        setVis(false)
      }
    })
  },[])

  return createPortal(
    <div style={{paddingLeft: '20px'}} className={`upSettings visibilityShow single `}>
      {backBtn && <div onClick={okFn} className="back_btn"/>}
      {vis && <span  style={{marginLeft: 'unset'}} onClick={upFn} className={`upMode single `} > Наверх </span>}
    </div> ,
    document.querySelector('.body'))
}