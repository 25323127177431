export const columns = [
  {
    title: 'Наименования обучения',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: <div className={'pL35'}>Статус</div>,
    dataIndex: 'status',
    key: 'status',
    width: '20%',
  },
  {
    title: 'Дата сдачи',
    dataIndex: 'start_date',
    key: 'start_date',
    className: 'centerColumn',
    width: '20%',
  },
  {
    title: 'Срок истечения',
    dataIndex: 'end_date',
    key: 'end_date',
    className: 'centerColumn ',
    width: '20%',
  }
];