import {makeAutoObservable, toJS} from "mobx";
import _ from "lodash";
import {formatData} from "./Competencies";
import {sortByReqCourse} from "./CoursesStore";
import User from "./User";
import moment from "moment";

class DetailUser {
  user = {}
  requirements = []
  competencies = []
  updateIds = []
  allUsers = {}
  history = []
  employeesStatistic = {}


  constructor() {
    makeAutoObservable(this)
  }

  setComp(competencies){
    return formatData(competencies)
  }

  getUser(){
    return this.user
  }

  getReq(){
    return this.requirements
  }


  update(item, title, type){
    if(item.info.evaluation === type){

      item.localChange.evaluation = null

      let f = this.updateIds.findIndex(x => x === item.id)
      this.updateIds.splice(f,1)

    }else{
      item.localChange.evaluation = type
      if(!this.updateIds.includes(item.id)) this.updateIds.push(item.id)
    }
  }


  getComp(){
    return this.competencies
  }

  fetchUserById (request, id) {
    if(this.allUsers.hasOwnProperty(`u_${id}`)) return;

    this.allUsers[`u_${id}`] = {}

    request(`/api/users/getUserById/${id}`)
      .then(data => {
        this.allUsers[`u_${id}`] = data
      })
  }


  getEmployeesStatistic(id){
    return this.employeesStatistic[`u_${id}`]
  }

  fetchUserStatistic (request, id) {
    this.employeesStatistic[`u_${id}`] = {
      competencies: 'ok',
      requirements: 'ok',
    }

    request(`/api/requirements/getRequirementsByOwnerId/${id}/0/999`)
      .then(data => {
        data.find(x => {
          if(x.course.required && x.status === 'Просрочен'){
            this.employeesStatistic[`u_${id}`].requirements = 'wrong'
            return x
          }

          if(x.course.required){
            let end_date = moment(x.end_date, "YYYY-MM-DD").unix()
            let current_date = moment().unix()
            let oneMonth = (end_date - current_date ) / 86400 <= 30
            if(oneMonth) {
              this.employeesStatistic[`u_${id}`].requirements = 'exclamation'
              return x
            }
          }
        })
      })

    request(`/api/competencies/getCompetenceHistoryByOwnerIdAndStatus/${id}/На_рассмотрении/0/1`)
      .then(data => {
        if(data && data.length){
          this.employeesStatistic[`u_${id}`].competencies = 'exclamation'
        }
      })
  }

  getUserById(id){
    return this.allUsers[`u_${id}`]
  }

  fetchData(request, id){
    request(`/api/users/getUserById/${id}`)
      .then(data => {
        this.user = data
        this.competencies = this.setComp(data.competencies)
      })

    request(`/api/requirements/getRequirementsByOwnerId/${id}/0/999`)
      .then(data => {
        this.requirements = data
      })
  }



  getUserByIdHistory(){

  }



  sortComp (data) {
    const dataObj = []

    data.forEach(x => {
      if(dataObj[x.id_competence]){
        dataObj[x.id_competence].push(x)
      }else{
        dataObj[x.id_competence] = [x]
      }
    })

    dataObj.forEach((x)=> {
      x = _.reverse(x)
    })

    this.competencies.forEach(gr => {
      gr.comp.forEach(c => {
        if(dataObj[c.id]) {
          c.history = dataObj[c.id]
          c.actual = c.history[0]


        }
      })
    })
    return dataObj
  }

  fetchRequired(request, id){
    request(`/api/requirements/getRequirementsByOwnerId/${id}/0/9999`)
      .then(data => {
        this.requirements = sortByReqCourse(data)
      })
  }


  fetchDataAll(request){
    request(`/api/users/listAll`)
      .then(data => {
        data.forEach(x => {
          this.allUsers[`u_${x.id}`] = x
          if(x.status === 'Уволен') {
            request(`/api/users/movePositionByUser/Cancel/${x.id}/0` , 'put')
              .then(data => {
                return data
              })
            return
          }
          if(x.status === 'Резерв') return

          if(x.id === 1 ) return

          request(`/api/users/getLeaderByUserId/${x.id}`)
            .then(leader => {
              this.allUsers[`u_${x.id}`].leader = leader
            })
            .catch(err => {
              if(x.name === 'Вакант'){
                request(`/api/users/movePositionByUser/Cancel/${x.id}/0` , 'put')
                  .then(data => {
                    return data
                  })
              }

            })
        })
      })
  }
}

export default new DetailUser()


