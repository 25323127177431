import {Space} from "../../components/Space";
import './index.sass'
import {useEffect, useState} from "react";
import {useHttp} from "../../hooks/http.hook";
import {Select} from "antd";
import StatisticStore from "../../store/StatisticStore";
import {toJS} from "mobx";
import { Progress } from 'antd';
import {observer} from "mobx-react-lite";

const Option = Select.Option

export const Statistics = observer(() => {
  const [statisticId , setStatisticId] = useState(0)
  const {request} = useHttp()

  useEffect(()=>{
    StatisticStore.fetchData(request)

    return StatisticStore.clearDate()
  }, [])

  const changeDepartment = (id) => {
    setStatisticId(id)
    if(id === 0 ) return;
    StatisticStore.fetchStatistic(request, id)
  }

  let departments = StatisticStore.getData()

  let statistic  = StatisticStore.getStatistic(statisticId)

  let {totalCountPeople} = statistic

  const getElement = (require) => {
    let {
      overdueNotRequiredCountPeople,
      overdueRequiredCountPeople,
      percentNotRequiredOverduePeople,
      percentRequiredOverduePeople } = statistic || {}

    let people, present

    let el, el2

    if(require){
      people = overdueRequiredCountPeople || 0
      present = 100 - percentRequiredOverduePeople || 0

      el = document.querySelector('.circle1')
      el2 = document.querySelector('.circle2')

    }else{
      people = overdueNotRequiredCountPeople || 0
      present = 100 - percentNotRequiredOverduePeople || 0

      el = document.querySelector('.circle3')
      el2 = document.querySelector('.circle4')
    }


    const getAnim = (el, present, ) => {
      if(!present) return;
      let max = 500
      let dashoffset = max - (max/100*present)

      if(isNaN(dashoffset)){
        el.style.strokeDashoffset = max
        return [
          {strokeDashoffset : max },
          {strokeDashoffset : max }
        ]
      }

      el.style.strokeDashoffset = dashoffset
      return [
        {strokeDashoffset : max },
        {strokeDashoffset : dashoffset }
      ]
    }

    const getAnim2 = (el ) => {
      let max = 370
      let dashoffset = max - (max*people/totalCountPeople)

      if(isNaN(dashoffset)){
        el.style.strokeDashoffset = max
        return [
          {strokeDashoffset : max },
          {strokeDashoffset : max }
        ]
      }

      el.style.strokeDashoffset = dashoffset
      return [
        {strokeDashoffset : max },
        {strokeDashoffset : dashoffset }
      ]
    }


    if(el && el2 && statistic.hasOwnProperty('overdueRequiredCountPeople')){
      el2.animate(getAnim(el2,present), 1000);
      el.animate(getAnim2(el), 1000);
    }

    return (
      <div className={'pr'}>
        <div className="svgItem">
          <svg
            width="236"
            height="222"
            viewBox="0 0 236 222"
            fill="none"
            className='element1'
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2 105.5C2 169.075 53.935 220 118 220C182.065 220 234 168.462 234 104.887V98"
              strokeWidth="4"
              stroke='#BFB9B6'
              strokeLinecap="round"
              className={require ? 'circle1' : 'circle3' }
            />
            <path
              d="M15 105.5C15 125.97 21.0408 145.981 32.3586 163.002C43.6764 180.022 59.7628 193.288 78.5836 201.122C97.4044 208.955 118.114 211.005 138.094 207.011C158.074 203.018 176.427 193.16 190.832 178.686C205.237 164.211 215.047 145.769 219.021 125.692C222.995 105.615 220.955 84.8044 213.16 65.8923C205.364 46.9801 192.162 30.8156 175.224 19.4429C158.285 8.07017 138.371 2 118 2"
              stroke="#E5B844"
              strokeWidth="4"
              strokeLinecap="round"
              className={require ? 'circle2' : 'circle4' }

            />
          </svg>

          <div className='element_name'>
            {!require && <span> не </span>}
            <span> обязательное </span>
            <span> обучение </span>
          </div>

        </div>




        <div className='element_prosent'>
          <span className={'mainProsentCount'}>{present}</span>
          <span className={'prosent'}>%</span>
        </div>

        <div className='element_people'>
          <span>{people}</span>
        </div>

        <Space h={60}/>

        <div className={'flex center'}>
          <div className={'statistic_circle itemFirst'}/>
          <span className={'statistic_info'}>% соответствия персонала</span>
        </div>


        <div  className={'flex center'}>
          <div className={'statistic_circle itemSecond'}/>
          <span className={'statistic_info'}>количество не сдавших в срок</span>
        </div>

      </div>
    )
  }

  return (
    <div id='StatisticsPage'>

      <div className={'title'}>Статистика</div>

      <div className={'pLeft'}>
        <Space h={20}/>

        <Select
          onChange={changeDepartment}
          defaultValue={departments[0].name}
          className={'selectDepartment'}
        >
          {departments.map(x => <Option key={x.name} value={x.id}> {x.name}</Option>)}
        </Select>

        <Space h={20}/>

        <div className={'peopleCount'}>
          <span>{totalCountPeople}</span>
          <i>количество сотрудников</i>

        </div>

        <Space h={60}/>

        <div className={'gridStatistic'}>
          {getElement(true)}
          {getElement(false)}
        </div>
      </div>


    </div>
    )
})
