import {useEffect, useState} from "react";
import {MilkLine} from "../../../../components/MilkLine";
import {columns} from "../../../Courses/reqTableColumn";
import {Table} from "antd";
import moment from "moment";
import "./index.sass"
import {InputSearch} from "../../../../components/InputSearch/Input";
import CoursesStore from "../../../../store/CoursesStore";
import {ModalInfo} from "../../../Competencies/components/ModalInfo";
import {Space} from "../../../../components/Space";
import UserCourseStore from "../../../../store/Admin/UserCourse"
import {useHttp} from "../../../../hooks/http.hook";
import {toJS} from "mobx";
import {observer} from "mobx-react-lite";
import {Button} from "../../../../components/Button";
import {CalculatorOutlined} from '@ant-design/icons';
import {CustomTable} from "../../../../components/CustomTable";

export const UserCourse = observer(() => {
  const [searchText, setSearchText] = useState('')
  let [modalShow, setModalShow] = useState({type:null, data:null})
  let [childListOpen, setChildListOpen] = useState([])
  let [searchShow, setSearchShow] = useState(false)
  let [searchList, setSearchList] = useState([])
  let [updateList, setUpdateList] = useState([])
  let [userId, setUserId] = useState(null)

  let {request} = useHttp()
  let usersAll = UserCourseStore.getUsersNotNull2()

  useEffect(()=>{
    UserCourseStore.fetchUsersAll(request)

    return () => {
      UserCourseStore.clear()
    }
  }, [])

  const addChildList = (id) => {
    let list = [...childListOpen]

    let f = list.findIndex(x => x === id)

    if(f>-1) list.splice(f,1)
    if(f === -1) list.push(id)

    setChildListOpen(list)
  }

  const getRowClassName = (el) => {
    if(el.key === 'mustHave' || el.key === 'noMust') return 'title_must_row'
    let status = el?.item?.status

    if(!status) return 'title_row'
    if (status === 'Просрочен') return 'title_row_overDay'
    if (status === 'Не выставлен') return 'title_row_no_status'

    if((status !== 'Просрочен')){
      let end_date = moment(el.item.end_date, "YYYY-MM-DD").unix()
      let current_date = moment().unix()
      let oneMonth = (end_date - current_date ) / 86400 <= 30

      if(oneMonth) return 'title_row_oneMonth'

      return 'title_row_normal'
    }
  }

  const filterByName = (data, text) => {
    if(!text) return data
    let final = []

    data.forEach(x => {
      let c = x.courses.filter(xx => {
        if(x.title.toLowerCase().includes(text.toLowerCase())) return xx
        if(xx.course.course_name.toLowerCase().includes(text.toLowerCase())) return xx
      })
      if(c.length) final.push({...x,courses:c })
    })
    return final
  }

  const modelShowFn = (item) => {
    setModalShow({type:`info`, data: item})
  }


  const updateDate = (item) => {
    const repeatTypes = [
      {months: 1, 'title':'1 месяц' },
      {months: 3, 'title':'3 месяца' },
      {months: 6, 'title':'6 месяцев' },
      {months: 12, 'title':'1 год' },
      {months: 24, 'title':'2 года' },
      {months: 36, 'title':'3 года' },
      {months: 48, 'title':'4 года' },
      {months: 60, 'title':'5 лет' },
    ]

    let months = 0

    let f = repeatTypes.find(x => x.title === item.frequency)

    if(f>-1){
      months = f.months
    }else{
      if(typeof JSON.parse(item.frequency) === 'object'){
        let parse = JSON.parse(item.frequency)
        months = parse.years * 12 + parse.months
      }
    }

    if(!item.begin_date){
      item.begin_date = moment().format('YYYY-MM-DD')
    }
    item.end_date = moment(item.begin_date).add('months', months).format('YYYY-MM-DD')

    saveLocal(item)
  }

  const saveLocal = (item) => {
    let copy = [...updateList]

    copy = copy.filter(x => x.id !== item.id)
    copy.push(item)

    setUpdateList(copy)
  }

  const setDate = (value , item , type) => {
    item[type] = value.target.value
    saveLocal(item)
  }

  const filterByStatus = (data) => {
    data.forEach(gr => {
      gr.courses.forEach(x => {

        if(x.status === "Не выставлен"){
          x.statusTitle = 'Не выставлен'
          return
        }
        if(x.status === "Просрочен"){
          x.statusTitle = 'Срок действия истек'
          return
        }

        if(x.status !== "Просрочен"){
          let end_date = moment(x.end_date, "YYYY-MM-DD").unix()
          let current_date = moment().unix()
          let oneMonth = (end_date - current_date ) / 86400 <= 30
          x.statusTitle = oneMonth ? 'Cок действия скоро заканчивается' : 'Всё хорошо'
        }
      })
    })

    return data
  }


  const coursesTable  = () => {
    let requirements = UserCourseStore.getData()
    let filterData = filterByName(requirements, searchText)
    filterData = filterByStatus(filterData)


    let mustHave = [{
      key: 'mustHave',
      name: <div className={'groupBlock'}>Обязательны к обучению</div>,
    }]

    let over = [{
      key: 'noMust',
      name: <div className={'groupBlock'}>Не обязательные</div>,
    }]

    const pushEl = (item, arr) => {
      let start_date_text = item.begin_date ? moment(item.begin_date).format("YYYY-MM-DD") : null
      let end_date_text = item.end_date ? moment(item.end_date).format("YYYY-MM-DD") : null

      let element = {
        key: item.id,
        name: <div className={'flexCenterVert firstChar'}>
          <span style={{marginLeft: '20px'}}>{item.course_name}</span>
          <i className={`infoBtn`} onClick={()=>modelShowFn(item)}/>
        </div> ,
        status:  <div className={'statusMain status_table'}  title={item.statusTitle}> {item.status} </div>,
        start_date:
          <div>
            <input type={'date'} value={start_date_text}
                   onChange={(e)=>setDate(e,item ,`begin_date`)}/>
          </div>,

        end_date:
          <div >
            <input type={'date'} value={end_date_text}
                   onChange={(e)=>setDate(e,item,`end_date`)}
            />
            {item.frequency &&
              <CalculatorOutlined
                className={'calcStyle'}
                height={'30px'}
                // title={item.frequency+", "+"рассчитать автоматически"}
                onClick={()=>updateDate(item)}/>
            }

            {!item.frequency && <div style={{width: '23px', display: `inline-block`}}/>}

          </div>,

        item: item
      }
      arr.push(element)
    }

    filterData.forEach((gr, i) => {
      let requiredFlag = false
      let noRequiredFlag = false

      gr.courses.forEach(x => {
        let keyReq = `req_${gr.title}`
        let keyNReq = `nreq_${gr.title}`

        if(x.required && !requiredFlag){
          requiredFlag = true
          mustHave.push({
            key: keyReq,
            name:
              <div className={'flex'}> {gr.title}
                <p className={`arrowTable ${!childListOpen.includes(keyReq) ? "arrowTableUp" : ""}`}
                   onClick={()=>addChildList(keyReq)}/>
              </div>
          })
        }
        if(x.required && !childListOpen.includes(keyReq)) pushEl(x, mustHave)

        if(!x.required && !noRequiredFlag){
          noRequiredFlag = true
          over.push({
            key: keyNReq,
            name:
              <div className={'flex'}> {gr.title}
                <p className={`arrowTable ${!childListOpen.includes(keyNReq) ? "arrowTableUp" : ""}`}
                   onClick={()=>addChildList(keyNReq)}/>
              </div>
          })
        }
        if(!x.required && !childListOpen.includes(keyNReq)) pushEl(x, over)
      })
    })

    let r = []
    if(mustHave.length>1) r = [...mustHave]
    if(over.length>1) r = [...r , ...over]
    return r
  }

  const searchListGet = (e) => {
    let r = []
    let searchText = e.target.value.toLowerCase()

    if (!searchText) {
      setSearchList([])
      return;
    }

    if(searchText.length < 3){
      return;
    }

    usersAll.forEach(x => {
      let fullName = x.name +" "+x.family+" "+x.surname
      if (fullName.toLowerCase().includes(searchText)) {
        r.push({...x, fullName: fullName})
      }
    })

    setSearchList(r)
  }

  useEffect(() => {
    setSearchShow(searchList.length > 0)
  }, [searchList])

  const searchData = async (user) => {
    setSearchShow(false)

    setUserId(user.id)
    await UserCourseStore.fetchAllCourses(request, user.id, user.position.id)
  }

  const clickSearch = () => {
    if(searchShow.length){
      setSearchShow(true)
    }
  }

  const SearchBlock = () => {
    return (
      <div className="search">
        <div className={'inputZone'}>
          <div className={`searchShos`}>
            <InputSearch placeholder={'Поиск сотрудника...'}
                         clear={false}
                         cb={searchListGet}
                         onClick={clickSearch}
                         cleanAll={()=>{}}
                         onEnter={()=>{}}
            />
          </div>

          {searchShow && <div className="blockResult">
            {searchList.map(x => (
              <span onClick={() => searchData(x)} key={x.id}>{x.fullName}</span>
            ))}
          </div>
          }
        </div>
      </div>
    )
  }


  const save = () => {
    if(!userId || !updateList.length) return

    UserCourseStore.savePersonalCourseDate(request, updateList, userId)
  }

  return (
    <div id={'CoursesPage'} className={'userPage'}>
      <div className="title">Требования</div>
      <MilkLine/>

      <Space h={15}/>

      <div style={{display: `flex`, flexDirection: `row-reverse`,
        justifyContent: `space-between`, marginBottom: '10px'}}
      >
        <Button name={'Сохранить'} cb={save}/>
        {SearchBlock()}
      </div>

      <CustomTable
        rowClassName={getRowClassName}
        columns={columns}
        dataSource={coursesTable()}
        tableHeight={window.innerHeight - 246 - 22}
      />


      {modalShow?.type==='info' && <ModalInfo closeFn={()=>setModalShow(false)} data={modalShow.data} />}

    </div>
  )
})
