import {useEffect, useState} from "react";
import "./index.sass"
import {MainMode} from "./components/MainMode/MainMode";
import {observer} from "mobx-react-lite";
import User from "../../store/User";
import PreviewMode from "./components/PreviewMode"
import {createPortal} from 'react-dom'

let timeOut;

const BackBtn = ({okFn}) => {
  let [vis, setVis] = useState(false)

  const upFn = () => {
    // window.scrollTo(0, 0)
    //window.scrollTo(0, -200)

    let top = Math.max(document.body.scrollTop,document.documentElement.scrollTop);
    if(top > 0) {
      window.scrollBy(0,-100);
      timeOut = setTimeout(()=>upFn(),20);
    } else clearTimeout(timeOut);

  }

  useEffect(()=>{
    document.addEventListener('scroll', ()=>{
      if(window.scrollY > 30){
        setVis(true)
      }else{
        setVis(false)
      }
    })
  },[])

  return createPortal(
    <div className={`upSettings ${vis ? "visibilityShow": ""} `}>
      <div  onClick={okFn} className="back_btn"/>
      <span onClick={upFn} className="upMode"> Наверх </span>
    </div> ,
    document.querySelector('.body'))
}

const Profile = observer(() => {
  const [previewMode, setPreviewMode] = useState(true)
  const [vis, setVis] = useState(false)

  useEffect(()=>{
    document.addEventListener('scroll', ()=>{
      if(window.scrollY > 5){
        setVis(true)
      }else{
        setVis(false)
      }
    })
  },[])

  const user = User.getUser()

  if (!user.name) return null


  return (
    <div id='profilePage' className={vis ? "topMilkShow" : ""}>
      <span className={'title'}>Профиль</span>

      {previewMode ?
      <>
        <PreviewMode
          previewMode={previewMode}
        />

        <div
          className={'seeMore text_main_2'}
          onClick={()=>setPreviewMode(false)}
        >Посмотреть все данные</div>
      </> :
        <>
          <MainMode user={user} previewMode={previewMode} setPreviewMode={()=>setPreviewMode(true)}/>
          <BackBtn okFn={()=>setPreviewMode(true)}/>
        </>
      }

    </div>
  )
})

export default Profile