import {useEffect, useState} from "react";
import Admin from "../../../../../../store/Admin/Admin";
import "../schooseModal/index.sass"
import "./index.sass"
import {observer} from "mobx-react-lite";
import {ButtonsForModal} from "../../../../../../components/ButtonsForModal";
import {toJS} from "mobx";
import {InputSearch} from "../../../../../../components/InputSearch/Input";

export const SchooseModal2 = observer(({save , data , close}) => {
  let [childList, setChildList] = useState([])
  let [childListF, setChildListF] = useState([])
  let [secondClear, setSecondClear] = useState(false)

  let [allData, setAllData] = useState(data || [] )
  let [allDataF, setAllDataF] = useState(data || [])


  let [activeParentId, setActiveParentId] = useState(null)


  const selectParent=(item)=>{
    setChildList(item.courses || [])
    setChildListF(item.courses || [])

    setActiveParentId(item.id)
    setSecondClear(true)
    setTimeout(()=>{
      setSecondClear(false)
    }, 100)
  }

  const selectChild=(item)=>{
    if(item.active){
      item.active = false
      item.delete = true
      return
    }
    if(item.delete){
      item.active = true
      item.delete = false
      return
    }
    if(!item.active && !item.delete){
      item.active = true
      item.add = true
      return
    }
  }

  const saveForm =() => {
    save(allData)
    close()
  }

  const countActive = (data) => {
    let d = data.courses

    let count = 0
    d.forEach(x => {
      if (x.active) count = count + 1
    })

    return count
  }

  const mainSearch = (e) => {
    let text = e.target.value.toLowerCase()

    let fList = allData.filter(x => {
      let name = x.categoryName.toLowerCase()
      if(name.includes(text)) return true
    })
    setAllDataF(fList)
  }

  const clearMain = () => {
    setAllDataF(data)
  }

  const secondSearch = (e) => {
    let text = e.target.value.toLowerCase()

    let fList = childList.filter(x => {
      let name = x.course_name.toLowerCase()
      if(name.includes(text)) return true
    })
    setChildListF(fList)
  }

  const clearSecond = () => {
    setChildListF(childList)
  }

  return (
    <>
      <div id={'SchooseModal'}>
        <div className="grid2">
          <div className="list1">
            <div className={`gridTitle`}>Типы курсов</div>

            {allDataF.map(x => {
              let count = countActive(x)
              if(!x.courses?.length) return

              return (
                <div className={'itemMainTable'}>
                  <div
                    onClick={()=>selectParent(x)}
                    className={`${activeParentId===x.id ? "activeBorder" : ""}  ${count ? "list1Name" : 'list1NameDef'}`}
                    key={x.id}>

                    <div>
                      <div className={'itemNameCat'}>{x.categoryName}</div>
                      <div className="flex countLine">
                        <div className={'all'}>всего: <span className={'val'}>{x.courses.length}</span> </div>
                        <div className={'all'}>выбрано: <span className={'val'}>{count}</span> </div>
                      </div>

                    </div>

                  </div>
                </div>
              )
            })}

            <div className="searchZone">
              <InputSearch placeholder={'Поиск...'}
                           clear={false}
                           cb={mainSearch}
                           cleanAll={clearMain}
                           onEnter={()=>{}}
              />
            </div>
          </div>

          <div className="list2">
            <div className={`gridTitle`}>Курсы</div>

            {childListF.map(x=> {
              return (
                <div className={'itemMainTable'}>
                  <div
                    onClick={()=>selectChild(x)}
                    className={`itemNameCat ${x.active ? 'list1Name' : 'list1NameDef'}`}
                    key={x.id}
                  >
                    {x.course_name}
                  </div>
                </div>
              )
            })}

            <div className="searchZone">
              <InputSearch placeholder={'Поиск...'}
                           clear={secondClear}
                           cb={secondSearch}
                           cleanAll={clearSecond}
              />
            </div>
          </div>
        </div>
      </div>
      <ButtonsForModal  save={saveForm} cancel={close} />
    </>
  )
})
