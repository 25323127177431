import {Button} from "../Button";

export const ButtonsForModalAuth = ({addClasses , cancel, save, okText='Войти в систему'}) => {

  return (
    <div style={{alignItems: `center`}} className={'row2'}>
      <span className={'cancel_btn text_main_2 border_dashed'} onClick={cancel}>Выйти</span>
      <Button name={okText} addStyleClass={addClasses} cb={save}/>
    </div>
  )
}
