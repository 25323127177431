import {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {AvatarProfile} from "../Profile/components/avatar/AvatarProfile";
import {toJS} from "mobx";
import {CloseBtn} from "../../components/CloseBtn";
import phone from "../../img/phone.png";
import email from "../../img/email.png";

export const BioBlock = observer(({item, hideFn, leaderClick}) => {
  let [userMode, setUserMode] = useState(true)

  useEffect(()=>{
    setUserMode(item.worker.name !== 'Вакант')
  }, [item])

  useEffect(() => {
    document.addEventListener('click', functionListener)
    return () => {
      document.removeEventListener('click', functionListener)
    }
  }, [])

  let functionListener = (e) => {
    let el = document.getElementById('bioBlock')
    if (!el.contains(e.target)) {
      el.classList.add('hideBlock')
      setTimeout(()=>{
        hideFn()
      }, 400)
    }
  }

  let hideSelf = () => {
    let el = document.getElementById('bioBlock')
    el.classList.add('hideBlock')
    setTimeout(()=>{
      hideFn()
    }, 400)
  }

  const bioBlock = () => {
    let user = item.bio || {}

    let {
      avatar_url, id, family, name,
      phone_work, status,username,
      surname, leader, position
    } = user

    if (!name) return null

    let {organization, department} = position

    let fullName = family +" "+name +" "+surname

    return (
      <div className="content">

        <CloseBtn size={20} fn={hideSelf} left={30} top={30}/>

        <div className="avatar_zone">
          <AvatarProfile
            avatar_url={avatar_url ? id+"_"+avatar_url : null}
            size={150}
            edit={false}
            companyAvatar={!avatar_url}
          />
        </div>

        <div className='content_name_full_block'>
          <span className={'name_full'}> {fullName} </span>
          <span></span>
          <span className={'status'}>
            статус: <span className={'statusHover'}> {status && status.replace("_", " ")} </span>
          </span>
        </div>

        <div className="info">
          <p><span className="title_key">Должность: </span>{position.name}</p>
          <p><span className="title_key">Отдел: </span>{department.name}</p>
          <p><span className="title_key">Компания: </span>{organization?.name}</p>
          {id !== leader.id &&
          <>
            <p><span className="title_key">Руководитель: </span>
              <span className={'leaderSpan'} onClick={leaderClick}>{leader.family} {leader.name} {leader.surname}</span>
            </p>
            <p><span className="title_key">Должность руководителя: </span>{leader.position?.name}  </p>
          </>
          }
        </div>

        <div className={'phone'}> <img src={phone} alt={''}/> <span>{phone_work}</span> </div>
        <div className={'mail'}> <img src={email} alt={''}/> <span>{username}</span> </div>

      </div>
    )
  }


  const bioBlockFree = () => {
    let user = item.bio || {}

    let {position, name, leader} = user

    if (!name) return null

    let {organization, department} = position

    let fullNameLeader = leader.name === 'Вакант'
      ? "Вакантаная должность"
      : leader.family +" "+leader.name +" "+leader.surname


    return (
      <div className="content">

        <CloseBtn size={20} fn={hideSelf} left={30} top={30}/>

        <div className="avatar_zone">
          <AvatarProfile
            avatar_url={null}
            size={150}
            edit={false}
            companyAvatar={true}
          />
        </div>

        <div className='content_name_full_block'>
          <span className={'name_full'}> Вакантная должность </span>
          <span></span>
          <span className={'status'}>
          </span>
        </div>

        <div className="info">
          <p><span className="title_key">Должность: </span>{position.name}</p>
          <p><span className="title_key">Отдел: </span>{department.name}</p>
          <p><span className="title_key">Компания: </span>{organization?.name}</p>
          <p>
            <span className="title_key">Руководитель: </span>
            <span  className={'leaderSpan'} onClick={leaderClick} >
              {fullNameLeader}
            </span>
          </p>
          <p>
            <span className="title_key">Должность руководителя: </span>
            {leader.position?.name}
          </p>
        </div>
      </div>
    )
  }

  return (
    <div id='bioBlock' className={'bioBlock'}>
      {userMode && bioBlock()}
      {!userMode && bioBlockFree()}
    </div>
  )
})
