import {useEffect, useState} from "react";
import './index.sass'
import {Select} from "antd";
import {Input} from "../../../../../components/Input/Input";
import {Space} from "../../../../../components/Space";
import {useHttp} from "../../../../../hooks/http.hook";
import Admin from "../../../../../store/Admin/Admin";
import {Button} from "../../../../../components/Button";
import {toJS} from "mobx";
import {useHistory} from "react-router-dom";
import {LearnTimes} from "./LearnTimes";
import {MilkLine} from "../../../../../components/MilkLine";
import {observer} from "mobx-react-lite";
import {PopUpClose} from "../../../../../components/PopUpClose";
import UsersListStore from "../../../../../store/Admin/UsersList";
import {PopUpChoose} from "./PopUpChoose";
import {InputSearch} from "../../../../../components/InputSearch/Input";
import {SearchByList} from "./SearchByList";


const Option = Select.Option

const clenData = (data) => {

  return {
    edit: !!data?.id,
    category_id: data.category_id || null,
    required: data.required || false,
    course_name: data.course_name || "",
    description: data.description ||'',
    provider: data.provider || '',
    type_learning: data.type_learning || '',
    cost: data.cost || '',
    frequency: data.frequency || "1 месяц",
    course_dates: data.course_dates || [],
    responsible_id: data?.responsible?.id || null,
    id: data.id || null
  }
}

export const NewCourse = observer(() => {
  const {request} = useHttp()
  const getCats = Admin.getCats()
  const history = useHistory()
  const [error, setError ] = useState(false)
  const [learnTimes, setLearnTimes] = useState([])
  const [editMode, setEditMode] = useState(false)
  const [data, setData] = useState(clenData({}))
  let [haveChange, setHaveChange] = useState(false)
  let [popUpCloseShow, setPopUpCloseShow] = useState(false)
  let [block, setBlock] = useState(false)
  let [modalFrequency, setModalFrequency] = useState(false)
  let [myChoose, setMyChoose] = useState("")
  let [frequenceData, setFrequenceData] = useState(null)



  const repeatTypes = ['1 месяц', '3 месяца', '6 месяцев', '1 год', '2 года', '3 года',  '4 года', "5 лет"]

  useEffect(()=>{
    UsersListStore.fetchList(request)
    let editObj = history.location.state.edit
    if(editObj.id){
      setEditMode(true)
    }

    if(editObj.frequency){
      function IsJsonString(str) {
        try {
          return JSON.parse(str);
        } catch (e) {
          return false;
        }
      }

      let parse = IsJsonString(editObj.frequency)
      if(parse){
        saveUserChoose(parse)
        selectActiveCat('myChooseActive',`frequency`)
      }
    }


    setData(clenData(editObj))
  },[])

  let users = UsersListStore.getUsersNotNull()

  const save = async () => {
    if(block) return

    if(!data.type_learning || !data.course_name  || !data.responsible_id){
      setError(true)
      setTimeout(()=>{
        setError(false)
      }, 1)
      return;
    }

    if(myChoose){
      data.frequency = frequenceData
    }

    setBlock(true)
    await Admin.addCourse(request , data, learnTimes)
    setBlock(false)
    goBackTrue()
  }

  const selectActiveCat = (id, type) => {
    if(id === `myChoose`){
      setModalFrequency(true)
      return
    }
    if(type === `frequency` && id !== 'myChooseActive'){
      setMyChoose('')
    }

    let copy = {...data}
    copy[type] = id
    setData(copy)


  }

  const saveInput = (e, key) => {
    setHaveChange(true)
    let copy = {...data}
    copy[key] = e.target.value
    setData(copy)
  }

  const checkBoxChange = (e, key) => {
    let copy = {...data}
    copy[key] = e.target.checked
    setData(copy)
  }

  const goBack = () => {
    if(haveChange){
      setPopUpCloseShow(true)
      return
    }

    goBackTrue()
  }

  const goBackTrue = () => {
    history.push('/admin/courses')
  }

  const saveUserChoose = (val) => {
    setFrequenceData(JSON.stringify(val))

    let txt = `года: ${val.years}; месяцы: ${val.months}`
    setMyChoose(txt)
    selectActiveCat('myChooseActive','frequency')
  }


  return (
    <div id={'NewCourse'} className={'userPage'}>
      <div className="title">Формирование обучения</div>
      <MilkLine/>

      <Space h={30}/>

      <div className="close_btn_global" onClick={goBack}/>

      <div className={'gridAdmin'}>
        <p>ГРУППА КОМПЕТЕНЦИЙ</p>
        <Select
          className={`editUserSelect`}
          value={data.category_id}
          onChange={(id)=>selectActiveCat(id, 'category_id')}
        >
          {getCats.map(x=>{
            return <Option key={x.id} value={x.id}> {x.categoryName} </Option>
          })}
        </Select>

        <div>НАЗВАНИЕ КОМПЕТЕНЦИИ</div>
        <Input defValue={data.course_name} error={error} cb={(e)=>saveInput(e,`course_name`)} />

        <div>ОПИСАНИЕ</div>

        <div className="text_area">
          <textarea
            className={'areaAdmin'}
            style={{width: 350}}
            onChange={(e)=>saveInput(e,`description`)}
            value={data.description}/>
        </div>

        <p>ОТВЕТСТВЕННЫЙ</p>
        <SearchByList
          error={error}
          value={data.responsible_id}
          save={(id)=>selectActiveCat(id, `responsible_id`)}/>


        <div>НАЗВАНИЕ УЧЕБНОГО ЦЕНТРА</div>
        <Input defValue={data.provider} cb={(e)=>saveInput(e,`provider`)} />

        <div>ОБЯЗАТЕЛЬНОЕ ПРОХОЖДЕНИЕ</div>
          <input
            checked={data.required}
            type="checkbox"
            className={'checkboxx'}
            onChange={(e) => checkBoxChange(e,'required')}/>



        <p>СТОИМОСТЬ ПРОХОЖДЕНИЯ</p>
        <Input defValue={data.cost} cb={(e)=>saveInput(e,`cost`)}/>


        <div>ФОРМА ОБУЧЕНИЯ</div>
        <Input defValue={data.type_learning} error={error} cb={(e)=>saveInput(e,`type_learning`)} />


        <p>ЧАСТОТА ПОВТОРЕНИЙ</p>
        <Select
          className={`editUserSelect`}
          value={myChoose || data.frequency}
          onChange={(name)=>selectActiveCat(name,'frequency')}
        >
          {repeatTypes.map(x=>{
            return <Option key={x} value={x}> {x} </Option>
          })}
          {/*{myChoose && <Option value={'myChooseActive'}> {myChoose} </Option>}*/}
          <Option value={'myChoose'}> Свой вариант </Option>
        </Select>
      </div>

      <LearnTimes course_dates={data.course_dates}  save={setLearnTimes}/>

      <div className={'gridAdmin'}>
        <p></p>
        <div style={{display: 'flex'}}>
          <Button
            addStyleClass={block ? 'block_color' : ""}
            name={editMode ? 'Сохранить' : 'Сохранить'} cb={save}/>
        </div>
      </div>

      <Space h={10}/>


      {popUpCloseShow && <PopUpClose save={goBackTrue} closeFn={()=>setPopUpCloseShow(false)} />}
      {modalFrequency && <PopUpChoose save={saveUserChoose} closeFn={()=>setModalFrequency(false)} />}
    </div>
  )
})
