import {useEffect, useState} from "react";
import {columns} from "../../Competencies/components/columsTable";
import {Table} from "antd";
import {ModalHistory} from "../../Competencies/components/ModalHistory";
import {ModalNote} from "../../Competencies/components/ModalNote";
import {ModalInfo} from "../../Competencies/components/ModalInfo";
import {EditOutlined, FormOutlined, MessageOutlined} from "@ant-design/icons";
import {toJS} from "mobx";
import {Select} from "antd/es";
import {Button} from "../../../components/Button";
import {useHttp} from "../../../hooks/http.hook";
import {PaginationForTable} from "../../../components/Pagination";
import Competencies from "../../../store/Competencies";
import {observer} from "mobx-react-lite";
import {ModalText} from "../../Competencies/components/ModalText";
import CompetenciesEmployees from "../../../store/CompetenciesEmployees";
import _ from 'lodash'
import {CustomTable} from "../../../components/CustomTable";

const Option = Select.Option

const CompetenciesEmployeesPage = observer(({tableHeight, userId}) => {
  let [modalShow, setModalShow] = useState({})
  let [childListOpen, setChildListOpen] = useState([])
  let [childListChecked, setChildListChecked] = useState([])
  let [allMode, setAllMode] = useState(false)

  let {request} = useHttp()


  let competencies = CompetenciesEmployees.getComp()

  const filterByNotApprove = (data) => {
    if (allMode) return data

    data.forEach(gr => {
      gr.comp.forEach(x => {
        if(x.actual.status === 'На_рассмотрении') {
          x.show = true
        }
      })
    })

    return data
  }

  const addChildList = (id) => {
    let list = [...childListOpen]

    let f = list.findIndex(x => x === id)

    if(f>-1) list.splice(f,1)
    if(f === -1) list.push(id)

    setChildListOpen(list)
  }

  const addCheckedList = (id) => {
    let list = [...childListChecked]

    let f = list.findIndex(x => x === id)

    if(f>-1) list.splice(f,1)
    if(f === -1) list.push(id)

    setChildListChecked(list)
  }

  const modelShowFn = (type, item) => {
    setModalShow({type: type, data: item})
  }

  const selectGradeChange = (type , item) => {
    Competencies.updateLocalEvaluation(item, type)
  }

  const selectGrade = (status, item) => {
    let add = status === 'Не выбрано'
    let block = item.actual.status !== `На_рассмотрении`
    return (
      <Select
        onChange={(type)=>selectGradeChange(type, item)}
        style={{width: '120px'}}
        value={status || 'Не_выбрано'}
        disabled={block}
      >
        {add && <Option value='Не выбрано'> Не выбрано </Option>}
        <Option value='Ознакомлен' > Ознакомлен </Option>
        <Option value='Знает'> Знает </Option>
        <Option value='На_практике'> На практике </Option>
        <Option value='Эксперт' > Эксперт </Option>
      </Select>
    )
  }

  const getDataSource = (data) => {
    let r = []

    let result = filterByNotApprove(data)

    result.forEach((parent) => {
      if(!parent.comp.length) return;

      let f = parent.comp.filter(x => x.show)

      if(!f.length && !allMode) return;

      r.push({
        key: parent.categoryName,
        name: <div className={'flexCenterVert'}>
          {parent.categoryName}
          <p className={`arrowTable ${!childListOpen.includes(parent.id) ? "arrowTableUp" : ""}`}
             onClick={()=>addChildList(parent.id)}/>
        </div>
      })

      !childListOpen.includes(parent.id) && parent.comp.forEach(item => {
        if(!item.show && !allMode) return false

        let {actual, req_level} = item
        let {status, evaluation_local, evaluation} = actual
        let grade = evaluation_local || evaluation
        let checked = childListChecked.includes(item.id)

        let showCheckBox = status === 'На_рассмотрении'
        let statusTable = status.includes('_') ? status.replace("_", " ") : status

        r.push( {
          key: item.id,
          name: <div className={'flexCenterVert'}>
            {showCheckBox && <input type="checkbox" className={`checkboxEmpl`} onChange={()=>addCheckedList(item.id)} checked={checked}/>}
            <span style={{marginLeft: showCheckBox ? '8px' : '21px'}}>{item.comp_name}</span>
            <i className={`infoBtn`} onClick={()=>modelShowFn('info', item)}/>
          </div>,
          grade: selectGrade(grade, item),
          req_level: req_level,
          status: statusTable,
          history: <div  className={'iconWrap'}>
            <MessageOutlined onClick={()=>modelShowFn(`history`, item) }/>
          </div>,
          text: <div  className={'iconWrap'}>
            <FormOutlined onClick={()=>modelShowFn(`text`, item) }/>
          </div>,
          note: <div  className={'iconWrap'}>
            <EditOutlined onClick={()=>modelShowFn(`note` , item) }/>
          </div>,

          item: item
        })
      })
    })

    return r
  }

  const saveForm = async (approveStatus) => {
    if(!childListChecked.length) return

    let complete = await CompetenciesEmployees.approveDecline(userId , request, childListChecked,approveStatus)
    if(complete) setChildListChecked([])
  }

  const saveNote = (text, comp) => {
    CompetenciesEmployees.saveNoteCompetencies(userId , request , text, comp)
  }

  const getRowClassName = (el) => {
    //[Не_согласовано, На_рассмотрении,
    // Согласовано, Редактирование ]

    //родительская строчка
    if(!el.grade) return 'title_row'

    //локальные изменения
    // if (el.item.actual.evaluation_local) return 'active_row'

    //согласованный статус, и соотвествует требованию
    if((el.req_level === el.item.actual.evaluation) &&
      el.status === 'Согласовано') return 'greenRow'

    //не согласованный
    if(el.status === 'Не согласовано') return 'redRow'

    //отправлена на согласование
    if(el.status === 'На рассмотрении' ){
      let checked = childListChecked.includes(el.item.id)
      return (checked) ? 'active_row' : 'sendToApproveRowChild2'
    }
  }

  return (
    <div className={'compEmplPage'}>
      <div className={'btns2'}>

        <Button name={'Показать всё'}
                cb={()=>setAllMode(true)}
                addStyleClass={"mr10"}
        />

        <Button name={'СОГЛАСОВАТЬ'}
                cb={()=>saveForm(true)}
                addStyleClass={childListChecked.length===0 ? 'block_color mr10' : " mr10"}
        />
        <Button name={'ОТКЛОНИТЬ'}
                cb={()=>saveForm(false)}
                addStyleClass={childListChecked.length===0 ? 'block_color' : ""}/>
      </div>


      <CustomTable
        rowClassName={getRowClassName}
        columns={columns}
        dataSource={getDataSource(competencies)}
        tableHeight={tableHeight}
      />


      {modalShow?.type==='info' && <ModalInfo closeFn={()=>setModalShow(false)} data={modalShow.data} />}

      {modalShow?.type==='history' && <ModalHistory closeFn={()=>setModalShow(false)} data={modalShow.data} />}


      {modalShow?.type==='text' && <ModalText
        closeFn={()=>setModalShow(false)}
        data={modalShow.data} />}

      {modalShow?.type==='note' && <ModalNote
        saveFn={(value) => saveNote(value,modalShow.data)}
        edit={true}
        data={modalShow.data}
        closeFn={()=>setModalShow(false)}
      />}
    </div>
  )
})

export default CompetenciesEmployeesPage