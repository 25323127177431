import {useEffect, useState} from "react";
import {Table} from 'antd';
import {Space} from "../../components/Space";
import "./index.sass"
import {Select} from "antd/es";
import {MessageOutlined , FormOutlined ,EditOutlined} from "@ant-design/icons"
import {ModalHistory} from "./components/ModalHistory";
import {ModalText} from "./components/ModalText";
import {ModalNote} from "./components/ModalNote";
import {Button} from "../../components/Button";
import {observer} from "mobx-react-lite";
import Competencies from "../../store/Competencies";
import {ModalInfo} from "./components/ModalInfo";
import {columns} from "./components/columsTable"
import {useHttp} from "../../hooks/http.hook";
import {toJS} from "mobx";
import {PaginationForTable} from "../../components/Pagination";
import {BackBtn} from "../../components/BackBtn";
import {ModalInfoSave} from "./components/ModalInfoSave";
import {CustomTable} from "../../components/CustomTable";


let Option = Select.Option


export const CompetenciesPage = observer(() => {
  let [modalShow, setModalShow] = useState(false)
  let [tableCount, setTableCount] = useState(0)
  let [childListOpen, setChildListOpen] = useState([])
  let [tableHeight, setTableHeight] = useState(500)
  let [modalInfoSave, setModalInfoSave] = useState(false)

  useEffect(()=>{
    setTableHeight(window.innerHeight - 74 - 94 -22 - 77 - 20)
  }, [])

  const {request} = useHttp()

  useEffect(()=>{
    Competencies.fetchHistory(request)
  }, [])

  let competencies = Competencies.getComp()

  useEffect(()=>{
    let count = 0
    competencies.forEach(x => count+= x.comp.length)
    setTableCount(count)
  }, [competencies])

  const selectGradeChange = (type , item) => {
    Competencies.updateLocalEvaluation(item, type)
  }

  const addChildList = (id) => {
    let list = [...childListOpen]

    let f = list.findIndex(x => x === id)

    if(f>-1) list.splice(f,1)
    if(f === -1) list.push(id)

    setChildListOpen(list)
  }

  const selectGrade = (status, item, block) => {
    let add = item.actual.status === 'Новое'
    return (
      <Select
        onChange={(type)=>selectGradeChange(type, item)}
        style={{width: '120px'}}
        value={status || 'Не_выбрано'}
        disabled={block}
        className={'selectBlock'}
      >
        {add && <Option value='Не выбрано' > Не выбрано </Option>}
        <Option value='Ознакомлен' > Ознакомлен </Option>
        <Option value='Знает' > Знает </Option>
        <Option value='На_практике'> На практике </Option>
        <Option value='Эксперт' > Эксперт </Option>
      </Select>
      )
  }

  const modelShowFn = (type, item) => {
    setModalShow({type: type, data: item})
  }

  const getDataSource = (data) => {
    let r = []

    data.forEach((parent) => {
      if(!parent.comp.length) return;
      r.push({
        key: parent.categoryName,
        name: <div className={'flex '}> {parent.categoryName}
              <p className={`arrowTable ${!childListOpen.includes(parent.categoryName) ? "arrowTableUp" : ""}`}
                 onClick={()=>addChildList(parent.categoryName)}/>
          </div>
      })

      !childListOpen.includes(parent.categoryName) && parent.comp.forEach(item => {
        let {actual, req_level} = item
        let {status, evaluation_local, evaluation} = actual
        let grade = evaluation_local || evaluation
        let block = item.actual.status === `На_рассмотрении`
        let statusTable = status.includes('_') ? status.replace("_", " ") : status

        r.push( {
          key: item.id,
          name: <div className={'flexCenterVert firstChar'}>
                  <span style={{marginLeft: '20px'}}>{item.comp_name}</span>
                  <i className={`infoBtn`} onClick={()=>modelShowFn('info', item)}/>
                </div>,
          grade: selectGrade(grade, item, block),
          req_level: req_level,
          status: statusTable,
          history: <div  className={'iconWrap'}>
            <MessageOutlined onClick={()=>modelShowFn(`history`, item) }/>
          </div>,
          note: <div  className={'iconWrap'}>
            <EditOutlined onClick={()=>modelShowFn(`note` , item) }/>
          </div>,
          text: <div  className={'iconWrap'}>
            <FormOutlined onClick={()=>modelShowFn(`text`, item) }/>
          </div>,
          item: item
        })
      })
    })

    return r
  }


  const getRowClassName = (el) => {
    //[Не_согласовано, На_рассмотрении,
    // Согласовано, Редактирование ]

    //родительская строчка
    if(!el.grade) return 'title_row'

    //локальные изменения
    if (el.item.actual.evaluation_local) return 'active_row'

    //согласованный статус, и соотвествует требованию
    if((el.req_level === el.item.actual.evaluation) &&
      el.status === 'Согласовано') return 'greenRow'

    //не согласованный
    if(el.status === 'Не согласовано') return 'redRow'

    //отправлена на согласование
    if(el.status === 'На рассмотрении') return 'sendToApproveRow'
  }

  const openModalForSave = () => {
    let count = Competencies.getUpdateCount()
    if(!count) return
    setModalInfoSave(true)
  }

  return (
    <div id='competenciesPage'>
      <div className={'title'}>Компетенции</div>

      <BackBtn backBtn={false} okFn={()=>{}}/>

      <Space h={15}/>

      <div style={{display: `flex`, flexDirection: `row-reverse`, marginBottom: '10px'}}>
        <Button name={'ОТПРАВИТЬ НА СОГЛАСОВАНИЕ'} cb={openModalForSave}
                addStyleClass={!Competencies.getUpdateStatus ? 'block_color' : ""}/>
      </div>

      <CustomTable
        rowClassName={getRowClassName}
        columns={columns}
        dataSource={getDataSource(competencies)}
        tableHeight={window.innerHeight - 246 - 15} />


      {modalInfoSave && <ModalInfoSave
        saveFn={()=>Competencies.approve(request)}
        closeFn={()=>setModalInfoSave(false)}
      />}

      {modalShow?.type==='info' && <ModalInfo closeFn={()=>setModalShow(false)} data={modalShow.data} />}

      {modalShow?.type==='history' && <ModalHistory closeFn={()=>setModalShow(false)} data={modalShow.data} />}

      {modalShow?.type==='text' && <ModalText
        closeFn={()=>setModalShow(false)}
        data={modalShow.data}
        btnsMode={modalShow.data.actual.status !== "На_рассмотрении"}
      />}
      {modalShow?.type==='note' && <ModalNote closeFn={()=>setModalShow(false)} data={modalShow.data} />}
    </div>

  )
})
