import "./index.sass"
import {Link} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {observer} from "mobx-react-lite";
import User from "../../store/User";

import {
  TeamOutlined,
  ContainerOutlined,
  AuditOutlined,
  ApartmentOutlined,
  BookOutlined,
  PieChartOutlined,
  UserOutlined,
  DatabaseOutlined,
  ControlOutlined,
  CrownOutlined,
  ClusterOutlined,
  FileSyncOutlined
} from '@ant-design/icons';


export const LeftMenu = observer(({admin}) => {
  let [open , setOpen] = useState(false)
  let [pin , setPin] = useState(localStorage.getItem('pinStatus') === 'true' || false)
  let [menuActive, setMenuActive] = useState(1)
  let [itemShowInfo, setItemShowInfo] = useState(null)
  let [hasSubjects, setHasSubjects] = useState(true)
  let [modeAdmin, setModeAdmin] = useState(false)

  let menuRef = useRef()

  let user = User.getUser()
  let organizationName = user?.organization ? user.organization.name : ''

  useEffect(()=>{
    let f = user.subjects.findIndex(x=> x.name !== 'Вакант')
    setHasSubjects(f>-1)
  }, [user])

  const pinToggle = () => {
    let pinNew = !pin
    setPin(pinNew)
    localStorage.setItem('pinStatus', pinNew)
  }

  function handleKeyDown(event) {
    if (modeAdmin) return
    if (event.shiftKey && event.altKey && event.ctrlKey && event.keyCode === 79) {
      setModeAdmin(true)
    }
  }


  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);


  useEffect(()=>{
    if(pin && open) mouseEnter(null, false)
  }, [pin,open])

  const mouseEnter = (e, status) => {
    if(status && !pin){
      setOpen(true)
    }else{
      setOpen(false)
    }
  }

  useEffect(()=>{
    if(admin)setMenuActive(9)
  }, [admin])

  let items = [
    {key: 1, link: '/profile' , title:"Профиль", icon: <UserOutlined style={{ fontSize: '23px', color:'gray'}}/> },
    {key: 2, link: '/competencies' , title:"Компетенции", icon: <AuditOutlined style={{ fontSize: '23px', color:'gray'}}/> },
    {key: 3, link: '/courses' , title:"Курсы", icon: <BookOutlined style={{ fontSize: '23px', color:'gray'}}/> },
    {key: 4, link: '/estimated' , title:"Расчётные листы", icon: <ContainerOutlined style={{ fontSize: '23px', color:'gray'}}/> },
    {key: 5, link: '/organization' , title:"Организационная структура", icon: <ApartmentOutlined style={{ fontSize: '23px', color:'gray'}}/> },
    {key: 6, link: '/statistics' , title:"Статистика", icon: <PieChartOutlined style={{ fontSize: '23px', color:'gray'}}/> },
  ]

  hasSubjects && items.push({key: 7, link: '/employees' , title:"Сотрудники", icon: <TeamOutlined style={{ fontSize: '23px', color:'gray'}}/> })


  if(admin){
    items = []
    let all = false
    if(user.roles.includes('ADMIN')){
      all = true
      items = [
        {key: 9, link: '/admin/users' , title:"Пользователь", icon: <TeamOutlined style={{ fontSize: '23px', color:'gray'}}/> },
        {key: 10, link: '/admin/positions' , title:"Должности", icon: <CrownOutlined style={{ fontSize: '23px', color:'gray'}}/> },
        {key: 16, link: '/admin/byx' , title:"Расчётные листы", icon: <ContainerOutlined style={{ fontSize: '23px', color:'gray'}}/> },
        {key: 12, link: '/admin/shos' , title:"Организационная структура", icon: <ApartmentOutlined style={{ fontSize: '23px', color:'gray'}}/> },
        {key: 11, link: '/admin/competencies' , title:"Компетенции", icon: <ClusterOutlined style={{ fontSize: '23px', color:'gray'}}/> },
        {key: 17, link: '/admin/departments' , title:"Отделы", icon: <DatabaseOutlined style={{ fontSize: '23px', color:'gray'}}/> },
        {key: 13, link: '/admin/courses' , title:"Курсы", icon: <BookOutlined style={{ fontSize: '23px', color:'gray'}}/> },
        {key: 14, link: '/admin/user_courses' , title:"Требования", icon: <BookOutlined style={{ fontSize: '23px', color:'gray'}}/> },
        {key: 15, link: '/admin/company' , title:"Компания", icon: <ControlOutlined style={{ fontSize: '23px', color:'gray'}}/> },
      ]
      modeAdmin && items.push({key: 18, link: '/admin/files' , title:"Старт компании", icon: <FileSyncOutlined style={{ fontSize: '23px', color:'gray'}}/> })
    }else{
      items = [
        {key: 9, link: '/admin/users' , title:"Пользователь", icon: <TeamOutlined style={{ fontSize: '23px', color:'gray'}}/> },
      ]
    }

    if(!all && user.roles.includes('BOOKER')){
      items.push({key: 10, link: '/admin/byx' , title:"Расчётные листы", icon: <ContainerOutlined style={{ fontSize: '23px', color:'gray'}}/> })
    }

    if(!all && user.roles.includes('TEACHER')){
      items.push({key: 11, link: '/admin/competencies' , title:"Компетенции", icon: <ClusterOutlined style={{ fontSize: '23px', color:'gray'}}/> })
      items.push({key: 12, link: '/admin/courses' , title:"Курсы", icon: <BookOutlined style={{ fontSize: '23px', color:'gray'}}/> })
      items.push({key: 13, link: '/admin/user_courses' , title:"Требования", icon: <BookOutlined style={{ fontSize: '23px', color:'gray'}}/> })
    }
  }

  const itemShowInfoToggle = (event, enter, key) => {
    if (event.target.className === 'menu_item') return;
    if(!pin) return;
    setItemShowInfo( enter ? key : null)
  }

  return (
    <>
        <div id='left_menu' className={`left_menu_width ${open && 'full'}`}
             onMouseLeave={(e)=>mouseEnter(e, false)}
             onMouseEnter={(e)=>mouseEnter(e, true)}>
          {/*<Link to={'/'}>*/}
          {/*  <div  data-attr={'company name'} className={'company_icon'}/>*/}
          {/*</Link>*/}
          <div
            className="menu"
            ref={menuRef}
          >
            <Link to={'/'}>
              <div className={`menu_item_line logotip company_icon`} >
                <span className={`menu_item menu_item_logo ${open ? '' : 'hideItem'} `}>{organizationName}</span>
              </div>
            </Link>


            {items.map(item => {
              return (
                <Link to={item.link} key={item.key}>
                  <div
                    className={`menu_item_line ${menuActive===item.key ? 'active' : ''} `}
                    onClick={()=>setMenuActive(item.key)}
                    onMouseLeave={(e)=>itemShowInfoToggle(e,false)}
                    onMouseEnter={(e)=>itemShowInfoToggle(e,true,item.key)}
                  >
                    <div className={`show_title_hide ${itemShowInfo===item.key ? "show" : ''}`}>
                      {item.title}
                    </div>

                    {item.icon}
                    <span className={`menu_item ${open ? '' : 'hideItem'} `}>{item.title}</span>
                  </div>
                </Link>
              )
            })}
          </div>

          <div onClick={pinToggle} className={`pin ${pin ? "lock" : "unlock"}`}/>

        </div>
    </>
  )
})
