import {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {Modal} from "../Modal";
import {ButtonsForModal} from "../ButtonsForModal";


export default observer(({ saveFn , closeFn}) => {

  const save = async () => {
    saveFn.save()
    closeFn()
  }

  return (
    <div id={`modalCreatePosition`}>

      <Modal
        closeFn={closeFn}
        title={'Подтвердите действие'}
      >
        <div id={'info_modal_id'}>
          <div className={'text_main_name_close'}>
            <p>{`Вы действительно хотите удалить запись?` }</p>
          </div>
        </div>

        <ButtonsForModal save={save} okText={'Продолжить'} cancel={closeFn} addClasses={[]}/>
      </Modal>
    </div>

  )
})
