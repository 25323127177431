import {makeAutoObservable, toJS} from "mobx";
import CoursesStore, {requireMerge, sortByReqCourse} from "../CoursesStore";
import {notification} from "antd";


class UserCourseStore {
  requirements = []
  allUsers = []

  constructor() {
    makeAutoObservable(this)
  }

  getData (){
    return this.requirements
  }

  async fetchAllCourses(request, userId, positionId){
    this.requirements = []

    this.data = []

    let list ;
    let history;

    await request(`/api/positions/getPositionById/${positionId}`)
      .then(data => {
        list = sortByReqCourse(data.courses)
      })

    await request(`/api/requirements/getRequirementsByOwnerId/${userId}/0/999`)
      .then(data => {
        history = data
      })
      .catch(err => {
        console.log('Experiment err');
        history = []
      })

    this.requirements = requireMerge(list , history)
  }

  getUsersAll(){
    return this.allUsers
  }

  getUsersNotNull2(){
    return this.allUsers.filter(x => {
      if(x.name !== 'Вакант' && x.position.id !== 100){
        return x
      }
    })
  }


  clear(){
    this.requirements = []
  }

  fetchUsersAll(request){
    request(`/api/users/listAll`)
      .then(data => {
        this.allUsers = data
      })
  }


  notificationSuccess(text) {
    notification.success({
      message: text,
      className: 'text_notification',
      duration: 3
    })
  }


  savePersonalCourseDate(request, list, userId){
    let arr = []

    list.forEach(x => {
      let data = {
        "owner": userId,
        "course_id": x.id,
        "status": "Действителен",
        "begin_date": x.begin_date,
        "end_date": x.end_date
      }
      arr.push(request('/api/requirements/save', `post`, data))
    })

    Promise.all(arr)
      .then(response => {
        this.notificationSuccess('Измененеия сохранены')
      })
  }
}

export default new UserCourseStore()